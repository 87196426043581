import React, { FC, useEffect, useState } from 'react';
import { PlxPanel } from '_Components/Panels/PlxPanel/PlxPanel';
import styled from '@emotion/styled';
import { IDistributionPartnerChannelDTO } from '_State/DistributionPartner/distributionPartner.types';
import { useTranslation } from 'react-i18next';
import { PlxLoader } from '_Components/PlxLoader';
import { useHistory, useParams } from 'react-router-dom';
import {
  acceptOffer,
  getChannels,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import { Dialog } from '@material-ui/core';
import { ReactComponent as ConfirmationIcon } from '_Styles/images/_Icons/Confirmation.svg';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { StandardizeAmount } from '_Components/StandardizeAmount/StandardizeAmount';

const Wrapper = styled.div`
  margin-top: 3.5rem;
  .PlxPanel-body {
    width: 77rem;
    margin: auto;
    padding: 5rem;
  }
  .PlxPanel-content {
    width: 100%;
  }
`;
const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Description = styled.div`
  margin-bottom: 5rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 3.3rem;
  text-align: center;
  color: #767676;
`;
const Label = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 4rem;
  text-align: center;
  color: #1b1b1b;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Title = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3.3rem;
  margin-bottom: 1rem;
  color: #1b1b1b;
`;
const Summary = styled.div`
  white-space: pre-wrap;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
  display: flex;
  justify-content: flex-start;
  color: #1b1b1b;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: right;
  margin-right: 10rem;
  margin-top: 2rem;
`;
const RightButton = styled(PlxButtonX)`
  && {
    margin-left: 5rem;
  }

  button {
    margin-left: auto;
  }
`;

export const DistributionPartnerSummary: FC = () => {
  const [summary, setSummary] = useState<IDistributionPartnerChannelDTO>();
  const [loading, setLoading] = useState(true);
  const [successDialogOpened, setSuccessDialogOpened] = useState(false);

  const { id } = useParams<any>();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    getChannels({ channelId: id }).then((channel) => {
      console.log(channel);
      if (channel) {
        setSummary(channel);
        setLoading(false);
      }
    });
    // eslint-disable-next-line
  }, []);
  const handleSubmit = () => {
    acceptOffer(id).then(() => {
      setSuccessDialogOpened(true);
    });
  };
  const handleConfirm = () => {
    setSuccessDialogOpened(false);
    history.push('/home/finfluencer/dashboard');
  };
  return (
    <>
      {!loading ? (
        <Wrapper>
          <PlxPanel
            enableActions={false}
            content={
              <>
                <Title>{t('influencer.addChannel.offeringSummary')}</Title>
                <Summary>{summary?.offeringSummary}</Summary>
                <Title>{t('influencer.addChannel.offer')}</Title>
                $<StandardizeAmount amount={summary ? summary.offer : 0} />
              </>
            }
          />
          {summary?.status !== 'ACTIVE' && (
            <ButtonWrap>
              <RightButton
                label={t('spectrum.footerButtons.accept')}
                onClick={handleSubmit}
              />
            </ButtonWrap>
          )}

          <Dialog open={successDialogOpened} disableBackdropClick>
            <DialogWrapper>
              <PlxPanel
                enableActions={false}
                content={
                  <Content>
                    <ConfirmationIcon />
                    <Label>{t('influencer.addChannel.confirmation')}</Label>
                    <Description>
                      {t('influencer.addChannel.confirmationDescription')}
                    </Description>
                    <PlxButtonX
                      onClick={handleConfirm}
                      label={t('influencer.addChannel.confirmationButton')}
                      testId="go-to-dashboard-button"
                    />
                  </Content>
                }
              />
            </DialogWrapper>
          </Dialog>
        </Wrapper>
      ) : (
        <PlxLoader />
      )}
    </>
  );
};
