import React, { FC, useState, useEffect, useContext } from 'react';
import { EmptyContainer, TabPageHeader } from './../../utils';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import { IDistributionPartnerChannelDTO } from '_State/DistributionPartner/distributionPartner.types';
import { useHistory } from 'react-router';
import {
  getContractDoc,
  getUserChannels,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import { Link } from '@material-ui/core';
import { PlxLoader } from '_Components/PlxLoader';
import {
  ChannelWrapper,
  Title,
  LinkContainer,
  TableHead,
  DateSection,
  ChannelSection,
} from './FinfluencerDocumentsStyle';
import { useTranslation } from 'react-i18next';
import { formatDate } from '_Components/DateFormat/DateFormat';
import { Body } from '../FinfluencerDashboard/DistributionPartnerDashboard';
import { NavigationContext } from '_Routes/Home/NavigationContext';

export const orderArr = ['OFFER_PLACED', 'ACTIVE', 'PENDING'];
interface IContract {
  contractUrl: string;
  contractSigned: string;
  title: string;
}
export const DistributionPartnerDocuments: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [channels, setChannels] = useState<IDistributionPartnerChannelDTO[]>();
  const [contracts, setContracts] = useState<IContract[]>();
  const history = useHistory();
  const { navBarExpanded } = useContext(NavigationContext);

  const fetchChannels = () => {
    return getUserChannels().then((v) => {
      const orderChannels = v
        .slice()
        .sort(
          ({ status: q }, { status: w }) =>
            orderArr.indexOf(q) - orderArr.indexOf(w)
        );
      setChannels(orderChannels);
      return orderChannels;
    });
  };

  useEffect(() => {
    getContractDoc().then((d: IContract[]) => {
      setContracts(d);
    });
    fetchChannels().then((r) => {
      setLoading(false);
    });
  }, []);

  return (
    <Body isExpanded={navBarExpanded}>
      <div style={{ width: '100%' }}>
        <TabPageHeader name={t('documents.title')} />
        <TableHead>
          <ul>
            <li>{t('documents.tableTitle')}</li>
            <li>{t('documents.date')}</li>
            <li>{t('documents.link')}</li>
          </ul>
        </TableHead>
        {!loading ? (
          <>
            {contracts ? (
              <ChannelWrapper>
                <Title>
                  <DescriptionSharpIcon />
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#000',
                    }}
                    variant="body2"
                    href={contracts[contracts?.length - 1].contractUrl}
                    target="_blank"
                  >
                    {t('documents.influencerAgreement')}
                  </Link>
                </Title>
                <DateSection>
                  {formatDate(contracts[contracts?.length - 1].contractSigned)}
                </DateSection>
                <LinkContainer>
                  <Link
                    variant="body2"
                    href={contracts[contracts?.length - 1].contractUrl}
                    target="_blank"
                  >
                    {t('documents.view')}
                  </Link>
                </LinkContainer>
              </ChannelWrapper>
            ) : (
              <EmptyContainer>{t('documents.noData')}</EmptyContainer>
            )}
            {channels &&
              channels.map(
                ({ id, status, channelName, termsAndConditions }) => {
                  return (
                    <React.Fragment key={id}>
                      {termsAndConditions && status === 'ACTIVE' && (
                        <ChannelWrapper>
                          <Title>
                            <Link
                              style={{
                                textDecoration: 'none',
                                color: '#000',
                              }}
                              component="button"
                              variant="body2"
                              onClick={() =>
                                history.push(
                                  `/home/finfluencer/channel/summary/${id}`
                                )
                              }
                            >
                              <ChannelSection>
                                Agreement for {channelName}
                              </ChannelSection>
                            </Link>
                          </Title>
                          <DateSection>
                            {termsAndConditions.channelAcceptedDate &&
                              formatDate(
                                termsAndConditions.channelAcceptedDate
                              )}
                          </DateSection>
                          <LinkContainer>
                            <Link
                              style={{
                                textDecoration: 'none',
                              }}
                              component="button"
                              variant="body2"
                              onClick={() =>
                                history.push(
                                  `/home/finfluencer/channel/summary/${id}`
                                )
                              }
                            >
                              {t('documents.accepted')}
                            </Link>
                          </LinkContainer>
                        </ChannelWrapper>
                      )}
                    </React.Fragment>
                  );
                }
              )}
          </>
        ) : (
          <PlxLoader />
        )}
      </div>
    </Body>
  );
};
