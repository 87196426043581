import { INavigationItem } from '../types';

export const spectrumNavigationItems: INavigationItem[] = [
  {
    label: 'overview',
    link: '/client/spectrum/overview',
    agencyLink: '/clienagencytrum/overview',
  },
  {
    label: 'engagement',
    link: '/home/client/spectrum/engagement',
    agencyLink: '/home/agency/spectrum/engagement',
  },
  {
    label: 'campaigns',
    link: '/home/client/spectrum/campaigns',
    agencyLink: '/home/agency/spectrum/campaigns',
  },
  {
    label: 'posts',
    link: '/home/client/spectrum/posts',
    agencyLink: '/home/agency/spectrum/posts',
    disabled: true,
  },
  {
    label: 'interactions',
    link: '/home/client/spectrum/interactions',
    agencyLink: '/home/agency/spectrum/interactions',
    disabled: true,
  },
  {
    label: 'audience',
    link: '/home/client/spectrum/audience',
    agencyLink: '/home/agency/spectrum/audience',
    disabled: true,
  },
  {
    label: 'influencers',
    link: '/home/client/spectrum/finfluencers',
    agencyLink: '/home/agency/spectrum/finfluencers',
    disabled: true,
  },
];

export const adminSpectrumNavigationItems: INavigationItem[] = [
  {
    label: 'Campaign Management',
    link: '/home/admin/spectrum-dashboard',
    agencyLink: '/home/agency/spectrum-dashboard',
  },
  {
    label: 'Client Analytics',
    link: '/home/admin/spectrum-analytics',
    agencyLink: '/home/admin/spectrum-analytics',
  },
];
