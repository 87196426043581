import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import { handleAccountConnect } from './functions/facebookConnectionFunctions';
import { useTranslation } from 'react-i18next';
import {
  StyleOverwrite,
  HeadingContainer,
  ProfileContainer,
  NameDiv,
  ManagedItemsList,
  ManagedListTitle,
  ListElement,
  ListElementProfileContainer,
  ListInfoContainer,
  ListElementTitle,
  ListElementDescription,
  FriendsDiv,
} from './styles/FacebookManagementPopup.styles';
import {
  IFacebookAccount,
  IFacebookGroup,
  IFacebookManagementPopup,
} from './interfaces';

export enum TokenLevelEnum {
  profile = 'profile',
  groups = 'groups',
  account = 'account',
  instagram = 'instagram',
}

export const FacebookManagementPopup = ({
  facebookData,
  fetchChannels,
  setFacebookData,
  connectedChannelsID,
  channelType,
}: IFacebookManagementPopup) => {
  const [open, setOpen] = useState(true);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      fetchChannels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const isConnected = (id: string): boolean => {
    return connectedChannelsID.includes(id);
  };
  return (
    <PlxDialog
      title={''}
      open={open}
      onClose={handleClose}
      backgroundColor="#f6f6f6"
    >
      <StyleOverwrite>
        <Grid container>
          <Grid item xs={12}>
            <HeadingContainer>
              <ProfileContainer>
                <img
                  className="rounded"
                  src={facebookData?.picture?.data.url}
                  alt={`${facebookData.name}`}
                />
                <NameDiv>
                  {facebookData.name}
                  <FriendsDiv>
                    {facebookData.friends?.summary.total_count}{' '}
                    {t('spectrum.socialMediaPopup.friends')}
                  </FriendsDiv>
                </NameDiv>
              </ProfileContainer>
              <div>
                {channelType.label === 'Connect to Facebook' && (
                  <PlxButton
                    label={
                      isConnected(facebookData.id as string)
                        ? t('spectrum.socialMediaPopup.connected')
                        : t('spectrum.socialMediaPopup.connect')
                    }
                    backgroundColor={
                      isConnected(facebookData.id as string) ? '#258004' : ''
                    }
                    isDisabled={isFormDisabled}
                    onClick={() =>
                      !isConnected(facebookData.id as string) &&
                      handleAccountConnect(
                        facebookData.id,
                        TokenLevelEnum.profile,
                        facebookData,
                        setFacebookData,
                        setIsFormDisabled,
                        handleClose
                      )
                    }
                  />
                )}
              </div>
            </HeadingContainer>
          </Grid>
          {facebookData.accounts && (
            <Grid item xs={12}>
              <Grid container direction="column" spacing={1}>
                <ManagedItemsList>
                  <ManagedListTitle>
                    {channelType.label === 'Connect to Facebook'
                      ? t('spectrum.socialMediaPopup.pages_you_manage')
                      : t('spectrum.socialMediaPopup.instagram_you_manage')}
                  </ManagedListTitle>
                  {facebookData.accounts.map(
                    (el: IFacebookAccount, index: number) => {
                      const connectionStatus = el.connected_instagram_account
                        ? isConnected(el.connected_instagram_account.id)
                        : false;
                      const connectionStatusBusiness = el.instagram_business_account
                        ? isConnected(el.instagram_business_account.id)
                        : false;

                      return channelType.label === 'Connect to Instagram' ? (
                        <>
                          {el.connected_instagram_account && (
                            <ListElement key={el.id ? el.id : index}>
                              <ListElementProfileContainer>
                                <img
                                  className="rounded"
                                  src={
                                    el?.connected_instagram_account
                                      ?.profile_picture_url
                                  }
                                  alt={`${el.connected_instagram_account.username}`}
                                />
                                <ListInfoContainer>
                                  <ListElementTitle>
                                    {el.connected_instagram_account.username}
                                  </ListElementTitle>
                                  <ListElementDescription>
                                    {el.connected_instagram_account
                                      .followers_count +
                                      ' ' +
                                      t('spectrum.socialMediaPopup.followers')}
                                  </ListElementDescription>
                                </ListInfoContainer>
                              </ListElementProfileContainer>
                              <PlxButton
                                backgroundColor={
                                  connectionStatus ? '#258004' : ''
                                }
                                label={
                                  connectionStatus ? 'Connected' : 'Connect'
                                }
                                isDisabled={isFormDisabled}
                                onClick={() =>
                                  !connectionStatus &&
                                  handleAccountConnect(
                                    el?.connected_instagram_account
                                      ?.id as string,
                                    TokenLevelEnum.instagram,
                                    facebookData,
                                    setFacebookData,
                                    setIsFormDisabled,
                                    handleClose,
                                    el?.connected_instagram_account
                                      ?.username as string,
                                    el?.connected_instagram_account
                                      ?.followers_count
                                  )
                                }
                              />
                            </ListElement>
                          )}
                          {el.instagram_business_account && (
                            <ListElement key={el.id ? el.id : index}>
                              <ListElementProfileContainer>
                                <img
                                  className="rounded"
                                  src={
                                    el?.instagram_business_account
                                      ?.profile_picture_url
                                  }
                                  alt={`${el.instagram_business_account.username}`}
                                />
                                <ListInfoContainer>
                                  <ListElementTitle>
                                    {el.instagram_business_account.username}
                                  </ListElementTitle>
                                  <ListElementDescription>
                                    {el.instagram_business_account
                                      .followers_count +
                                      ' ' +
                                      t('spectrum.socialMediaPopup.followers')}
                                  </ListElementDescription>
                                </ListInfoContainer>
                              </ListElementProfileContainer>
                              <PlxButton
                                backgroundColor={
                                  connectionStatusBusiness ? '#258004' : ''
                                }
                                label={
                                  connectionStatusBusiness
                                    ? 'Connected'
                                    : 'Connect'
                                }
                                isDisabled={isFormDisabled}
                                onClick={() =>
                                  !connectionStatusBusiness &&
                                  handleAccountConnect(
                                    el?.instagram_business_account
                                      ?.id as string,
                                    TokenLevelEnum.instagram,
                                    facebookData,
                                    setFacebookData,
                                    setIsFormDisabled,
                                    handleClose,
                                    el?.instagram_business_account
                                      ?.username as string,
                                    el?.instagram_business_account
                                      ?.followers_count
                                  )
                                }
                              />
                            </ListElement>
                          )}
                        </>
                      ) : (
                        <>
                          <ListElement key={el.id ? el.id : index}>
                            <ListElementProfileContainer>
                              <img
                                className="rounded"
                                src={el?.picture?.data?.url}
                                alt={`${el.name}`}
                              />
                              <ListInfoContainer>
                                <ListElementTitle>{el.name}</ListElementTitle>
                                <ListElementDescription>
                                  {el.category} {el.fan_count}{' '}
                                  {t('spectrum.socialMediaPopup.likes')}{' '}
                                  {el.followers_count}{' '}
                                  {t('spectrum.socialMediaPopup.followers')}
                                </ListElementDescription>
                              </ListInfoContainer>
                            </ListElementProfileContainer>
                            <PlxButton
                              backgroundColor={
                                isConnected(el.id as string) ? '#258004' : ''
                              }
                              label={
                                isConnected(el.id as string)
                                  ? 'Connected'
                                  : 'Connect'
                              }
                              isDisabled={isFormDisabled}
                              onClick={() =>
                                !isConnected(el.id as string) &&
                                handleAccountConnect(
                                  el.id,
                                  TokenLevelEnum.account,
                                  facebookData,
                                  setFacebookData,
                                  setIsFormDisabled,
                                  handleClose
                                )
                              }
                            />
                          </ListElement>
                        </>
                      );
                    }
                  )}
                </ManagedItemsList>
              </Grid>
            </Grid>
          )}
          {facebookData.groups && (
            <Grid item xs={12}>
              <Grid container direction="column" spacing={1}>
                <ManagedItemsList>
                  <ManagedListTitle>
                    {t('spectrum.socialMediaPopup.groups_you_manage')}
                  </ManagedListTitle>
                  {facebookData.groups.map(
                    (el: IFacebookGroup, index: number) => {
                      return (
                        <ListElement key={el.id ? el.id : index}>
                          <ListElementProfileContainer>
                            <img
                              className="rounded"
                              src={el.picture.data.url}
                              alt={`${el.name}`}
                            />
                            <ListInfoContainer>
                              <ListElementTitle>{el.name}</ListElementTitle>
                              <ListElementDescription>
                                {el.member_count}{' '}
                                {t('spectrum.socialMediaPopup.members')}
                              </ListElementDescription>
                            </ListInfoContainer>
                          </ListElementProfileContainer>
                          <PlxButton
                            backgroundColor={
                              isConnected(el.id as string) ? '#258004' : ''
                            }
                            label={
                              isConnected(el.id as string)
                                ? 'Connected'
                                : 'Connect'
                            }
                            isDisabled={isFormDisabled}
                            onClick={() =>
                              !isConnected(el.id as string) &&
                              handleAccountConnect(
                                el.id,
                                TokenLevelEnum.groups,
                                facebookData,
                                setFacebookData,
                                setIsFormDisabled,
                                handleClose
                              )
                            }
                          />
                        </ListElement>
                      );
                    }
                  )}
                </ManagedItemsList>
              </Grid>
            </Grid>
          )}
        </Grid>
      </StyleOverwrite>
    </PlxDialog>
  );
};

export default FacebookManagementPopup;
