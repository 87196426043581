import React, { FC } from 'react';
import { PlxTableHeaderSelect } from '_Components/PlxSelect/PlxTableHeaderSelect';
import { FilterSectionRow } from './Styles';
import {
  ages,
  countries,
  gender,
  other,
} from '_Routes/Home/_Components/Sections/NewChannelSection/options';

interface IProps {
  channelTypeList: string[];
  topicsList: string[];
  backgroundList: string[];
  designationsList: string[];
  onAgeChange: (value: string) => void;
  onGenderChange: (value: string) => void;
  onOtherChange: (value: string) => void;
  onFollowersAgeChange: (value: string) => void;
  onPrimaryLocationChange: (value: string) => void;
  onSecondaryLocationChange: (value: string) => void;
  onChannelTypeChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
  onTopicsChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
  onBackgroundChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
  onDesignationsChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
}

export const FilterSection: FC<IProps> = ({
  channelTypeList,
  topicsList,
  backgroundList,
  designationsList,
  onAgeChange,
  onGenderChange,
  onOtherChange,
  onFollowersAgeChange,
  onPrimaryLocationChange,
  onSecondaryLocationChange,
  onBackgroundChange,
  onChannelTypeChange,
  onDesignationsChange,
  onTopicsChange,
}) => {
  const agesList = [{ value: 'placeholder', label: 'Age' }].concat(ages);
  const gendersList = [{ value: 'placeholder', label: 'Gender' }].concat(
    gender
  );
  const otherList = [{ value: 'placeholder', label: 'Other' }].concat(other);
  const followersAgeList = [
    { value: 'placeholder', label: 'Audience Age' },
  ].concat(ages);
  const primaryLocationList = [
    { value: 'placeholder', label: 'Audience Primary Location' },
  ].concat(countries);
  const secondaryLocationList = [
    {
      value: 'placeholder',
      label: 'Audience Secondary Location',
    },
  ].concat(countries);

  return (
    <>
      <FilterSectionRow>
        <PlxTableHeaderSelect
          filterMulti
          onMultiChange={onChannelTypeChange}
          channelType
          filterName="Channel Type"
          multiOptions={channelTypeList}
        />
        <PlxTableHeaderSelect
          filterMulti
          onMultiChange={onTopicsChange}
          topics
          filterName="Topic"
          multiOptions={topicsList}
        />
        <PlxTableHeaderSelect
          filterMulti
          onMultiChange={onDesignationsChange}
          designation
          filterName="Designation"
          multiOptions={designationsList}
        />
        <PlxTableHeaderSelect
          filterMulti
          onMultiChange={onBackgroundChange}
          backgrounds
          filterName="Background"
          multiOptions={backgroundList}
        />
      </FilterSectionRow>
      <FilterSectionRow>
        <PlxTableHeaderSelect
          singleFilter
          initialValue={gendersList && gendersList[0].value}
          onChange={onGenderChange}
          options={gendersList}
        />
        <PlxTableHeaderSelect
          singleFilter
          initialValue={agesList && agesList[0].value}
          onChange={onAgeChange}
          options={agesList}
        />
        <PlxTableHeaderSelect
          singleFilter
          initialValue={otherList && otherList[0].value}
          onChange={onOtherChange}
          options={otherList}
        />
        <div style={{ flex: 1 }} />
      </FilterSectionRow>
      <FilterSectionRow>
        <PlxTableHeaderSelect
          singleFilter
          initialValue={followersAgeList && followersAgeList[0].value}
          onChange={onFollowersAgeChange}
          options={followersAgeList}
        />
        <PlxTableHeaderSelect
          singleFilter
          initialValue={primaryLocationList && primaryLocationList[0].value}
          onChange={onPrimaryLocationChange}
          options={primaryLocationList}
        />
        <PlxTableHeaderSelect
          singleFilter
          initialValue={secondaryLocationList && secondaryLocationList[0].value}
          onChange={onSecondaryLocationChange}
          options={secondaryLocationList}
        />
        <div style={{ flex: 1 }} />
      </FilterSectionRow>
    </>
  );
};
