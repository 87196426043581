import React, { FC, useEffect, useState } from 'react';
import { FormSection } from '_Components/FormSection/FormSection';
import { ICommonProps } from '_Types/props';
import { Option } from '../types';
import {
  CheckboxContainer,
  CheckboxLabel,
  Container,
  PlxWrapper,
  StyledCheckbox,
} from '../styles';

interface IProps extends ICommonProps {
  title?: string;
  tooltip?: string;
  description?: string;
  options: Option[];
  initialValue?: any;
  fieldName: string;
  required?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  testId?: string;
}
export const PlxSection: FC<IProps> = ({
  initialValue,
  tooltip,
  title,
  description,
  options,
  required,
  fieldName,
  className,
  setFieldValue,
  testId,
}) => {
  const [inputValue, setInputValue] = useState<string | number>('');

  useEffect(() => {
    initialValue && setInputValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (setFieldValue) {
      setFieldValue(fieldName, inputValue);
    }
    // eslint-disable-next-line
  }, [inputValue]);

  return (
    <FormSection
      className={className}
      title={title}
      tooltip={tooltip}
      description={description}
      required={required}
      testId={testId}
    >
      <PlxWrapper>
        {fieldName !== 'followersPrimaryAge' ? (
          <Container>
            <CheckboxContainer style={{ marginBottom: 0 }}>
              {options.map((option) => {
                return (
                  <CheckboxLabel>
                    <StyledCheckbox
                      type="checkbox"
                      name={option.label}
                      checked={inputValue === option.value}
                      onChange={() => setInputValue(option.value)}
                    />
                    {option.label}
                  </CheckboxLabel>
                );
              })}
            </CheckboxContainer>{' '}
          </Container>
        ) : (
          <CheckboxContainer style={{ marginBottom: 0 }}>
            {options.map((option) => {
              return (
                <CheckboxLabel>
                  <StyledCheckbox
                    type="checkbox"
                    name={option.label}
                    checked={inputValue === option.value}
                    onChange={() => setInputValue(option.value)}
                  />
                  {option.label}
                </CheckboxLabel>
              );
            })}
          </CheckboxContainer>
        )}
      </PlxWrapper>
    </FormSection>
  );
};
