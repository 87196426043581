import { IApproveChannelForm } from '_State/DistributionPartner/distributionPartner.types';
import styled from '@emotion/styled';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { InputSectionX } from '_Routes/Home/_Components/Sections/InputSectionX';
import { InputSection } from './Sections/InputSection';
import i18next from 'i18next';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { approveChannelActive } from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import { FormMassage } from '_Components/Forms/FormMassage';

const INITIAL_VALUES: IApproveChannelForm = {
  oldOffer: undefined,
  offer: undefined,
  offeringSummary: '',
  termsAndConditions: {
    fileName: 'download.pdf',
    base64Content:
      'JVBERi0xLjMKJf////8KOCAwIG9iago8PAovVHlwZSAvRXh0R1N0YXRlCi9j',
  },
};

const REQUIRED_FIELD = ['offer'];

const Wrapper = styled.div`
  min-width: 300px;
  height: 500px;
  display: block;
  width: 90%;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 5.5rem;
  margin-top: 7rem;
  margin-bottom: 4rem;

  .MuiButton-root {
    padding: 0.6rem 3.2rem;
  }
`;

const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELD);

interface IProps {
  apiError?: string;
  onBack: () => void;
  existingOffer?: number | undefined;
  performSubmit?: boolean;
  channelId?: number;
  closeWindowOffer: () => void;
}

export const OfferForm: FC<IProps> = ({
  existingOffer,
  onBack,
  performSubmit,
  channelId,
  closeWindowOffer,
}) => {
  const { t } = useTranslation();
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState<boolean>();

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELD,
    t('errors.reqField')
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        setIsProcessing(true);
        if (channelId) {
          approveChannelActive(form, channelId).then(
            () => {
              setIsProcessing(false);
              closeWindowOffer();
            },
            (error) => {
              error.response
                .json()
                .then((error: any) => setApiErrorMessage(error.message));
            }
          );
        }
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      if (form.offer && (isNaN(form.offer) || form.offer < 0)) {
        errors.offer = 'Please provide correct number value';
      }
      if (form.offer && form.offer > 2147483647) {
        errors.offer = 'Value is too big';
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const handleOffer = (offer: string) => {
    formik.setFieldValue('offer', offer);
    formik.setFieldValue(
      'offeringSummary',
      i18next.t('channels.approveChannel.offeringSummaryAutoFillNewOffer', {
        offerAmount: offer,
      })
    );
  };
  const handleBack = () => {
    onBack();
  };

  useEffect(() => {
    if (performSubmit !== undefined) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [performSubmit]);

  return (
    <>
      <Wrapper>
        <InputSectionX
          typeNumber
          title={t('influencer.addChannel.offer')}
          fieldName={'offer'}
          disabled
          value={existingOffer}
        />
        <FieldErrorMessage message={formik.errors.offer} />
        <InputSection
          typeNumber
          title={t('influencer.addChannel.newOffer')}
          placeholder={t('influencer.addChannel.newOffer')}
          required
          fieldName={'offer'}
          handleChange={(e: any) => handleOffer(e.target.value)}
          testId="offer-field"
        />
        <FieldErrorMessage message={formik.errors.offer} testId="offer-error" />
        <InputSection
          multiline
          rows={4}
          title={t('channels.approveChannel.offeringSummary')}
          placeholder={t('channels.approveChannel.offeringSummaryPlaceholder')}
          required
          fieldName={'offeringSummary'}
          handleChange={formik.handleChange}
          testId="summary-field"
          value={formik.values.offeringSummary}
        />
        <FieldErrorMessage
          message={formik.errors.offeringSummary}
          testId="summary-error"
        />
        {apiErrorMessage && <FormMassage message={apiErrorMessage} />}
        <ButtonsWrapper>
          <PlxButton
            label={t('channels.approveChannel.back')}
            onClick={handleBack}
            variant={ButtonVariant.Outlined}
            testId="back-button"
          />
          <PlxButton
            label={t('channels.approveChannel.submit')}
            onClick={formik.handleSubmit}
            isDisabled={isProcessing}
            isProcessing={isProcessing}
            testId="submit-button"
          />
        </ButtonsWrapper>
      </Wrapper>
    </>
  );
};
