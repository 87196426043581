import { Option } from '../types';

export const countries: Option[] = [
  { label: 'Afghanistan', value: 'AFGHANISTAN' },
  { label: 'Albania', value: 'ALBANIA' },
  { label: 'Algeria', value: 'ALGERIA' },
  { label: 'American Samoa', value: 'AMERICAN SAMOA' },
  { label: 'AndorrA', value: 'ANDORRA' },
  { label: 'Angola', value: 'ANGOLA' },
  { label: 'Anguilla', value: 'ANGUILLA' },
  { label: 'Antarctica', value: 'ANTARCTICA' },
  { label: 'Antigua and Barbuda', value: 'ANTIGUA AND BARBUDA' },
  { label: 'Argentina', value: 'ARGENTINA' },
  { label: 'Armenia', value: 'ARMENIA' },
  { label: 'Aruba', value: 'ARUBA' },
  { label: 'Australia', value: 'AUSTRALIA' },
  { label: 'Austria', value: 'AUSTRIA' },
  { label: 'Azerbaijan', value: 'AZERBAIJAN' },
  { label: 'Bahamas', value: 'BAHAMAS' },
  { label: 'Bahrain', value: 'BAHRAIN' },
  { label: 'Bangladesh', value: 'BANGLADESH' },
  { label: 'Barbados', value: 'BARBADOS' },
  { label: 'Belarus', value: 'BELARUS' },
  { label: 'Belgium', value: 'BELGIUM' },
  { label: 'Belize', value: 'BELIZE' },
  { label: 'Benin', value: 'BENIN' },
  { label: 'Bermuda', value: 'BERMUDA' },
  { label: 'Bhutan', value: 'BHUTAN' },
  { label: 'Bolivia', value: 'BOLIVIA' },
  { label: 'Bosnia and Herzegovina', value: 'BOSNIA AND HERZEGOVINA' },
  { label: 'Botswana', value: 'BOTSWANA' },
  { label: 'Bouvet Island', value: 'BOUVET ISLAND' },
  { label: 'Brazil', value: 'BRAZIL' },
  {
    label: 'British Indian Ocean Territory',
    value: 'BRITISH INDIAN OCEAN TERRITORY',
  },
  { label: 'Brunei Darussalam', value: 'BRUNEI DARUSSALAM' },
  { label: 'Bulgaria', value: 'BULGARIA' },
  { label: 'Burkina Faso', value: 'BURKINA FASO' },
  { label: 'Burundi', value: 'BURUNDI' },
  { label: 'Cambodia', value: 'CAMBODIA' },
  { label: 'Cameroon', value: 'CAMEROON' },
  { label: 'Canada', value: 'CANADA' },
  { label: 'Cape Verde', value: 'CAPE VERDE' },
  { label: 'Cayman Islands', value: 'CAYMAN ISLANDS' },
  { label: 'Central African Republic', value: 'CENTRAL AFRICAN REPUBLIC' },
  { label: 'Chad', value: 'CHAD' },
  { label: 'Chile', value: 'CHILE' },
  { label: 'China', value: 'CHINA' },
  { label: 'Christmas Island', value: 'CHRISTMAS ISLAND' },
  { label: 'Cocos (Keeling) Islands', value: 'COCOS (KEELING) ISLANDS' },
  { label: 'Colombia', value: 'COLOMBIA' },
  { label: 'Comoros', value: 'COMOROS' },
  { label: 'Congo', value: 'CONGO' },
  {
    label: 'Congo, The Democratic Republic of the',
    value: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
  },
  { label: 'Cook Islands', value: 'COOK ISLANDS' },
  { label: 'Costa Rica', value: 'COSTA RICA' },
  { label: 'Cote D"Ivoire', value: 'COTE D"IVOIRE' },
  { label: 'Croatia', value: 'CROATIA' },
  { label: 'Cuba', value: 'CUBA' },
  { label: 'Cyprus', value: 'CYPRUS' },
  { label: 'Czech Republic', value: 'CZECH REPUBLIC' },
  { label: 'Denmark', value: 'DENMARK' },
  { label: 'Djibouti', value: 'DJIBOUTI' },
  { label: 'Dominica', value: 'DOMINICA' },
  { label: 'Dominican Republic', value: 'DOMINICAN REPUBLIC' },
  { label: 'Ecuador', value: 'ECUADOR' },
  { label: 'Egypt', value: 'EGYPT' },
  { label: 'El Salvador', value: 'EL SALVADOR' },
  { label: 'Equatorial Guinea', value: 'EQUATORIAL GUINEA' },
  { label: 'Eritrea', value: 'ERITREA' },
  { label: 'Estonia', value: 'ESTONIA' },
  { label: 'Ethiopia', value: 'ETHIOPIA' },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'FALKLAND ISLANDS (MALVINAS)',
  },
  { label: 'Faroe Islands', value: 'FAROE ISLANDS' },
  { label: 'Fiji', value: 'FIJI' },
  { label: 'Finland', value: 'FINLAND' },
  { label: 'France', value: 'FRANCE' },
  { label: 'French Guiana', value: 'FRENCH GUIANA' },
  { label: 'French Polynesia', value: 'FRENCH POLYNESIA' },
  {
    label: 'French Southern Territories',
    value: 'FRENCH SOUTHERN TERRITORIES',
  },
  { label: 'Gabon', value: 'GABON' },
  { label: 'Gambia', value: 'GAMBIA' },
  { label: 'Georgia', value: 'GEORGIA' },
  { label: 'Germany', value: 'GERMANY' },
  { label: 'Ghana', value: 'GHANA' },
  { label: 'Gibraltar', value: 'GIBRALTAR' },
  { label: 'Greece', value: 'GREECE' },
  { label: 'Greenland', value: 'GREENLAND' },
  { label: 'Grenada', value: 'GRENADA' },
  { label: 'Guadeloupe', value: 'GUADELOUPE' },
  { label: 'Guam', value: 'GUAM' },
  { label: 'Guatemala', value: 'GUATEMALA' },
  { label: 'Guernsey', value: 'GUERNSEY' },
  { label: 'Guinea', value: 'GUINEA' },
  { label: 'Guinea-Bissau', value: 'GUINEA-BISSAU' },
  { label: 'Guyana', value: 'GUYANA' },
  { label: 'Haiti', value: 'HAITI' },
  {
    label: 'Heard Island and Mcdonald Islands',
    value: 'HEARD ISLAND AND MCDONALD ISLANDS',
  },
  {
    label: 'Holy See (Vatican City State)',
    value: 'HOLY SEE (VATICAN CITY STATE)',
  },
  { label: 'Honduras', value: 'HONDURAS' },
  { label: 'Hong Kong', value: 'HONG KONG' },
  { label: 'Hungary', value: 'HUNGARY' },
  { label: 'Iceland', value: 'ICELAND' },
  { label: 'India', value: 'INDIA' },
  { label: 'Indonesia', value: 'INDONESIA' },
  { label: 'Iran, Islamic Republic Of', value: 'IRAN, ISLAMIC REPUBLIC OF' },
  { label: 'Iraq', value: 'IRAQ' },
  { label: 'Ireland', value: 'IRELAND' },
  { label: 'Isle of Man', value: 'ISLE OF MAN' },
  { label: 'Israel', value: 'ISRAEL' },
  { label: 'Italy', value: 'ITALY' },
  { label: 'Jamaica', value: 'JAMAICA' },
  { label: 'Japan', value: 'JAPAN' },
  { label: 'Jersey', value: 'JERSEY' },
  { label: 'Jordan', value: 'JORDAN' },
  { label: 'Kazakhstan', value: 'KAZAKHSTAN' },
  { label: 'Kenya', value: 'KENYA' },
  { label: 'Kiribati', value: 'KIRIBATI' },
  {
    label: 'Korea, Democratic People"S Republic of',
    value: 'KOREA, DEMOCRATIC PEOPLE"S REPUBLIC OF',
  },
  { label: 'Korea, Republic of', value: 'KOREA, REPUBLIC OF' },
  { label: 'Kuwait', value: 'KUWAIT' },
  { label: 'Kyrgyzstan', value: 'KYRGYZSTAN' },
  {
    label: 'Lao People"S Democratic Republic',
    value: 'LAO PEOPLE"S DEMOCRATIC REPUBLIC',
  },
  { label: 'Latvia', value: 'LATVIA' },
  { label: 'Lebanon', value: 'LEBANON' },
  { label: 'Lesotho', value: 'LESOTHO' },
  { label: 'Liberia', value: 'LIBERIA' },
  { label: 'Libyan Arab Jamahiriya', value: 'LIBYAN ARAB JAMAHIRIYA' },
  { label: 'Liechtenstein', value: 'LIECHTENSTEIN' },
  { label: 'Lithuania', value: 'LITHUANIA' },
  { label: 'Luxembourg', value: 'LUXEMBOURG' },
  { label: 'Macao', value: 'MACAO' },
  {
    label: 'Macedonia, The Former Yugoslav Republic of',
    value: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
  },
  { label: 'Madagascar', value: 'MADAGASCAR' },
  { label: 'Malawi', value: 'MALAWI' },
  { label: 'Malaysia', value: 'MALAYSIA' },
  { label: 'Maldives', value: 'MALDIVES' },
  { label: 'Mali', value: 'MALI' },
  { label: 'Malta', value: 'MALTA' },
  { label: 'Marshall Islands', value: 'MARSHALL ISLANDS' },
  { label: 'Martinique', value: 'MARTINIQUE' },
  { label: 'Mauritania', value: 'MAURITANIA' },
  { label: 'Mauritius', value: 'MAURITIUS' },
  { label: 'Mayotte', value: 'MAYOTTE' },
  { label: 'Mexico', value: 'MEXICO' },
  {
    label: 'Micronesia, Federated States of',
    value: 'MICRONESIA, FEDERATED STATES OF',
  },
  { label: 'Moldova, Republic of', value: 'MOLDOVA, REPUBLIC OF' },
  { label: 'Monaco', value: 'MONACO' },
  { label: 'Mongolia', value: 'MONGOLIA' },
  { label: 'Montenegro', value: 'MONTENEGRO' },

  { label: 'Surilabel', value: 'SURILABEL' },
  { label: 'Svalbard and Jan Mayen', value: 'SVALBARD AND JAN MAYEN' },
  { label: 'Swaziland', value: 'SWAZILAND' },
  { label: 'Sweden', value: 'SWEDEN' },
  { label: 'Switzerland', value: 'SWITZERLAND' },
  { label: 'Syrian Arab Republic', value: 'SYRIAN ARAB REPUBLIC' },
  { label: 'Taiwan, Province of China', value: 'TAIWAN, PROVINCE OF CHINA' },
  { label: 'Tajikistan', value: 'TAJIKISTAN' },
  {
    label: 'Tanzania, United Republic of',
    value: 'TANZANIA, UNITED REPUBLIC OF',
  },
  { label: 'Thailand', value: 'THAILAND' },
  { label: 'Timor-Leste', value: 'TIMOR-LESTE' },
  { label: 'Togo', value: 'TOGO' },
  { label: 'Tokelau', value: 'TOKELAU' },
  { label: 'Tonga', value: 'TONGA' },
  { label: 'Trinidad and Tobago', value: 'TRINIDAD AND TOBAGO' },
  { label: 'Tunisia', value: 'TUNISIA' },
  { label: 'Turkey', value: 'TURKEY' },
  { label: 'Turkmenistan', value: 'TURKMENISTAN' },
  { label: 'Turks and Caicos Islands', value: 'TURKS AND CAICOS ISLANDS' },
  { label: 'Tuvalu', value: 'TUVALU' },
  { label: 'Uganda', value: 'UGANDA' },
  { label: 'Ukraine', value: 'UKRAINE' },
  { label: 'United Arab Emirates', value: 'UNITED ARAB EMIRATES' },
  { label: 'United Kingdom', value: 'UNITED KINGDOM' },
  { label: 'United States', value: 'UNITED STATES' },
  {
    label: 'United States Minor Outlying Islands',
    value: 'UNITED STATES MINOR OUTLYING ISLANDS',
  },
  { label: 'Uruguay', value: 'URUGUAY' },
  { label: 'Uzbekistan', value: 'UZBEKISTAN' },
  { label: 'Vanuatu', value: 'VANUATU' },
  { label: 'Venezuela', value: 'VENEZUELA' },
  { label: 'Viet Nam', value: 'VIET NAM' },
  { label: 'Virgin Islands, British', value: 'VIRGIN ISLANDS, BRITISH' },
  { label: 'Virgin Islands, U.S.', value: 'VIRGIN ISLANDS, U.S.' },
  { label: 'Wallis and Futuna', value: 'WALLIS AND FUTUNA' },
  { label: 'Western Sahara', value: 'WESTERN SAHARA' },
  { label: 'Yemen', value: 'YEMEN' },
  { label: 'Zambia', value: 'ZAMBIA' },
  { label: 'Zimbabwe', value: 'ZIMBABWE' },
];

export const ages = [
  { value: '18-24', label: '18-24' },
  { value: '25-35', label: '25-35' },
  { value: '35-55', label: '35-55' },
  { value: '55+', label: '55+' },
];

export const finances = [
  { value: 'AMAZON/AFFILIATE', label: 'Amazon/Affiliate' },
  { value: 'APPS/TECHNOLOGY', label: 'Apps/Technology' },
  { value: 'BUDGETING', label: 'Budgeting' },
  { value: 'BUSINESS', label: 'Business' },
  { value: 'CREDIT CARDS', label: 'Credit Cards' },
  { value: 'CREDIT (BUILDING)', label: 'Credit (Building)' },
  { value: 'DEBT', label: 'Debt' },
  { value: 'ECONOMY', label: 'Economy' },
  { value: 'EDUCATION', label: 'Education' },
  { value: 'ENTREPRENEURSHIP', label: 'Entrepreneurship' },
  { value: 'INSURANCE (WHOLE LIFE)', label: 'Insurance (Whole Life)' },
  { value: 'JOBS/SIDE HUSTLES', label: 'Jobs/Side Hustles' },
  { value: 'MONEY SAVING HACKS', label: 'Money Saving Hacks' },
  { value: 'MORTGAGES', label: 'Mortgages' },
  { value: 'PASSIVE INCOME', label: 'Passive Income' },
  { value: 'RETIREMENT', label: 'Retirement' },
  { value: 'SAVINGS', label: 'Savings' },
  { value: 'TAX', label: 'Tax' },
  { value: 'WEALTH', label: 'Wealth' },
];

export const stocks = [
  { value: 'PENNY/MICROCAP', label: 'Penny/Microcap' },
  { value: 'MID TO LARGE CAP', label: 'Mid to Large Cap' },
  { value: 'TRADING PLATFORMS', label: 'Trading Platforms' },
  { value: 'ETFS', label: 'ETFs' },
];
export const investing = [
  { value: 'REAL ESTATE', label: 'Real Estate' },
  { value: 'FOREX', label: 'Forex' },
  { value: 'BITCOIN/CRYPTO', label: 'Bitcoin/Crypto' },
  { value: 'DAY TRADING', label: 'Day Trading' },
];

export const themes = [
  { value: 'DANCING', label: 'Dancing' },
  { value: 'DIVORCE/RELATIONSHIP', label: 'Divorce/Relationship' },
  { value: 'FAITH', label: 'Faith' },
  { value: 'FASHION/BEAUTY', label: 'Fashion/Beauty' },
  { value: 'FITNESS', label: 'Fitness' },
  { value: 'FOOD/RESTAURANTS/MEAL PREP', label: 'Food/Restaurants/Meal Prep' },
  { value: 'HEALTH/WELLNESS', label: 'Health/Wellness' },
  { value: 'INTERVIEWS', label: 'Interviews' },
  { value: 'LUXURY', label: 'Luxury' },
  { value: 'MENTAL HEALTH', label: 'Mental Health' },
  { value: 'NEWCOMER/EXPAT', label: 'Newcomer/Expat' },
  { value: 'NON-PROFIT', label: 'Non-Profit' },
  { value: 'PARENTING/SINGLE MOM', label: 'Parenting/Single Mom' },
  { value: 'PHILANTHROPY', label: 'Philanthropy' },
  { value: 'STUDENT LIFE', label: 'Student Life' },
  { value: 'SUSTAINABILITY/ESG', label: 'Sustainability/ESG' },
  { value: 'TRAVEL', label: 'Travel' },
  { value: 'WOMEN SUPPORT', label: 'Women Support' },
];

export const designations = [
  { value: 'COACH', label: 'Coach' },
  { value: 'CPA/CFA', label: 'CPA/CFA' },
  { value: 'DOCTOR/NURSE', label: 'Doctor/Nurse' },
  { value: 'LAWYER', label: 'Lawyer' },
  { value: 'MORTGAGE BROKER', label: 'Mortgage Broker' },
  { value: 'PHYSICIAN', label: 'Physician' },
  { value: 'PSYCHOLOGIST', label: 'Psychologist' },
  { value: 'REALTOR', label: 'Realtor' },
  { value: 'WEALTH MANAGER', label: 'Wealth Manager' },
  { value: 'OTHER', label: 'Other' },
];

export const background = [
  { value: 'POC', label: 'POC' },
  { value: 'ASIAN', label: 'Asian' },
  { value: 'LATINO/LATINA', label: 'Latino/Latina' },
  { value: 'BLACK', label: 'Black' },
  { value: 'NATIVE', label: 'Native' },
  { value: 'WHITE', label: 'White' },
  { value: 'OTHER/PREFER NOT TO SAY', label: 'Other/Prefer not to say' },
];

export const gender = [
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' },
  { value: 'NON-BINARY', label: 'Non-Binary' },
  { value: 'LGBTQ2S+', label: 'LGBTQ2S+' },
  { value: 'OTHER/PREFER NOT TO SAY', label: 'Other/Prefer not to say' },
];

export const other = [
  { value: 'PERMANENT RESIDENT/EXPAT', label: 'Permanent Resident/Expat' },
  { value: 'STUDENT', label: 'Student' },
  { value: 'RETIREE', label: 'Retiree' },
  { value: 'NOT APPLICABLE', label: 'Not applicable' },
];
