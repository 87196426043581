import React, { FC, useContext, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { TableWrap, TopHeaderWrap } from './SpectrumTable.styles';
import { TopChannels } from './TopChannels';
import { TopPosts } from './TopPosts';
import { TopFinfluencers } from './TopFinfluencers';
import { TopCampaigns } from './TopCampaigns';
import {
  getCampaignData,
  getChannelData,
  getTopFinfluencerData,
  getTopPostData,
} from '_State/Spectrum/Api/spectrum.api';
import {
  ICampaignData,
  IChannelData,
  ITopFinfluencerData,
  PostData,
} from './utils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  getCampaignDataForAdmin,
  getChannelDataForAdmin,
  getTopFinfluencerDataForAdmin,
  getTopPostDataForAdmin,
} from '_State/User/Api/admin-user.api';
import { NavigationContext } from '_Routes/Home/NavigationContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const TabPanelHeader = ({
  header,
  subHeader,
}: {
  header: string;
  subHeader: string;
}): JSX.Element => {
  return (
    <TopHeaderWrap>
      <span>{header}</span>
      <p>{subHeader}</p>
    </TopHeaderWrap>
  );
};
const a11yProps = (index: any) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '3rem',
  },
}));

export const SpectrumTable: FC<{
  startDate: string;
  endDate: string;
  agencyId?: number;
  clientSelected: string | null;
  isAdmin?: number;
}> = ({ startDate, endDate, clientSelected, isAdmin, agencyId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [campaignData, setCampaignData] = useState<ICampaignData[]>([]);
  const [channelData, setChannelData] = useState<IChannelData[]>([]);
  const [postData, setPostData] = useState<PostData[]>([]);
  const { isAgencyType } = useContext(NavigationContext);
  const [finfluencerData, setFinfluencerData] = useState<ITopFinfluencerData[]>(
    []
  );

  useEffect(() => {
    const start = startDate === 'Invalid date' ? '2020-01-01' : startDate;
    const end =
      endDate === 'Invalid date'
        ? moment(new Date()).format('yyyy-MM-DD')
        : endDate;

    if (!!isAdmin) {
      getCampaignDataForAdmin(start, end, isAdmin, agencyId).then((res) => {
        setCampaignData(res);
      });
      getChannelDataForAdmin(start, end, isAdmin, agencyId).then((res) => {
        setChannelData(res);
      });
      getTopPostDataForAdmin(start, end, isAdmin, agencyId).then((res) => {
        setPostData(res);
      });
      getTopFinfluencerDataForAdmin(start, end, isAdmin, agencyId).then(
        (res) => {
          const newRes = res.filter(
            (finfluencer: any) => finfluencer.firstName
          );
          setFinfluencerData(newRes);
        }
      );
    } else {
      getCampaignData(start, end, clientSelected).then((res) => {
        setCampaignData(res);
      });
      getChannelData(start, end, clientSelected).then((res) => {
        setChannelData(res);
      });
      getTopPostData(start, end, clientSelected).then((res) => {
        setPostData(res);
      });
      getTopFinfluencerData(start, end, clientSelected).then((res) => {
        const newRes = res.filter((finfluencer: any) => finfluencer.firstName);
        setFinfluencerData(newRes);
      });
    }
  }, [startDate, endDate, clientSelected, agencyId, isAdmin]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <TableWrap className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label={
              <TabPanelHeader
                header={t('spectrum.topSpectrumTable.topCampaigns')}
                subHeader={`${campaignData.length} active campaigns`}
              />
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <TabPanelHeader
                header={t('spectrum.topSpectrumTable.topChannels')}
                subHeader={`${channelData.length} active channels`}
              />
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <TabPanelHeader
                header={t('spectrum.topSpectrumTable.topPosts')}
                subHeader={`${postData.length} posts published`}
              />
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <TabPanelHeader
                header={t('spectrum.topSpectrumTable.topInfluencers')}
                subHeader={`${finfluencerData.length} active influencers`}
              />
            }
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TopCampaigns
          campaignData={campaignData}
          isAdmin={isAdmin}
          isAgencyType={isAgencyType}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TopChannels channelData={channelData} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TopPosts
          postData={postData}
          isAdmin={isAdmin}
          isAgencyType={isAgencyType}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TopFinfluencers finfluencerData={finfluencerData} />
      </TabPanel>
    </TableWrap>
  );
};
