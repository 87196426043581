import styled from '@emotion/styled';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XemInfoPanel } from '_Components/Panels/PlxInfoPanel/XemInfoPanel';
import { useHistory } from 'react-router-dom';
import { PlxLoader } from '_Components/PlxLoader';
import {
  // deleteChannel,
  getUserChannels,
  postTiktok,
  postTwitter,
  postYoutube,
  updateOfferAcceptingOption,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import {
  IDistributionPartnerChannelDTO,
  SOCIAL_MEDIA_BY_COLOR_MAP,
} from '_State/DistributionPartner/distributionPartner.types';
import { channelTypeConfig } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerChannel/_Routes/distributionPartnerForm.config';
import { Link } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
  ChannelStatus,
  EligibleChannelType,
} from '_State/DistributionPartner/distributionPartner.enums';
import { CHANNEL_STATUS_MAP_STRATEGY } from '_State/DistributionPartner/distributionPartner.types';
import { ChannelStatusCell } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelStatusCell';
import { getChannelStatus } from '_Routes/Home/_routes/Admin/_Routes/Channels/utils';
import { EmptyContainer, TrimString } from './../../utils';
import { orderBy } from 'lodash';
import { PlxCheckbox } from '_Components/PlxCheckbox/PlxCheckbox';
import FacebookLoginComponent from './FacebookLoginComponent';
// import { PlxSimpleDialog } from '_Components/Dialogs/PlxSimpleDialog';
// import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';

const Body = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  margin-top: 3rem;
  display: flex;
  align-self: center;

  a {
    text-decoration: none;
  }
`;

const ChannelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 1rem;
`;

const Channel = styled.div`
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  border: 1px solid #d5dde3;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 1rem;
  height: 65px;
`;

const ChannelTypeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;

  svg {
    height: 20px;
    max-width: 22px;
  }
`;
const ChannelName = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #1b1b1b;
  flex: 5;
  flex-direction: column;
  && a {
    color: #000;
    font-size: 1rem;
  }
`;
const ChannelCurrentStatus = styled.div`
  font-weight: 300;
  font-size: 1rem;
  /* margin-left: 2rem; */
`;
const Actions = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: end;
  flex: 10;

  && .MuiButton-outlinedSecondary.Mui-disabled {
    border: none;
  }
`;

// const Title = styled.div`
//   font-family: Rubik;
//   font-weight: 500;
//   font-size: 2.1rem;
//   line-height: 4.1rem;
//   text-align: center;
//   color: #1b1b1b;
// `;
// const Buttons = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-top: 3rem;
//   gap: 1rem;
// `;
// const StyledButton = styled(PlxButtonX)`
//   width: 11rem;
// `;
// const DelButton = styled(Button)`
//   && {
//     margin: 0;
//     border-radius: 0;
//     border: none;
//     background: #e9e9e9;
//     color: #000;
//     padding: 2rem;
//     font-weight: 300;
//     height: 100%;
//     :hover {
//       border: none;
//       color: #fff;
//       background: #dc534d;
//     }
//   }
// `;
const CompleteButton = styled(Button)`
  && {
    margin: 0;
    border-radius: 0;
    border-right: 1px solid #fff;
    background: #e9e9e9;
    color: #000;
    padding: 2rem;
    font-weight: 300;
    height: 100%;

    :hover {
      color: #fff;
      background: #ea8423;
    }
  }
`;
const AcceptingOfferWrap = styled.div`
  flex: 6;
  font-size: 1.1rem;

  > div {
    align-items: center;
    > div {
      line-height: 12px;
    }
  }
`;

const UpdateButton = styled.button`
  display: inline-flex;
  padding: 0.7rem 1rem;
  color: #fff;
  background: #006aff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: unset;
  width: 188px;
  color: #ffffff;
  font-family: Quicksand;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 2rem;
  :hover {
    cursor: pointer;
    color: #fff;
    background: #34c3f3;
    border: #ffffff;
  }
`;

interface BtnProps {
  bgcolor: any;
  color: any;
}
export const ConnectBtnWrap = styled(Button)<BtnProps>`
  && {
    background: ${({ bgcolor }) => bgcolor};
    margin: 0;
    height: 65px;
    width: 130px;
    border-radius: 0 5px 5px 0;
    padding: 0;
    font-size: 1.2rem;
    text-align: left;
    span {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div {
      display: flex;
      color: ${({ color }) => color};
      text-decoration: none;
      margin-top: -1px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      transition: all 0.5s;

      > span:first-of-type {
        flex: 1;
      }
      > span:last-of-type {
        flex: 3;
        display: flex;
        justify-content: left;
        align-items: center;
        line-height: 1.5rem;
        font-weight: 600;
      }
    }
    img {
      margin: 1rem;
    }
    :hover {
      background-color: #000;
    }
  }
`;
const storeChannelId = (id: number) => {
  sessionStorage.setItem('channelId', JSON.stringify(id));
};

export const DistributionPartnerChannelsInformation: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [channels, setChannels] = useState<IDistributionPartnerChannelDTO[]>(
    []
  );

  // const [infoForSocialConnector, setInfoForSocialConnector] = useState<{
  //   name: string;
  //   channelId?: number;
  //   channelType?: string;
  // }>({ channelId: undefined, name: '', channelType: '' });
  // const [channelIdToDelete, setChannelIdToDelete] = useState<any>();
  // const [
  //   deleteConfirmationDialogOpen,
  //   setDeleteConfirmationDialogOpen,
  // ] = useState(false);
  const channelsRef = React.useRef<HTMLInputElement>(null);
  const fetchChannels = () => {
    return getUserChannels().then((v) => {
      const orderChannels = orderBy(v, 'id', 'asc');
      setChannels(orderChannels);
      return orderChannels;
    });
  };

  const handleUpdatePreferences = () => {
    history.push('/home/finfluencer/channel/update-channel');
  };

  const ConnectBtn: FC<any> = ({
    channelType,
    id,
    fetchChannels,
    connectedChannelsID,
    distributionPartner,
  }) => {
    const handleBtnClick = () => {
      storeChannelId(id);
      // setInfoForSocialConnector({
      //   channelId: id,
      //   name: `${distributionPartner.firstName} ${distributionPartner.lastName}`,
      //   channelType: `${channelType}`,
      // });
    };
    return (
      <React.Fragment key={id}>
        {channelType?.label === 'Connect to Facebook' ||
        channelType?.label === 'Connect to Instagram' ? (
          <FacebookLoginComponent
            channelType={channelType}
            id={id}
            fetchChannels={fetchChannels}
            connectedChannelsID={connectedChannelsID}
          >
            <ConnectBtnWrap
              color={channelType?.color}
              bgcolor={channelType?.bgcolor}
            >
              <div>
                <img
                  src={channelType?.logo}
                  alt={channelType?.label}
                  width="29"
                />
                <span>{channelType?.label}</span>
              </div>
            </ConnectBtnWrap>
          </FacebookLoginComponent>
        ) : (
          <Link href={channelType.url} onClick={handleBtnClick}>
            <ConnectBtnWrap
              color={channelType?.color}
              bgcolor={channelType?.bgcolor}
            >
              <div>
                <img
                  src={channelType?.logo}
                  alt={channelType?.label}
                  width="29"
                />
                <span>{channelType?.label}</span>
              </div>
            </ConnectBtnWrap>
          </Link>
        )}
      </React.Fragment>
    );
  };

  // const openDeleteConfirmationDialog = (channelId: number) => {
  //   setChannelIdToDelete(channelId);
  //   setDeleteConfirmationDialogOpen(true);
  // };

  // const closeDeleteConfirmationDialog = () => {
  //   setDeleteConfirmationDialogOpen(false);
  // };

  // const handleDeleteChannel = (channelId: number) => {
  //   setLoading(true);
  //   deleteChannel(channelId).then(() => {
  //     fetchChannels().then(() => setLoading(false));
  //   });
  // };

  const handleCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    channelId: number
  ) => {
    updateOfferAcceptingOption(event.target.checked, channelId).then(() => {
      fetchChannels();
    });
  };
  useEffect(() => {
    if (window.location.hash) {
      const elem = document.getElementById(window.location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    fetchChannels().then(() => setLoading(false));
  }, []);

  const handleCompleteProfileInitialization = () => {
    history.push('/home/finfluencer/channel/add-channel');
  };

  const connectedChannelsID = channels
    .filter((el) => el.status === 'CONNECTED')
    .map((el) => el.socialMediaNodeId);

  useEffect(() => {
    const queryParams: URLSearchParams = new URLSearchParams(
      window.location.search
    );
    const code = queryParams.get('code');
    const pathname = window.location.pathname;
    const channelId = Number(sessionStorage.getItem('channelId'));
    if (code !== null && code !== undefined) {
      if (pathname === '/home/finfluencer/profile/twitter')
        postTwitter(channelId, code).then(() => {
          fetchChannels();
          sessionStorage.removeItem('channelId');
        });
      else if (pathname === '/home/finfluencer/profile/tiktok')
        postTiktok(channelId, code).then(() => {
          fetchChannels();
          sessionStorage.removeItem('channelId');
        });
      else if (pathname === '/home/finfluencer/profile/youtube')
        postYoutube(channelId, code).then(() => {
          fetchChannels();
          sessionStorage.removeItem('channelId');
        });
    }
  }, []);

  return (
    <Body id="channels" ref={channelsRef}>
      {loading ? (
        <PlxLoader />
      ) : (
        <XemInfoPanel
          label={t('profile.myChannels')}
          withButton
          buttonLabel={t('influencer.dashboard.welcomePage.addChannel')}
          onButtonClick={handleCompleteProfileInitialization}
        >
          {channels.length > 0 ? (
            <ChannelsWrapper>
              {channels.map(
                ({
                  id,
                  channelType,
                  channelName,
                  dontWantOffer,
                  status,
                  channelUrl,
                  distributionPartner,
                }) => (
                  <React.Fragment key={id}>
                    <Channel>
                      <ChannelTypeIcon>
                        {channelTypeConfig.filter(
                          (v) => v.value === channelType
                        )[0] ? (
                          channelTypeConfig.filter(
                            (v) => v.value === channelType
                          )[0].icon
                        ) : (
                          <span />
                        )}
                      </ChannelTypeIcon>
                      <ChannelName>
                        <TrimString paragraph={channelName} maxLetters={18} />
                        <div>
                          <a
                            href={channelUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TrimString
                              paragraph={channelUrl}
                              maxLetters={23}
                            />
                          </a>
                        </div>
                        <ChannelCurrentStatus>
                          <ChannelStatusCell
                            status={
                              (CHANNEL_STATUS_MAP_STRATEGY as any)[
                                getChannelStatus(status)
                              ]
                            }
                          />
                        </ChannelCurrentStatus>
                      </ChannelName>
                      <AcceptingOfferWrap>
                        <PlxCheckbox
                          value={dontWantOffer}
                          label={t('influencer.addChannel.dontWantOffer')}
                          name={'dontWantOffer'}
                          onChange={(e) => handleCheckBox(e, id)}
                          testId="no-offer-checkbox"
                        />
                      </AcceptingOfferWrap>
                      <Actions>
                        {status ===
                          ChannelStatus[ChannelStatus.OFFER_PLACED] && (
                          <CompleteButton
                            // variant={ButtonVariant.Outlined}
                            data-test-id="complete-button"
                            size={'small'}
                            onClick={() =>
                              history.push(
                                `/home/finfluencer/channel/summary/${id}`
                              )
                            }
                          >
                            {t('profile.viewOffer')}
                          </CompleteButton>
                        )}
                        {/* <DelButton
                          // disabled={!isDeletable}
                          variant="outlined"
                          color="secondary"
                          size={'small'}
                          onClick={() => openDeleteConfirmationDialog(id)}
                        >
                          Delete
                        </DelButton> */}
                        {(status === 'ACTIVE' ||
                          status === 'DISABLED' ||
                          status === 'EXPIRED') && (
                          <>
                            {Object.values(EligibleChannelType).includes(
                              channelType as EligibleChannelType
                            ) &&
                              channelType !== 'X' && (
                                <ConnectBtn
                                  fetchChannels={fetchChannels}
                                  id={id}
                                  connectedChannelsID={connectedChannelsID}
                                  channelType={
                                    (SOCIAL_MEDIA_BY_COLOR_MAP as any)[
                                      channelType ? channelType : 'DEFAULT'
                                    ]
                                  }
                                  distributionPartner={distributionPartner}
                                />
                              )}
                          </>
                        )}

                        {status === 'CONNECTED' &&
                          Object.values(EligibleChannelType).includes(
                            channelType as EligibleChannelType
                          ) && (
                            <ConnectBtnWrap
                              style={{
                                cursor: 'auto',
                              }}
                              color={
                                (SOCIAL_MEDIA_BY_COLOR_MAP as any)[
                                  channelType ? channelType : 'DEFAULT'
                                ].color
                              }
                              bgcolor={
                                (SOCIAL_MEDIA_BY_COLOR_MAP as any)[
                                  channelType ? channelType : 'DEFAULT'
                                ].bgcolor
                              }
                            >
                              <div>
                                <span>
                                  <img
                                    src={
                                      (SOCIAL_MEDIA_BY_COLOR_MAP as any)[
                                        channelType ? channelType : 'DEFAULT'
                                      ].logo
                                    }
                                    alt={
                                      (SOCIAL_MEDIA_BY_COLOR_MAP as any)[
                                        channelType ? channelType : 'DEFAULT'
                                      ].label
                                    }
                                    width="29"
                                  />
                                </span>
                                <span>
                                  {t('spectrum.socialMediaPopup.connected')}
                                </span>
                              </div>
                            </ConnectBtnWrap>
                          )}
                      </Actions>
                    </Channel>
                    {/* <PlxSimpleDialog
                      open={deleteConfirmationDialogOpen}
                      onClose={closeDeleteConfirmationDialog}
                    >
                      <Title>{t('channels.deleteConfirmation.title')}</Title>
                      <Buttons>
                        <StyledButton
                          onClick={() => closeDeleteConfirmationDialog()}
                          label={t('channels.deleteConfirmation.cancel')}
                        />
                        <StyledButton
                          onClick={() => handleDeleteChannel(channelIdToDelete)}
                          label={t('channels.deleteConfirmation.delete')}
                          // variant={ButtonVariant.Outlined}
                        />
                      </Buttons>
                    </PlxSimpleDialog> */}
                  </React.Fragment>
                )
              )}
              <UpdateButton onClick={handleUpdatePreferences}>
                {t('dashboard.updatePreferences.updateButtonLable')}
              </UpdateButton>
            </ChannelsWrapper>
          ) : (
            <>
              <EmptyContainer>{t('profile.noChannels')}</EmptyContainer>
            </>
          )}
        </XemInfoPanel>
      )}
    </Body>
  );
};
