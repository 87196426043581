import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import styled from '@emotion/styled';
import { NavigationPanel } from '_Routes/Home/NavigationPanel/NavigationPanel';
import { Dashboard } from '_Routes/Home/_routes/Dashboard/Dashboard';
import { Spectrum } from '_Routes/Home/_routes/Spectrum/Spectrum';
import { SpectrumWelcomePage } from './_routes/Spectrum/_Routes/SpectrumWelcomePage/SpectrumWelcomePage';
import { SpectrumDashboard } from './_routes/Spectrum/_Routes/SpectrumDashboard/SpectrumDashboard';
import { SpectrumDashboardProcessing } from '_Routes/Home/_routes/Spectrum/SpectrumDashboardProcessing/SpectrumDashboardProcessing';
import { ThriveWelcomePage } from './_routes/Thrive/Routes/ThriveWelcomePage/ThriveWelcomePage';
import { ThriveDashboardProcessing } from './_routes/Thrive/Routes/ThriveDashboardProcessing/ThriveDashboardProcessing';
import { Thrive } from '_Routes/Home/_routes/Thrive/Thrive';
import { SpectrumCampaignReport } from './_routes/Spectrum/_Routes/SpectrumDashboard/_Routes/SpectrumCampaignReport/SpectrumCampaignReport';
import { ThriveDashboard } from './_routes/Thrive/Routes/ThriveDashboard/ThriveDashboard';
import { ThriveCampaignReport } from './_routes/Thrive/Routes/ThriveDashboard/_Routes/ThriveCampaignReport/ThriveCampaignReport';
import { ThriveCampaignAssets } from '_Routes/Home/_routes/Thrive/Routes/ThriveDashboard/_Routes/ThriveCampaignAssets/ThriveCampaignAssets';
import { Profile } from '_Routes/Home/_routes/Profile/Profile';
import { INavigationContext, NavigationContext } from './NavigationContext';
import { useSelector } from 'react-redux';
import { IState } from '_State';
import { UserType } from '_State/User/user.types';
import { Admin } from '_Routes/Home/_routes/Admin/Admin';
import { Finfluencer } from './_routes/DistributionPartner/Finfluencer';
import { FinfluencerContactUs } from './_routes/DistributionPartner/_Routes/FinfluencerContactUs/FinfluencerContactUs';
import { IScreenSize, useWindowSize } from '_App/utils';
import { muiTheme } from '_Styles/theme';
import ExpandableDrawer from './NavigationPanel/Drawer/ExpandableDrawer';
import SpectrumEngagement from './_routes/Spectrum/_Routes/SpectrumEngagement/SpectrumEngagement';
import { getDistributionPartnerProfileInfo } from '_State/DistributionPartner/Api/distributionPartner.api';
import { XemFinfluencerContract } from './_routes/DistributionPartner/_Routes/DistributionPartnerDocuments/XemFinfluencerContract';
import { SpectrumCampaign } from './_routes/Spectrum/_Routes/SpectrumCampaign/SpectrumCampaign';
import { TopNavigation } from './TopNavigation';
import { AddClient } from '_Routes/Auth/Agency/AddClient';
import { getClients } from '_State/User/Api/user.api';
import { identifyUser, trackScreenView } from './segmentUtils';
import { AgencyClientsTable } from './_routes/Spectrum/_Routes/AgencyClients/AgencyClientsTable';

const HomeBody = styled.div`
  display: flex;
  background-color: #f8f9fc;
  font-family: 'Quicksand';
  font-style: normal;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const ADMIN_TYPE_ROLE: string[] = [UserType.ADMIN, UserType.EDITOR];

const isAdminTypeRole = (state: IState): boolean => {
  const userType = state.user.user?.userType;
  return ADMIN_TYPE_ROLE.includes(userType || '');
};

export const isDistributionPartnerRole = (state: IState): boolean => {
  const userType = state.user.user?.userType;
  return userType === UserType.DISTRIBUTION_PARTNER;
};

export const isAgencyTypeRole = (state: IState): boolean => {
  const userType = state.user.user?.userType;
  return userType === UserType.AGENCY;
};

export const Home: FC = () => {
  const size: IScreenSize = useWindowSize();
  const isAdminType = useSelector(isAdminTypeRole);
  const isDistributionPartner = useSelector(isDistributionPartnerRole);
  const isAgencyType = useSelector(isAgencyTypeRole);
  const { user } = useSelector((state: IState) => state.user);
  const isMobile = size.width <= muiTheme.screenSizes.sm;
  const [navBarExpanded, setNavBarExpanded] = useState<boolean>(true);
  const [contractAccepted, setContractAccepted] = useState<boolean | undefined>(
    true
  );
  const [clientAdded, setClientAdded] = useState<boolean | undefined>(true);
  const [clients, setClients] = useState<any>();
  const [clientSelected, setClientSelected] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      identifyUser(user);
      trackScreenView(user, 'Home Screen');
    }
  }, [user]);

  const updateSelectedClient = (value: string | null) => {
    sessionStorage.setItem('clientSelected', value || '');
    setClientSelected(value);
  };

  const context: INavigationContext = {
    navBarExpanded,
    onExpandClick: () => setNavBarExpanded(!navBarExpanded),
    clientSelected,
    clients,
    updateSelectedClient,
    isAgencyType,
  };

  const fetchData = () => {
    return getClients()
      .then(({ content }) => {
        const data = content.map((obj: any) => ({
          ...obj,
          id: obj.id.toString(),
        }));
        setClients(data);
      })
      .catch((error) => error.response.json().then(() => {}));
  };

  useEffect(() => {
    isAgencyType && fetchData().then(() => {});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMobile) setNavBarExpanded(false);
    else setNavBarExpanded(true);
  }, [isMobile]);

  useLayoutEffect(() => {
    if (isDistributionPartner) {
      getDistributionPartnerProfileInfo().then((res) => {
        setContractAccepted(res.contractAccepted);
      });
    }
  }, [isDistributionPartner]);

  useLayoutEffect(() => {
    if (isAgencyType) {
      getClients().then((res) => {
        res.content.length === 0 && setClientAdded(false);
      });
    }
  }, [isAgencyType]);

  useEffect(() => {
    const clientSelected = sessionStorage.getItem('clientSelected');
    if (clients) {
      if (clientSelected === '') {
        setClientSelected(null);
      } else {
        setClientSelected(clientSelected as string);
      }
    }
  }, [clients]);

  return (
    <HomeBody>
      <NavigationContext.Provider value={context}>
        {isMobile ? (
          <ExpandableDrawer />
        ) : (
          <NavigationPanel
            admin={isAdminType}
            finfluencer={isDistributionPartner}
          />
        )}
        <ContentWrapper>
          {!isAdminType && !isDistributionPartner && (
            <TopNavigation isAgency={isAgencyType} />
          )}
          {isAdminType ? (
            <Route path="/home/admin">
              <Admin />
            </Route>
          ) : isDistributionPartner ? (
            contractAccepted ? (
              <Route path="/home/finfluencer">
                <Finfluencer />
              </Route>
            ) : (
              <XemFinfluencerContract />
            )
          ) : isAgencyType && !clientAdded ? (
            <AddClient userName={user?.firstName || ''} />
          ) : (
            <>
              <Route exact path="/home/client/dashboard">
                <Dashboard />
              </Route>
              <Route exact path="/home/client/spectrum-start">
                <SpectrumWelcomePage />
              </Route>
              <Route path="/home/client/spectrum-campaign">
                <Spectrum />
              </Route>
              <Route exact path="/home/client/spectrum-dashboard">
                <SpectrumDashboard />
              </Route>
              <Route exact path="/home/client/spectrum-dashboard/campaign/:id">
                <SpectrumCampaignReport />
              </Route>
              <Route exact path="/home/client/spectrum-processing">
                <SpectrumDashboardProcessing />
              </Route>
              <Route exact path="/home/client/spectrum/overview">
                <SpectrumDashboard />
              </Route>
              <Route exact path="/home/client/spectrum/engagement">
                <SpectrumEngagement />
              </Route>
              <Route exact path="/home/client/spectrum/campaigns">
                <SpectrumCampaign />
              </Route>
              <Route exact path="/home/client/thrive-start">
                <ThriveWelcomePage />
              </Route>
              <Route exact path="/home/client/thrive-processing">
                <ThriveDashboardProcessing />
              </Route>
              <Route exact path="/home/client/thrive-dashboard">
                <ThriveDashboard />
              </Route>
              <Route exact path="/home/client/thrive-dashboard/campaign/:id">
                <ThriveCampaignReport />
              </Route>
              <Route
                exact
                path="/home/client/thrive-dashboard/campaign/assets/:id"
              >
                <ThriveCampaignAssets />
              </Route>
              <Route exact path="/home/client/profile">
                <Profile />
              </Route>
              <Route exact path="/home/client/contact">
                <FinfluencerContactUs />
              </Route>
              <Route path="/home/client/thrive-campaign">
                <Thrive />
              </Route>
              <Route exact path="/home/agency/dashboard">
                <Dashboard />
              </Route>
              <Route exact path="/home/agency/spectrum-start">
                <SpectrumWelcomePage />
              </Route>
              <Route path="/home/agency/spectrum-campaign">
                <Spectrum />
              </Route>
              <Route exact path="/home/agency/spectrum-dashboard">
                <SpectrumDashboard />
              </Route>
              <Route exact path="/home/agency/spectrum-dashboard/campaign/:id">
                <SpectrumCampaignReport />
              </Route>
              <Route exact path="/home/agency/spectrum-processing">
                <SpectrumDashboardProcessing />
              </Route>
              <Route exact path="/home/agency/spectrum/overview">
                <SpectrumDashboard />
              </Route>
              <Route exact path="/home/agency/spectrum/engagement">
                <SpectrumEngagement />
              </Route>
              <Route exact path="/home/agency/spectrum/clients">
                <AgencyClientsTable />
              </Route>
              <Route exact path="/home/agency/spectrum/campaigns">
                <SpectrumCampaign />
              </Route>
              <Route exact path="/home/agency/thrive-start">
                <ThriveWelcomePage />
              </Route>
              <Route exact path="/home/agency/thrive-processing">
                <ThriveDashboardProcessing />
              </Route>
              <Route exact path="/home/agency/thrive-dashboard">
                <ThriveDashboard />
              </Route>
              <Route exact path="/home/agency/thrive-dashboard/campaign/:id">
                <ThriveCampaignReport />
              </Route>
              <Route
                exact
                path="/home/agency/thrive-dashboard/campaign/assets/:id"
              >
                <ThriveCampaignAssets />
              </Route>
              <Route exact path="/home/agency/profile">
                <Profile />
              </Route>
              <Route exact path="/home/agency/contact">
                <FinfluencerContactUs />
              </Route>
              <Route path="/home/agency/thrive-campaign">
                <Thrive />
              </Route>
            </>
          )}
        </ContentWrapper>
      </NavigationContext.Provider>
    </HomeBody>
  );
};
