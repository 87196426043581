import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const MessageWrap = styled.div`
  padding: 0 5rem 1rem;
  max-width: 600px;
  span {
    color: #545454;
    line-height: 2rem;
    font-size: 1.5rem;
  }
`;
const SecondLine = styled.div`
  padding: 1rem 0;
  font-size: 2rem;
`;
interface IProps {
  switchName: string;
}
export const FinfluencerSubscriptionDialog: FC<IProps> = ({ switchName }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');

  useEffect(() => {
    switch (switchName) {
      case 'productUpdates':
        setMessage(t('influencer.contactUs.productUpdateAlert'));
        break;
      case 'opportunityInvitations':
        setMessage(t('influencer.contactUs.opportunityInvitationsAlert'));
        break;
      case 'campaignNotifications':
        setMessage(t('influencer.contactUs.campaignNotificationsAlert'));

        break;
      case 'payoutNotifications':
        setMessage(t('influencer.contactUs.payoutNotificationsAlert'));
        break;
    }
  }, [switchName, t]);

  return (
    <MessageWrap>
      <SecondLine>{t('influencer.contactUs.sureToUnsubscribe')}</SecondLine>
      <span>{message}</span>
    </MessageWrap>
  );
};
