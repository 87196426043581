import React, { FC, useEffect, useState } from 'react';
import { FormSection } from '_Components/FormSection/FormSection';
import { ICommonProps } from '_Types/props';
import { DropDownMenu } from './DropDownMenu';
import { Option } from '../types';

interface IProps extends ICommonProps {
  placeholder?: string;
  options: Option[];
  title?: string;
  tooltip?: string;
  value?: any;
  fieldName: string;
  required?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  testId?: string;
  setValue?: (field: string, value: any, shouldValidate?: boolean) => void;
}
export const InputSectionDropDown: FC<IProps> = ({
  value,
  tooltip,
  title,
  required,
  options,
  fieldName,
  placeholder,
  className,
  setFieldValue,
  testId,
  setValue,
}) => {
  const [inputValue, setInputValue] = useState<string | number>(value);

  useEffect(() => {
    if (setFieldValue) {
      setFieldValue(fieldName, inputValue);
    }
    // eslint-disable-next-line
  }, [inputValue]);

  const onChange = (value: string) => {
    setInputValue(value);
    setValue && fieldName && setValue(fieldName, value);
  };

  return (
    <FormSection
      className={className}
      title={title}
      tooltip={tooltip}
      required={required}
      testId={testId}
    >
      <DropDownMenu
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        initialValue={value}
      />
    </FormSection>
  );
};
