import styled from '@emotion/styled';
import React, { FC, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { DISTRIBUTION_PARTNER_NEW_CHANNEL_PROGRESS_STRATEGY } from '_State/DistributionPartner/distributionPartner.types';
// import { IThemed } from '_Styles/types';
import { distributionPartnerActions } from '_State/DistributionPartner/distributionPartner.state';
import { IState } from '_State';
// import { PlxStepper } from '_Components/PlxStepper/PlxStepper';
import { HomePageWrapper } from '_Routes/Home/_Components/HomePageWrapper';
import { FormWrapper } from '_Components/Wrappers/FormWrapper';
import { DistributionPartnerCompleteProfile } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerChannel/_Routes/DistributionPartnerCompleteProfile';
import { DistributionPartnerAddChannel } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerChannel/_Routes/DistributionPartnerAddChannel';
import { DistributionPartnerReviewingChannel } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerChannel/_Routes/DistributionPartnerReviewingChannel';
import { DistributionPartnerSummary } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerChannel/_Routes/DistributionPartnerSummary';
import { getDistributionPartnerProfileInfo } from '_State/DistributionPartner/Api/distributionPartner.api';
import { PlxLoader } from '_Components/PlxLoader';
import { DistributionPartnerUpdatePreferences } from '../FinfluencerDashboard/DistributionPartnerUpdatePreferences';
// import { boolean } from 'yup';

// const stepperConfigWWithCompleteProfile = [
//   'influencer.tracker.completeProfile',
//   'influencer.tracker.addChannel',
//   'influencer.tracker.summary',
//   'influencer.tracker.agreement',
// ];

// const stepperConfig = [
//   'influencer.tracker.addChannel',
//   'influencer.tracker.summary',
//   'influencer.tracker.agreement',
// ];

const SpectrumWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

// const Stepper = styled.div<IThemed>`
//   width: 20rem;
//   display: flex;
//   height: 100%;
//   background-color: ${({ theme }) => theme.palette.background.paper};
//   border-right: 1px solid #d5dde3;
// `;
export const DistributionPartnerChannel: FC = () => {
  // const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  // const [profileCompleted, setProfileCompleted] = useState(true);
  const [loading, setLoading] = useState(true);
  const step = useSelector<IState, string>(
    (state) => state.distributionPartner.step
  );
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    Object.keys(DISTRIBUTION_PARTNER_NEW_CHANNEL_PROGRESS_STRATEGY).filter(
      // eslint-disable-next-line
      (o) => {
        const loc = location.pathname
          .substring(location.pathname.lastIndexOf('/') + 1)
          .replace('-', '')
          .toLowerCase();
        if (o.toUpperCase().includes(loc.toUpperCase())) {
          dispatch(distributionPartnerActions.changeStep(loc));
        }
      }
    );

    setActiveStep(
      (DISTRIBUTION_PARTNER_NEW_CHANNEL_PROGRESS_STRATEGY as any)[step]
    );
    // eslint-disable-next-line
  }, [location, step, activeStep]);

  useEffect(() => {
    getDistributionPartnerProfileInfo().then(
      () => {
        // setProfileCompleted(true);
        setLoading(false);
      },
      () => {
        // setProfileCompleted(false);
        setLoading(false);
      }
    );
  }, []);

  // const handleStepClick = (index: number) => {};

  return (
    <>
      {!loading ? (
        <SpectrumWrapper>
          {/* <Stepper>
            <PlxStepper
              onStepClick={handleStepClick}
              steps={
                profileCompleted
                  ? stepperConfig.map((v) => t(v))
                  : stepperConfigWWithCompleteProfile.map((v) => t(v))
              }
              activeStep={activeStep}
            />
          </Stepper> */}
          <HomePageWrapper>
            <FormWrapper>
              <Route exact path="/home/finfluencer/channel">
                <Redirect to={'/home/finfluencer/channel/complete-profile'} />
              </Route>
              <Route exact path="/home/finfluencer/channel/complete-profile">
                <DistributionPartnerCompleteProfile />
              </Route>
              <Route exact path="/home/finfluencer/channel/add-channel">
                <DistributionPartnerAddChannel />
              </Route>
              <Route exact path="/home/finfluencer/channel/update-channel">
                <DistributionPartnerUpdatePreferences />
              </Route>
              <Route exact path="/home/finfluencer/channel/reviewing">
                <DistributionPartnerReviewingChannel />
              </Route>
              <Route exact path="/home/finfluencer/channel/summary/:id">
                <DistributionPartnerSummary />
              </Route>
            </FormWrapper>
          </HomePageWrapper>
        </SpectrumWrapper>
      ) : (
        <PlxLoader />
      )}
    </>
  );
};
