import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { IThemed } from '_Styles/types';
import { NavigationContext } from '_Routes/Home/NavigationContext';
import { DistributionPartnerDashboardHeader } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerDashboard/DistributionPartnerDashboardHeader';
import { DistributionPartnerCampaignsTableX } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerDashboard/DistributionPartnerCampaignsTableX';
import { useHistory } from 'react-router-dom';
import { PlxLoader } from '_Components/PlxLoader';
import { PlxTilesPanelX } from '_Components/Panels/PlxTilesPanel/PlxTilesPanelX';
import { ITile } from '_Types/ITile';
import { createChannelStatisticsConfig } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerDashboard/utils';
import {
  getNotificationData,
  getOpportunities,
  getStatisticData,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import {
  IAllNotifications,
  IDistributionPartnerChannelStatistics,
  IDistributionPartnerOpportunityDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import { useTranslation } from 'react-i18next';
import { DistributionPartnerDashboardNotifications } from './DistributionPartnerDashboardNotifications';
import { DistributionPartnerDashboardChannelsDetailsContainer } from './DistributionPartnerDashboardChannelsDetailsContainer';
import { IDistributionPartnerChannelDTO } from '_State/DistributionPartner/distributionPartner.types';
import { getUserChannels } from '_State/DistributionPartner/Api/distributionPartner.api';
import { SectionHeader, TrimString } from './../../utils';
import { PlxList } from '_Components/PlxList/PlxList';
import { xemCreateListConfig } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerOpportunities/utils';
import { CellConfig } from '_Components/PlxCustomTable/PlxCustomTable';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { CampaignDetails } from '../DistributionPartnerOpportunities/CampaignDetails';
import { getRecentTransactions } from '_State/Spectrum/Api/spectrum.api';
import { XPropTransactions } from '../FinfluencerWallet/utils';
import { tableHeaderOptions } from '../FinfluencerWallet/FinfluencerWallet';
import { XemTableHeader } from '_Components/TableHeader/XemTableHeader';
import { XemTableRow } from '_Components/XemTableRow/XemTableRow';
import { PlxText } from '_Components/Text/PlxText';
import { formatDate } from '_Components/DateFormat/DateFormat';
import { StandardizeAmount } from '_Components/StandardizeAmount/StandardizeAmount';
import { EligibleChannelType } from '_State/DistributionPartner/distributionPartner.enums';

export const Body = styled.div<{
  isExpanded: boolean;
}>`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  width: 100%;
`;

const Wrapper = styled.div<IThemed>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EmptyContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  padding: 1.7rem 0 1.7rem 2rem;
`;

const SectionWrap = styled.div`
  width: 100%;
`;
const orderArr = [
  'NEEDS_WORK',
  'ANALYTICS_NEEDED',
  'REVIEW_ANALYTICS',
  'ACTIVE',
  'COMPLETED',
];

export const DistributionPartnerDashboard: FC = () => {
  const { t } = useTranslation();
  const { navBarExpanded } = useContext(NavigationContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState<
    IDistributionPartnerChannelStatistics
  >();
  const [allNotifications, setAllNotifications] = useState<IAllNotifications[]>(
    []
  );
  const [channels, setChannels] = useState<{
    connectedChannels: IDistributionPartnerChannelDTO[];
    pendingChannels: IDistributionPartnerChannelDTO[];
    activeChannels: IDistributionPartnerChannelDTO[];
    disabledChannels: IDistributionPartnerChannelDTO[];
  }>({
    connectedChannels: [],
    pendingChannels: [],
    activeChannels: [],
    disabledChannels: [],
  });
  const [notConnectedChannels, setNotConnectedChannels] = useState<
    IDistributionPartnerChannelDTO[]
  >([]);
  const [campaigns, setCampaigns] = useState<
    IDistributionPartnerOpportunityDTO[]
  >([]);
  const [participatingCampaigns, setParticipatingCampaigns] = useState<
    IDistributionPartnerOpportunityDTO[]
  >([]);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [urlSubmitted, setUrlSubmitted] = useState(false);
  const [analyticsSubmitted, setAnalyticsSubmitted] = useState(false);
  const [campaignDetailsIds, setCampaignDetailsIds] = useState<{
    campaignId?: number;
    opportunityId?: number;
  }>({
    campaignId: undefined,
    opportunityId: undefined,
  });
  const [lastTransaction, setLastTransaction] = useState<XPropTransactions>();

  useEffect(() => {
    getNotificationData().then(
      (n) => {
        setAllNotifications(n);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    getStatisticData().then(
      (v) => {
        setStatistics(v);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        // setLoading(false);
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (channels.activeChannels) {
      const notConnectedChannels = channels.activeChannels.filter((ac) =>
        Object.values(EligibleChannelType).includes(
          ac.channelType as EligibleChannelType
        )
      );
      setNotConnectedChannels(notConnectedChannels);
    }
  }, [channels]);

  const getDistributionPartnerLink = async () => {
    try {
      return await getUserChannels().then(
        (v) => {
          if (v.length > 0) {
            const connectedChannels = v.filter((v) => v.status === 'CONNECTED');
            const activeChannels = v.filter((v) => v.status === 'ACTIVE');
            const channelsToUpdate = connectedChannels
              .concat(activeChannels)
              .filter((channel) => {
                return (channel.topics ?? []).length === 0;
              });
            if (channelsToUpdate.length > 0) {
              return '/home/finfluencer/channel/update-channel';
            } else {
              const pendingChannels = v.filter((v) => v.status === 'PENDING');
              const disabledChannels = v.filter((v) => v.status === 'DISABLED');

              setChannels({
                connectedChannels,
                pendingChannels,
                activeChannels,
                disabledChannels,
              });
              return '/home/finfluencer/dashboard';
            }
          } else {
            return '/home/finfluencer/channel/add-channel';
          }
        },
        () => {
          return '/home/finfluencer/channel/add-channel';
        }
      );
    } catch (httpError) {
      console.log(httpError);
      return '/home/finfluencer/channel/add-channel';
    }
  };
  useEffect(() => {
    getDistributionPartnerLink().then((path) => {
      if (path !== '/home/finfluencer/dashboard') {
        history.push(path);
      }
    });
  }, [history]);

  useEffect(() => {
    // getDistributionPartnerLink();
    getOpportunities().then(
      (o) => {
        const orderByStatus = o
          .slice()
          .sort(
            ({ status: q }, { status: w }) =>
              orderArr.indexOf(q) - orderArr.indexOf(w)
          );
        setCampaigns(orderByStatus);
        setParticipatingCampaigns(
          orderByStatus.filter((os) => os.status !== 'COMPLETED')
        );
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  }, [urlSubmitted, analyticsSubmitted]);

  const closeDetails = (): void => {
    setDetailsOpen(false);
  };
  const channelsStatisticsConfig: ITile[] = useMemo(() => {
    return createChannelStatisticsConfig(statistics) as ITile[];
  }, [statistics]);
  const openDetails = (campaignId: number, opportunityId: number): void => {
    setCampaignDetailsIds({
      campaignId: campaignId,
      opportunityId: opportunityId,
    });
    setDetailsOpen(true);
  };
  const config = useMemo(() => {
    return xemCreateListConfig(openDetails) as CellConfig<{}>[];
    // eslint-disable-next-line
  }, []);
  const handleUrlSubmitted = () => {
    setUrlSubmitted(!urlSubmitted);
  };
  const handleAnalyticsSubmitted = () => {
    setAnalyticsSubmitted(!analyticsSubmitted);
  };
  useEffect(() => {
    getRecentTransactions().then((res) => {
      setLastTransaction(res[res.length - 1]);
    });
  }, []);

  return (
    <Body isExpanded={navBarExpanded}>
      <Wrapper>
        {loading ? (
          <PlxLoader />
        ) : (
          <>
            {/* {numberOfNewOpportunities &&
              numberOfNewOpportunities > 0 &&
              alert('you have a new opportunity')} */}

            <DistributionPartnerDashboardHeader />

            {/* Notifications */}
            <SectionWrap>
              <DistributionPartnerDashboardNotifications
                notifications={allNotifications}
                notConnectedChannels={notConnectedChannels}
              />
            </SectionWrap>

            {/*Statistics To Date*/}
            <SectionWrap>
              <SectionHeader>{t('dashboard.statisticsToDate')}</SectionHeader>
              <PlxTilesPanelX tiles={channelsStatisticsConfig} />
            </SectionWrap>

            {/* Connected  Channels */}
            <SectionWrap>
              <SectionHeader>{t('dashboard.connectedChannels')}</SectionHeader>
              {channels.connectedChannels.length > 0 ? (
                channels.connectedChannels.map(
                  (
                    {
                      id,
                      dontWantOffer,
                      status,
                      channelName,
                      channelType,
                      earnings,
                      offer,
                      channelUrl,
                      numberOfFollowers,
                      reactions,
                      comments,
                      shares,
                      recentPostCount,
                    },
                    index
                  ) => {
                    return (
                      <React.Fragment key={index}>
                        {!dontWantOffer && (
                          <DistributionPartnerDashboardChannelsDetailsContainer
                            status={status}
                            id={id}
                            channelName={channelName}
                            channelType={channelType}
                            offer={offer}
                            earnings={earnings}
                            channelUrl={channelUrl}
                            numberOfFollowers={numberOfFollowers}
                            reactions={reactions}
                            comments={comments}
                            shares={shares}
                            recentPostCount={recentPostCount}
                          />
                        )}
                        {dontWantOffer && offer > 0 && (
                          <DistributionPartnerDashboardChannelsDetailsContainer
                            status={status}
                            id={id}
                            channelName={channelName}
                            channelType={channelType}
                            offer={offer}
                            earnings={earnings}
                            channelUrl={channelUrl}
                            numberOfFollowers={numberOfFollowers}
                            reactions={reactions}
                            comments={comments}
                            shares={shares}
                            recentPostCount={recentPostCount}
                            disable
                          />
                        )}
                      </React.Fragment>
                    );
                  }
                )
              ) : (
                <EmptyContainer>
                  {t('influencer.dashboard.noConnectedChannel')}
                </EmptyContainer>
              )}
            </SectionWrap>

            {/* Pending Channels */}
            <SectionWrap>
              <SectionHeader>{t('dashboard.pendingChannels')}</SectionHeader>
              {channels.pendingChannels.length > 0 ? (
                channels.pendingChannels.map(
                  (
                    {
                      id,
                      dontWantOffer,
                      status,
                      channelName,
                      channelType,
                      earnings,
                      offer,
                      channelUrl,
                      numberOfFollowers,
                    },
                    index
                  ) => {
                    return (
                      <React.Fragment key={index}>
                        {!dontWantOffer && (
                          <DistributionPartnerDashboardChannelsDetailsContainer
                            status={status}
                            id={id}
                            channelName={channelName}
                            channelType={channelType}
                            offer={offer}
                            earnings={earnings}
                            channelUrl={channelUrl}
                            numberOfFollowers={numberOfFollowers}
                          />
                        )}
                        {dontWantOffer && offer > 0 && (
                          <DistributionPartnerDashboardChannelsDetailsContainer
                            status={status}
                            id={id}
                            channelName={channelName}
                            channelType={channelType}
                            offer={offer}
                            earnings={earnings}
                            channelUrl={channelUrl}
                            numberOfFollowers={numberOfFollowers}
                            disable
                          />
                        )}
                      </React.Fragment>
                    );
                  }
                )
              ) : (
                <EmptyContainer>
                  {t('influencer.dashboard.noPendingChannel')}
                </EmptyContainer>
              )}
            </SectionWrap>

            {/*Active Channels */}
            <SectionWrap>
              <SectionHeader>{t('dashboard.activeChannels')}</SectionHeader>
              {channels.activeChannels.length > 0 ? (
                channels.activeChannels.map(
                  (
                    {
                      id,
                      dontWantOffer,
                      status,
                      channelName,
                      channelType,
                      earnings,
                      offer,
                      channelUrl,
                      numberOfFollowers,
                    },
                    index
                  ) => {
                    return (
                      <React.Fragment key={index}>
                        {!dontWantOffer && (
                          <DistributionPartnerDashboardChannelsDetailsContainer
                            status={status}
                            id={id}
                            channelName={channelName}
                            channelType={channelType}
                            offer={offer}
                            earnings={earnings}
                            channelUrl={channelUrl}
                            numberOfFollowers={numberOfFollowers}
                          />
                        )}
                        {dontWantOffer && offer > 0 && (
                          <DistributionPartnerDashboardChannelsDetailsContainer
                            status={status}
                            id={id}
                            channelName={channelName}
                            channelType={channelType}
                            offer={offer}
                            earnings={earnings}
                            channelUrl={channelUrl}
                            numberOfFollowers={numberOfFollowers}
                            disable
                          />
                        )}
                        {/* {dontWantOffer === true && offer === 0 && (
                          <React.Fragment key={index}>
                            disable++++++++++++++
                            <DistributionPartnerDashboardChannelsDetailsContainer
                              status={status}
                              id={id}
                              channelName={channelName}
                              channelType={channelType}
                              offer={offer}
                              earnings={earnings}
                              channelUrl={channelUrl}
                              disable
                            />
                          </React.Fragment>
                        )} */}
                      </React.Fragment>
                    );
                  }
                )
              ) : (
                <EmptyContainer>
                  {t('influencer.dashboard.noActiveChannel')}
                </EmptyContainer>
              )}
            </SectionWrap>

            {/* Disabled Channels */}
            <SectionWrap>
              <SectionHeader>{t('dashboard.disabledChannels')}</SectionHeader>
              {channels.disabledChannels.length > 0 ? (
                channels.disabledChannels.map(
                  (
                    {
                      id,
                      dontWantOffer,
                      status,
                      channelName,
                      channelType,
                      earnings,
                      offer,
                      channelUrl,
                      numberOfFollowers,
                    },
                    index
                  ) => {
                    return (
                      <React.Fragment key={index}>
                        {!dontWantOffer && (
                          <DistributionPartnerDashboardChannelsDetailsContainer
                            status={status}
                            id={id}
                            channelName={channelName}
                            channelType={channelType}
                            offer={offer}
                            earnings={earnings}
                            channelUrl={channelUrl}
                            numberOfFollowers={numberOfFollowers}
                          />
                        )}
                        {dontWantOffer && offer > 0 && (
                          <React.Fragment>
                            <DistributionPartnerDashboardChannelsDetailsContainer
                              status={status}
                              id={id}
                              channelName={channelName}
                              channelType={channelType}
                              offer={offer}
                              earnings={earnings}
                              channelUrl={channelUrl}
                              numberOfFollowers={numberOfFollowers}
                              disable
                            />
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    );
                  }
                )
              ) : (
                <EmptyContainer>
                  {t('influencer.dashboard.noDisabledChannel')}
                </EmptyContainer>
              )}
            </SectionWrap>

            {/* Participating Campaigns */}
            <SectionWrap style={{ marginTop: '2rem' }}>
              <SectionHeader>
                {t('dashboard.participatingCampaigns')}
              </SectionHeader>
              {participatingCampaigns.length > 0 ? (
                <PlxList
                  visibility={'seen'}
                  data={participatingCampaigns}
                  config={config}
                />
              ) : (
                <EmptyContainer>
                  {t('dashboard.emptyParticipatingCampaigns')}
                </EmptyContainer>
              )}
              <PlxDialog
                title={t('channels.campaignDetails.title')}
                open={detailsOpen}
                onClose={closeDetails}
              >
                <CampaignDetails campaignDetailsIds={campaignDetailsIds} />
              </PlxDialog>
            </SectionWrap>

            {/* Last transaction */}
            <SectionWrap>
              <SectionHeader>{t('dashboard.lastTransaction')}</SectionHeader>
              {lastTransaction ? (
                <>
                  <XemTableHeader headers={tableHeaderOptions} />
                  <XemTableRow key={lastTransaction.transactionId}>
                    <div>{formatDate(lastTransaction.approvedDate)}</div>
                    <div>{lastTransaction.transactionId}</div>
                    <div>
                      <PlxText
                        tooltipText={lastTransaction.finfluencerPayoutEmail}
                      >
                        <TrimString
                          paragraph={lastTransaction.finfluencerPayoutEmail}
                          maxLetters={25}
                        ></TrimString>
                      </PlxText>
                    </div>
                    <div>{lastTransaction.channelName}</div>
                    <div>{lastTransaction.campaignId}</div>
                    <div>
                      {lastTransaction.transactionSuccess ? (
                        <span style={{ color: 'green' }}>Successful</span>
                      ) : (
                        <span style={{ color: '#FF8C00' }}>Processing</span>
                      )}
                    </div>
                    <div>
                      $
                      <StandardizeAmount amount={lastTransaction.amount} />
                    </div>
                  </XemTableRow>
                </>
              ) : (
                <EmptyContainer>
                  {t('dashboard.emptyLastTransaction')}
                </EmptyContainer>
              )}
            </SectionWrap>

            {/* Campaigns Table */}
            {campaigns.length > 0 && (
              <DistributionPartnerCampaignsTableX
                campaigns={campaigns}
                urlSubmitted={handleUrlSubmitted}
                analyticsSubmitted={handleAnalyticsSubmitted}
              />
            )}
          </>
        )}
      </Wrapper>
    </Body>
  );
};
