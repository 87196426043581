import React, { FC, useEffect, useRef, useState } from 'react';
import { SectionHeader } from '../../utils';
import styled from '@emotion/styled';
import { PlxSwitch } from '_Components/PlxCheckbox/PlxSwitch';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
// import { updateSubscription } from '_State/DistributionPartner/Api/distributionPartner.api';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { FinfluencerSubscriptionDialog } from './FinfluencerSubscriptionDialog';
import { useTranslation } from 'react-i18next';
import {
  getSubscription,
  updateSubscription,
} from '_State/DistributionPartner/Api/distributionPartner.api';

const Body = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  margin-top: 3rem;
  display: flex;
  align-self: center;
`;
const SubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  width: 100%;
`;
const Items = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  padding: 0 2rem;
  color: #000;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem;
  gap: 2rem;
`;
interface IProps {
  campaignNotifications: boolean;
  opportunityInvitation: boolean;
  // payoutNotifications: boolean;
  // productUpdates: boolean;
}
export const DistributionPartnerSubscriptions: FC = () => {
  const { t } = useTranslation();
  // const [loading, setLoading] = useState(false);

  const [state, setState] = useState<IProps>({
    campaignNotifications: false,
    opportunityInvitation: false,
    // productUpdates: false,
    // payoutNotifications: false,
  });
  const [handleDialog, setHandleDialog] = useState(false);
  const [switchEventName, setSwitchEventName] = useState<string>('');
  const [switchEventChecked, setSwitchEventChecked] = useState<boolean>();
  const initialRender = useRef(false);

  useEffect(() => {
    getSubscription().then((res) => {
      setState({
        opportunityInvitation: res.opportunityInvitation,
        campaignNotifications: res.campaignNotifications,
      });
    });
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      updateSubscription(state);
    } else {
      initialRender.current = true;
    }
  }, [state]);

  const handleSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked === true) {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
    } else {
      setHandleDialog(true);
      setSwitchEventName(event.target.name);
      setSwitchEventChecked(event.target.checked);
      setState({ ...state, [event.target.name]: event.target.checked });
    }
  };

  const handleCancel = () => {
    setHandleDialog(false);
    setState({
      ...state,
      [switchEventName]: !switchEventChecked,
    });
  };

  return (
    <Body>
      {/* {loading ? (
        <PlxLoader />
      ) : ( */}
      <SubscriptionWrapper>
        <SectionHeader>{t('influencer.contactUs.subscriptions')}</SectionHeader>
        {/* <Items>
          <PlxSwitch
            value={state.productUpdates}
            label={t('influencer.contactUs.productUpdate')}
            name={'productUpdates'}
            onChange={handleSwitchChange}
          />
        </Items> */}
        <Items>
          <PlxSwitch
            value={state.opportunityInvitation}
            label={t('influencer.contactUs.opportunityInvitations')}
            name={'opportunityInvitation'}
            onChange={handleSwitchChange}
          />
        </Items>
        <Items>
          <PlxSwitch
            value={state.campaignNotifications}
            label={t('influencer.contactUs.campaignNotifications')}
            name={'campaignNotifications'}
            onChange={handleSwitchChange}
          />
        </Items>
        {/* <Items>
          <PlxSwitch
            label={t('influencer.contactUs.payoutNotifications')}
            name={'payoutNotifications'}
            value={state.payoutNotifications}
            onChange={handleSwitchChange}
          />
        </Items> */}
        <br />
        <br />
        {/* <Items>
        <PlxSwitch
          value={state.UnsubscribeFromAll}
          label="Unsubscribe from all"
          name={'UnsubscribeFromAll'}
          onChange={handleSwitchChange}
        />
      </Items> */}
        <PlxDialog title={''} open={handleDialog} onClose={handleCancel}>
          <div>
            <FinfluencerSubscriptionDialog switchName={switchEventName} />
            <ButtonWrap>
              <PlxButtonX onClick={() => setHandleDialog(false)}>
                {t('influencer.contactUs.confirm')}
              </PlxButtonX>
              <PlxButtonX onClick={handleCancel}>
                {t('influencer.contactUs.cancel')}
              </PlxButtonX>
            </ButtonWrap>
          </div>
        </PlxDialog>
      </SubscriptionWrapper>
      {/* )} */}
    </Body>
  );
};
