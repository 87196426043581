import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IAnalyticsDTO,
  IDistributionPartnerChannelSelectableRow,
  IUpdateableAnalyticsDTO,
  IUrlDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import { xemCreateSpectrumChannelsTableConfig } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/AdminSpectrumCampaign/configs';
import {
  declineStatistics,
  getEligibleChannels,
  inviteChannels,
  cancelChannels,
  toggleRebrandlyUrl,
  getDomains,
} from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import { ReviewAnalyticsDialog } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/AdminSpectrumCampaign/ReviewAnalyticsDialog';
import { Dialog } from '@material-ui/core';
import { PlxPanel } from '_Components/Panels/PlxPanel/PlxPanel';
import { ReactComponent as ConfirmationIcon } from '_Styles/images/_Icons/Confirmation.svg';
import { PlxButton } from '_Components/Buttons/PlxButton/PlxButton';
import { InputSection } from '_Routes/Home/_Components/Sections/InputSection';
import {
  Body,
  Content,
  Description,
  InputBody,
  Label,
  StyledDialog,
  Wrapper,
} from './Styles';
import { XemTable } from '_Components/XemTable/XemTable';
import { MultiInvitePopup } from './MultiInvitePopup';
import { BatchPayoutPopup } from './BatchPayoutPopup';
import { XemSuccessDialog } from '_Components/Dialogs/XemSuccessDialog';
import { IProps } from './utils';
import { EligibleChannelStatus } from '_State/DistributionPartner/distributionPartner.enums';
import { XemTableHeader } from './XemTableHeader';
import { ManualPayoutConfirmationDialog } from './ManualPayoutConfirmationDIalog';
import { ISelectOption } from '_Components/PlxSelect/types';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { SubmitUrlForm } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerDashboard/SubmitUrlForm';

export const AdminSpectrumCampaignChannelsTable: FC<IProps> = ({
  id,
  isNewCampaign,
  onAnalyticsApproved,
  isRebrandlyUrl,
  maxSpend,
  boostSpend,
  campaignStatus,
  handleBoostConfirm,
  handleBoostCancel,
  handleMaxValConfirm,
  handleMaxValCancel,
  inputBoostFocused,
  setInputBoostFocused,
  inputMaxValFocused,
  setInputMaxValFocused,
  handleApproveAnalytics,
  handleManualPayAnalytics,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [reviewingAnalytics, setReviewingAnalytics] = useState<IAnalyticsDTO>();
  const [reviewingAnalyticsId, setReviewingAnalyticsId] = useState<string>();
  const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
  const [
    submitConfirmationDialogOpen,
    setSubmitConfirmationDialogOpen,
  ] = useState(false);
  const [notes, setNotes] = useState<string>('');
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [needsWorkDialogOpen, setNeedsWorkDialogOpen] = useState(false);
  const [batchPayoutConfirmation, setBatchPayoutConfirmation] = useState(false);
  const [domains, setDomains] = useState<ISelectOption[]>([
    { value: 'Please select domain...' },
  ]);
  const [selectedDomain, setSelectedDomain] = useState<string>('');
  const [channels, setChannels] = useState<
    IDistributionPartnerChannelSelectableRow[]
  >([]);
  const [channelsFiltered, setChannelsFiltered] = useState<
    IDistributionPartnerChannelSelectableRow[]
  >([]);
  const [invitedChannels, setInvitedChannels] = useState<
    IDistributionPartnerChannelSelectableRow[]
  >([]);
  const [rowSelected, setRowSelected] = useState<
    IDistributionPartnerChannelSelectableRow[]
  >([]);
  const [completedOpportunity, setCompletedOpportunity] = useState<
    IDistributionPartnerChannelSelectableRow[]
  >([]);
  const [totalPayout, setTotalPayout] = useState(0);
  const [
    reviewAnalyticsNotesSentDialogOpen,
    setReviewAnalyticsNotesSentDialogOpen,
  ] = useState(false);
  const [channelTypeList, setChannelTypeList] = useState<string[]>([]);
  const [topicsList, setTopicsList] = useState<string[]>([]);
  const [backgroundList, setBackgroundList] = useState<string[]>([]);
  const [designationsList, setDesignationsList] = useState<string[]>([]);
  const [age, setAge] = useState<string>();
  const [gender, setGender] = useState<string>();
  const [other, setOther] = useState<string>();
  const [followersAge, setFollowersAge] = useState<string>();
  const [primaryLocation, setPrimaryLocation] = useState<string>();
  const [secondaryLocation, setSecondaryLocation] = useState<string>();
  const [showRejectedValue, setShowRejectedValue] = useState(false);
  const [rebrandlyUrlEnabled, setRebrandlyUrlEnabled] = useState(
    isRebrandlyUrl
  );
  const [submitUrlDialogOpen, setSubmitUrlDialogOpen] = useState(false);
  const [submitUrlDialogData, setSubmitUrlDialogData] = useState<{
    url?: IUrlDTO;
  }>({ url: undefined });
  const [updatedAnalitics, setUpdatedAnalitics] = useState<
    IUpdateableAnalyticsDTO
  >();

  const [totals, setTotals] = useState({
    invitedTotalFollowers: 0,
    invitedTotalOffers: 0,
    invitingTotalFollowers: 0,
    invitingTotalOffers: 0,
    acceptedTotalFollowers: 0,
    acceptedTotalOffers: 0,
    acceptedTotalChannel: 0,
  });
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [manualPayoutId, setManualPayoutId] = useState('');

  useEffect(() => {
    const invitingTotalFollowers = rowSelected.reduce(
      (prev, current) => prev + +current.numberOfFollowers,
      0
    );
    const invitingTotalOffers = rowSelected.reduce(
      (prev, current) => prev + +current.offer,
      0
    );

    setTotals((prev) => ({
      ...prev,
      invitingTotalFollowers: invitingTotalFollowers,
      invitingTotalOffers: invitingTotalOffers,
    }));
  }, [rowSelected]);

  useEffect(() => {
    let invitedTotalFollowers = 0;
    let invitedTotalOffers = 0;
    let acceptedOfferTotalFollowers = 0;
    let acceptedOfferTotalOffer = 0;
    let acceptedOffer = 0;

    invitedChannels.map((c) => {
      invitedTotalFollowers += +c.numberOfFollowers;
      invitedTotalOffers += +c.offer;
      return c;
    });

    invitedChannels.map((c) => {
      if (
        c.status === EligibleChannelStatus[EligibleChannelStatus.ACTIVE] ||
        c.status ===
          EligibleChannelStatus[EligibleChannelStatus.ANALYTICS_NEEDED] ||
        c.status ===
          EligibleChannelStatus[EligibleChannelStatus.REVIEW_ANALYTICS] ||
        c.status === EligibleChannelStatus[EligibleChannelStatus.COMPLETED] ||
        c.status ===
          EligibleChannelStatus[
            EligibleChannelStatus.COMPLETED_MANUAL_PAYOUT
          ] ||
        c.status === EligibleChannelStatus[EligibleChannelStatus.NEEDS_WORK]
      ) {
        acceptedOfferTotalFollowers += +c.numberOfFollowers;
        acceptedOfferTotalOffer += +c.offer;
        acceptedOffer++;
      }
      return c;
    });

    setTotals((prev) => ({
      ...prev,
      invitedTotalFollowers: invitedTotalFollowers,
      invitedTotalOffers: invitedTotalOffers,
      acceptedTotalFollowers: acceptedOfferTotalFollowers,
      acceptedTotalOffers: acceptedOfferTotalOffer,
      acceptedTotalChannel: acceptedOffer,
    }));
  }, [invitedChannels]);

  const handleRowSelection = (e: any) => {
    setSelected(e.selectionModel);
  };

  useEffect(() => {
    const selectedObjects = channels.filter(
      (c) => c.status === 'ELIGIBLE' && selected.some((s) => Number(s) === c.id)
    );
    setRowSelected(selectedObjects);
  }, [selected, channels]);

  useEffect(() => {
    const invitedObjects = channels.filter(function ({ status }) {
      return (
        status === 'INVITATION_SENT' ||
        status === 'ACTIVE' ||
        status === 'ANALYTICS_NEEDED' ||
        status === 'REVIEW_ANALYTICS' ||
        status === 'COMPLETED' ||
        status === 'COMPLETED_MANUAL_PAYOUT' ||
        status === 'REJECTED' ||
        status === 'NEEDS_WORK'
      );
    });
    setInvitedChannels(invitedObjects);
  }, [channels]);

  const closeDialog = (): void => {
    setOpenInviteDialog(false);
    setNeedsWorkDialogOpen(false);
  };
  const openClosePayoutDialog = (): void =>
    setBatchPayoutConfirmation((pre) => !pre);

  const handleReview = (
    opportunityId?: string,
    dialogData?: IAnalyticsDTO
  ): void => {
    opportunityId && setReviewingAnalyticsId(opportunityId);
    setReviewingAnalytics(dialogData);
    setUpdatedAnalitics({
      reactions: dialogData?.reactions,
      shares: dialogData?.shares,
      comments: dialogData?.comments,
      impressions: dialogData?.impressions,
      videoSaves: dialogData?.videoSaves,
      numberOfFollowers: dialogData?.numberOfFollowers,
    });
    setReviewDialogOpen(true);
  };

  const handleUpdateAnalitics = (data: IUpdateableAnalyticsDTO) => {
    setUpdatedAnalitics((prevAnalytics) => ({
      ...prevAnalytics,
      ...data,
    }));
  };

  const handleApprove = (): void | Promise<any> => {
    setProcessing(true);
    reviewingAnalyticsId &&
      handleApproveAnalytics &&
      handleApproveAnalytics(reviewingAnalyticsId, updatedAnalitics).then(
        () => {
          setSubmitConfirmationDialogOpen(true);
          setProcessing(false);
        }
      );
  };

  const handleSubmitUrl = (url: IUrlDTO) => {
    setSubmitUrlDialogData({ url: url });
    setSubmitUrlDialogOpen(true);
  };

  const closeSubmitUrl = () => {
    setSubmitUrlDialogOpen(false);
  };

  const handleUrlSubmitted = () => {
    setSubmitUrlDialogOpen(false);
  };

  const handleChannelTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const filterValue = event.target.value as string[];
    setChannelTypeList(filterValue);
  };

  const handleTopicsChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const filterValue = event.target.value as string[];
    setTopicsList(filterValue);
  };

  const handleBackgroundChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const filterValue = event.target.value as string[];
    setBackgroundList(filterValue);
  };

  const handleDesignationsChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const filterValue = event.target.value as string[];
    setDesignationsList(filterValue);
  };

  const handleAgeChange = (value: string): void => {
    const val = value === 'placeholder' ? undefined : value;
    setAge(val);
  };
  const handleGenderChange = (value: string): void => {
    const val = value === 'placeholder' ? undefined : value;
    setGender(val);
  };
  const handleOtherChange = (value: string): void => {
    const val = value === 'placeholder' ? undefined : value;
    setOther(val);
  };
  const handleFollowersAgeChange = (value: string): void => {
    const val = value === 'placeholder' ? undefined : value;
    setFollowersAge(val);
  };
  const handlePrimaryLocation = (value: string): void => {
    const val = value === 'placeholder' ? undefined : value;
    setPrimaryLocation(val);
  };
  const handleSecondaryLocation = (value: string): void => {
    const val = value === 'placeholder' ? undefined : value;
    setSecondaryLocation(val);
  };

  useEffect(() => {
    if (
      age ||
      gender ||
      other ||
      followersAge ||
      primaryLocation ||
      secondaryLocation ||
      channelTypeList.length ||
      topicsList.length ||
      backgroundList.length ||
      designationsList.length
    ) {
      const combinedFilter = channels.filter((c) => {
        const ageCondition = !age || c.age === age;
        const genderCondition = !gender || c.gender === gender;
        const otherCondition = !other || c.other === other;
        const followersAgeCondition =
          !followersAge || c.followersPrimaryAge === followersAge;
        const primaryLocationCondition =
          !primaryLocation || c.followersPrimaryLocation === primaryLocation;
        const secondaryLocationCondition =
          !secondaryLocation ||
          c.followersSecondaryLocation === secondaryLocation;

        const channelTypeCondition =
          !channelTypeList.length ||
          channelTypeList.some((s) => s === c.channelType);

        const topicsCondition =
          !topicsList.length ||
          topicsList.some((topic) => c.topics?.includes(topic));

        const backgroundCondition =
          !backgroundList.length ||
          backgroundList.some((bg) => c.background?.includes(bg));

        const designationCondition =
          !designationsList.length ||
          designationsList.some((des) => c.designations?.includes(des));

        return (
          ageCondition &&
          genderCondition &&
          otherCondition &&
          followersAgeCondition &&
          primaryLocationCondition &&
          secondaryLocationCondition &&
          channelTypeCondition &&
          topicsCondition &&
          backgroundCondition &&
          designationCondition
        );
      });

      setChannelsFiltered(combinedFilter);
    } else {
      setChannelsFiltered(channels);
    }
  }, [
    age,
    gender,
    other,
    followersAge,
    primaryLocation,
    secondaryLocation,
    topicsList,
    backgroundList,
    designationsList,
    channelTypeList,
    channels,
  ]);

  const handleManualPay = (payoutId?: string) =>
    payoutId && setManualPayoutId(payoutId);

  const confirmManualPay = () =>
    !!manualPayoutId &&
    handleManualPayAnalytics &&
    handleManualPayAnalytics(manualPayoutId).then(() => {
      setManualPayoutId('');
      fetchChannels().then(() => setLoading(false));
    });

  const handleMultipleInvite = (): void => {
    setOpenInviteDialog(true);
  };

  const handleCancel = (channelId?: string) =>
    channelId &&
    cancelChannels(channelId, id).then(() => {
      fetchChannels().then(() => setLoading(false));
    });

  const handleConfirmInviteMultiple = (): void => {
    const selectedId: string[] = [];
    rowSelected.map((r) => selectedId.push(r.id.toString()));
    setInviteLoading(true);
    selectedId.length &&
      inviteChannels(selectedId, id, selectedDomain).then(() => {
        fetchChannels().then(() => {
          setInviteLoading(false);
          setSelected([]);
          setOpenInviteDialog(false);
        });
      });
  };

  useEffect(() => {
    fetchChannels().then();
    // eslint-disable-next-line
  }, []);

  const handleConfirmationClose = () => {
    setSubmitConfirmationDialogOpen(false);
    setReviewDialogOpen(false);
    fetchChannels().then(() => {
      onAnalyticsApproved();
      setLoading(false);
    });
  };

  const handleNeedsWorkForm = () => {
    setProcessing(true);
    declineStatistics(reviewingAnalyticsId, notes).then(() => {
      setProcessing(false);
      setReviewAnalyticsNotesSentDialogOpen(true);
    });
  };

  const handleReviewAnalyticsNotesSentClose = () => {
    setReviewAnalyticsNotesSentDialogOpen(false);
    setNeedsWorkDialogOpen(false);
    setReviewDialogOpen(false);
    fetchChannels().then(() => setLoading(false));
  };

  const openNeedsWorkDialog = () => {
    setNeedsWorkDialogOpen(true);
  };

  const closeReviewDialog = () => {
    setReviewDialogOpen(false);
  };

  useEffect(() => {
    if (campaignStatus === 'COMPLETED') {
      setCompletedOpportunity(
        channels.filter(
          (r: IDistributionPartnerChannelSelectableRow) =>
            r.offer > 0 &&
            (r.status === 'COMPLETED' ||
              r.status === 'COMPLETED_MANUAL_PAYOUT') &&
            r.defaultWalletEmail &&
            !r.transactionSuccess &&
            !r.transactionStatus
        )
      );
    }
  }, [campaignStatus, channels]);

  const fetchDomains = () =>
    getDomains().then((res) => {
      setDomains((prev) =>
        prev.concat(
          res.map((el: any) => {
            return { value: el.fullName };
          })
        )
      );
    });

  useEffect(() => {
    if (isNewCampaign) {
      fetchDomains();
    }
  }, [isNewCampaign]);

  useEffect(() => {
    let isMounted = true;
    const sum = completedOpportunity.reduce(function (prev, current) {
      return prev + +current.offer;
    }, 0);
    if (isMounted) {
      setTotalPayout(sum);
    }
    return () => {
      isMounted = false;
    };
  }, [completedOpportunity]);

  // const search = (rows: any) => {
  //   return rows.filter(
  //     (row: any) =>
  //       row.channelName.toLowerCase().indexOf(searchWord) > -1 ||
  //       row.channelType.toLowerCase().indexOf(searchWord) > -1 ||
  //       row.fullName.toLowerCase().indexOf(searchWord) > -1 ||
  //       row.status.toLowerCase().indexOf(searchWord) > -1 ||
  //       row.transactionStatus?.toLowerCase().indexOf(searchWord) > -1
  //   );
  // };
  const handleCloseManualPayoutId = (): void => setManualPayoutId('');
  const handleShowRejected = (): void => setShowRejectedValue((pre) => !pre);
  const handleRebrandlyUrl = (): void => {
    setRebrandlyUrlEnabled((prev) => {
      const newRebrandlyUrlEnabled = !prev;
      if (isNewCampaign) {
        toggleRebrandlyUrl(newRebrandlyUrlEnabled, id);
      }
      return newRebrandlyUrlEnabled;
    });
  };

  const handleSelectDomain = (value: string): void => {
    setSelectedDomain(value.toString());
  };

  const rejectedChannel = (
    data: IDistributionPartnerChannelSelectableRow[]
  ): IDistributionPartnerChannelSelectableRow[] => {
    if (!showRejectedValue) {
      return data.filter(
        (c: IDistributionPartnerChannelSelectableRow) => c.status !== 'REJECTED'
      );
    } else {
      return data;
    }
  };

  const fetchChannels = () =>
    getEligibleChannels(id).then((res) => {
      const mappedChannels = res.map((channel: any) => ({
        ...channel,
        id: channel.id ? channel.id : channel.channelId,
      }));
      if (campaignStatus === 'NEW' || campaignStatus === 'DRAFT') {
        setChannels(mappedChannels);
      } else {
        setChannels(
          mappedChannels.filter(
            (d: IDistributionPartnerChannelSelectableRow) =>
              d.status !== 'ELIGIBLE'
          )
        );
      }
      setLoading(false);
      return res;
    });

  const handleInvite = (channelId?: string, selectedDomain?: string) => {
    return (
      channelId &&
      inviteChannels([channelId], id, selectedDomain).then(() => {
        fetchChannels().then(() => setLoading(false));
      })
    );
  };

  const header = xemCreateSpectrumChannelsTableConfig(
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    (id) => handleInvite(id, selectedDomain),
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleReview,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleManualPay,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleSubmitUrl,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleCancel,
    campaignStatus,
    maxSpend
  );

  return (
    <Body>
      <XemTableHeader
        channelTypeList={channelTypeList}
        topicsList={topicsList}
        backgroundList={backgroundList}
        designationsList={designationsList}
        domains={domains}
        onDomainChange={handleSelectDomain}
        onChannelTypeChange={handleChannelTypeChange}
        onTopicsChange={handleTopicsChange}
        onBackgroundChange={handleBackgroundChange}
        onDesignationsChange={handleDesignationsChange}
        onAgeChange={handleAgeChange}
        onGenderChange={handleGenderChange}
        onOtherChange={handleOtherChange}
        onFollowersAgeChange={handleFollowersAgeChange}
        onPrimaryLocationChange={handlePrimaryLocation}
        onSecondaryLocationChange={handleSecondaryLocation}
        data={rejectedChannel(channelsFiltered)}
        totals={totals}
        campaignStatus={campaignStatus}
        completedOpportunity={completedOpportunity}
        invitedChannels={invitedChannels}
        isNewCampaign={isNewCampaign}
        totalPayout={totalPayout}
        openClosePayoutDialog={openClosePayoutDialog}
        rowSelected={rowSelected}
        handleMultipleInvite={handleMultipleInvite}
        handleShowRejected={handleShowRejected}
        showRejectedValue={showRejectedValue}
        handleRebrandlyUrl={handleRebrandlyUrl}
        rebrandlyUrlEnabled={rebrandlyUrlEnabled}
        maxSpend={maxSpend}
        id={id}
        isCSVButton={true}
        handleBoostConfirm={handleBoostConfirm}
        handleBoostCancel={handleBoostCancel}
        handleMaxValConfirm={handleMaxValConfirm}
        handleMaxValCancel={handleMaxValCancel}
        focusedBoost={inputBoostFocused}
        setFocusedBoost={setInputBoostFocused}
        boostSpend={boostSpend}
        focusedMaxVal={inputMaxValFocused}
        setFocusedMaxVal={setInputMaxValFocused}
        maxSpendValue={maxSpend}
      />
      <XemTable
        header={header}
        data={rejectedChannel(channelsFiltered)}
        pageSize={20}
        checkbox={true}
        handleRowSelection={handleRowSelection}
        loading={loading}
      />
      <PlxDialog
        title={t('channels.campaignDetails.updateUrl')}
        open={submitUrlDialogOpen}
        onClose={closeSubmitUrl}
      >
        <SubmitUrlForm
          onBack={closeSubmitUrl}
          updateUrl
          onUrlSubmitted={handleUrlSubmitted}
          submitUrlDialogData={submitUrlDialogData}
        />
      </PlxDialog>
      <StyledDialog
        extraOptionLabel={t('channels.reviewAnalytics.needsWork')}
        onExtraOption={openNeedsWorkDialog}
        onClose={closeReviewDialog}
        saveButtonLabel={t('channels.reviewAnalytics.approve')}
        onSave={handleApprove}
        open={reviewDialogOpen}
        onSaveDisabled={processing}
        title={`${t(
          'channels.reviewAnalytics.reviewTitle'
        )}  #${reviewingAnalyticsId}`}
      >
        <ReviewAnalyticsDialog
          handleUpdate={handleUpdateAnalitics}
          analytics={reviewingAnalytics}
        />
      </StyledDialog>
      <Dialog
        onClose={handleConfirmationClose}
        open={submitConfirmationDialogOpen}
      >
        <Wrapper>
          <PlxPanel
            enableActions={false}
            content={
              <Content>
                <ConfirmationIcon />
                <Label>{t('channels.reviewAnalyticsConfirmation.label')}</Label>
                <Description>
                  {t('channels.reviewAnalyticsConfirmation.description')}
                </Description>
                <PlxButton
                  onClick={handleConfirmationClose}
                  label={t('channels.reviewAnalyticsConfirmation.close')}
                />
              </Content>
            }
          />
        </Wrapper>
      </Dialog>
      <StyledDialog
        closeButton
        closeButtonLabel={t('channels.reviewAnalytics.close')}
        onClose={closeDialog}
        saveButtonLabel={t('channels.reviewAnalytics.submit')}
        onSave={handleNeedsWorkForm}
        onSaveDisabled={processing}
        isDisabled={notes.length < 1}
        open={needsWorkDialogOpen}
        title={`${t(
          'channels.reviewAnalytics.reviewTitle'
        )}  #${reviewingAnalyticsId}`}
      >
        <InputBody>
          <InputSection
            required
            multiline
            fieldName={'notes'}
            title={t('channels.reviewAnalytics.notesTitle')}
            rows={8}
            placeholder={t('channels.reviewAnalytics.notesPlaceholder')}
            handleChange={(event) => {
              if (typeof event !== 'string') {
                setNotes(event.target.value);
              }
            }}
            value={notes}
          />
        </InputBody>
      </StyledDialog>
      <Dialog
        onClose={handleReviewAnalyticsNotesSentClose}
        open={reviewAnalyticsNotesSentDialogOpen}
      >
        <Wrapper>
          <PlxPanel
            enableActions={false}
            content={
              <Content>
                <Label>{t('channels.reviewAnalyticsNotesSent.label')}</Label>
                <PlxButton
                  onClick={handleReviewAnalyticsNotesSentClose}
                  label={t('channels.reviewAnalyticsConfirmation.close')}
                />
              </Content>
            }
          />
        </Wrapper>
      </Dialog>
      <Dialog onClose={openClosePayoutDialog} open={batchPayoutConfirmation}>
        <BatchPayoutPopup
          completedOpportunity={completedOpportunity}
          openClosePayoutDialog={openClosePayoutDialog}
          id={id}
          fetchChannels={fetchChannels}
          setSuccessDialogOpen={(v: boolean) => setSuccessDialogOpen(v)}
        />
      </Dialog>
      <Dialog onClose={closeDialog} open={openInviteDialog}>
        <MultiInvitePopup
          inviteLoading={inviteLoading}
          rowSelected={rowSelected}
          closeDialog={closeDialog}
          handleConfirmInviteMultiple={handleConfirmInviteMultiple}
          invitedChannels={invitedChannels}
          totals={totals}
        />
      </Dialog>
      <XemSuccessDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
      />
      <Dialog
        open={!!manualPayoutId}
        onClose={handleCloseManualPayoutId}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ManualPayoutConfirmationDialog
          handleCloseManualPayoutId={handleCloseManualPayoutId}
          confirmManualPay={confirmManualPay}
        />
      </Dialog>
    </Body>
  );
};
