import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IDistributionPartnerAddChannelForm,
  IDistributionPartnerChannelDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import {
  getChannels,
  getUserChannels,
  updatePreferences,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import { PlxLoader } from '_Components/PlxLoader';
import { Header, Subtitle } from '../../utils';
import {
  AddChannelFormWrapper,
  AgeWrapper,
  BottomWrapper,
  LocationAgeWrapper,
  LocationWrapper,
} from '_Routes/Home/_Components/Sections/styles';
import { FooterButtons } from '_Components/FooterButtons/FooterButtons';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import { InputSectionDropDown } from '_Routes/Home/_Components/Sections/NewChannelSection/InputSectionDropDown';
import { MultiSection } from '_Routes/Home/_Components/Sections/NewChannelSection/MultiSection';
import { PlxSection } from '_Routes/Home/_Components/Sections/NewChannelSection/PlxSection';
import { TopicsSection } from '_Routes/Home/_Components/Sections/NewChannelSection/TopicsSection';
import {
  countries,
  ages,
  finances,
  stocks,
  investing,
  themes,
  designations,
  background,
  gender,
  other,
} from '_Routes/Home/_Components/Sections/NewChannelSection/options';
import { useFormik } from 'formik';
import { xemCreateSpectrumChannelsTableConfig } from './utils';
import { XemTable } from './XemTable';
import { orderBy } from 'lodash';

const INITIAL_VALUES: IDistributionPartnerAddChannelForm = {
  followersPrimaryLocation: '',
  followersSecondaryLocation: '',
  followersPrimaryAge: '',
  designations: [],
  background: [],
  gender: '',
  age: '',
  other: '',
  topics: [],
};

const REQUIRED_FIELDS = [
  'followersPrimaryLocation',
  'followersSecondaryLocation',
  'followersPrimaryAge',
  'designations',
  'background',
  'gender',
  'age',
  'other',
  'topics',
];

const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

interface IProps {
  apiError?: string;
  id?: string;
  performSubmit?: boolean;
  // channels: IDistributionPartnerChannelDTO[];
}

export const DistributionPartnerUpdatePreferences: FC<IProps> = ({
  id,
  performSubmit,
  // channels,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDistributionPartnerChannelDTO>();
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedError, setSelectedError] = useState<boolean>(false);
  const [channels, setChannels] = useState<IDistributionPartnerChannelDTO[]>(
    []
  );

  const fetchChannels = () => {
    setLoading(true);
    return getUserChannels().then((v) => {
      const orderChannels = orderBy(v, 'id', 'asc');
      const filteredChannels = orderChannels
        .filter((v) => v.status === 'CONNECTED' || v.status === 'ACTIVE')
        .filter((channel) => {
          return (channel.topics ?? []).length === 0;
        });
      if (filteredChannels.length > 0) {
        setChannels(filteredChannels);
      } else {
        setChannels(orderChannels);
      }
      return orderChannels;
    });
  };

  useEffect(() => {
    fetchChannels().then(() => setLoading(false));
  }, []);

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  const handleUpdatePreferences = (
    form: IDistributionPartnerAddChannelForm,
    listOfIds: string[]
  ) => {
    const updatePromises = listOfIds.map((channel) => {
      return updatePreferences(form, channel);
    });
    Promise.all(updatePromises)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
  };

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        handleUpdatePreferences(form, selected);
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      if (selected.length < 1) {
        setSelectedError(true);
      } else {
        setSelectedError(false);
      }
      if (
        form.followersPrimaryLocation &&
        form.followersPrimaryLocation.length < 1
      ) {
        errors.followersPrimaryLocation = 'This field is required';
      }
      if (
        form.followersSecondaryLocation &&
        form.followersSecondaryLocation.length < 1
      ) {
        errors.followersSecondaryLocation = 'This field is required';
      }
      if (form.followersPrimaryAge && form.followersPrimaryAge.length < 1) {
        errors.followersPrimaryAge = 'This field is required';
      }
      if (form.topics && form.topics.length < 1) {
        errors.topics = 'This field is required';
      }
      if (form.designations && form.designations.length < 1) {
        errors.designations = 'This field is required';
      }
      if (form.background && form.background.length < 1) {
        errors.background = 'Background field is required';
      }
      if (form.gender && form.gender.length < 1) {
        errors.gender = 'Gender field is required';
      }
      if (form.age && form.age.length < 1) {
        errors.age = 'Age field is required';
      }
      if (form.other && form.other.length < 1) {
        errors.other = 'Other field is required';
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const handleNextStep = () => {
    formik.handleSubmit();
  };

  const fillForm = (data?: IDistributionPartnerChannelDTO) => {
    formik.setValues({
      followersPrimaryLocation: data?.followersPrimaryLocation,
      followersSecondaryLocation: data?.followersSecondaryLocation,
      followersPrimaryAge: data?.followersPrimaryAge,
      designations: data?.designations,
      background: data?.background,
      gender: data?.gender,
      age: data?.age,
      other: data?.other,
      topics: data?.topics,
    });
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getChannels({ channelId: id }).then(
        ({ content }) => {
          setData(content[0]);
          fillForm(content[0]);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (performSubmit !== undefined) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [performSubmit]);

  const handleRowSelection = (e: any) => {
    setSelected(e.selectionModel);
  };

  const header = xemCreateSpectrumChannelsTableConfig();

  return (
    <>
      {loading ? (
        <PlxLoader />
      ) : (
        <div>
          <Header>
            {t(
              `dashboard.updatePreferences.${
                channels.length > 1 ? 'updateTitleM' : 'updateTitle'
              }`
            )}{' '}
            ({channels.length})
          </Header>
          <Subtitle>
            {t(
              `dashboard.updatePreferences.${
                channels.length > 1 ? 'selectChannels' : 'selectChannel'
              }`
            )}
            <span style={{ color: '#D10000' }}>*</span>
          </Subtitle>
          <XemTable
            header={header}
            data={channels}
            checkbox={true}
            handleRowSelection={handleRowSelection}
            loading={loading}
          />
          {selectedError && (
            <div style={{ marginBottom: '2rem' }}>
              <FieldErrorMessage
                message="Please select a channel"
                testId="followers-primary-location-error"
              />
            </div>
          )}
          <AddChannelFormWrapper>
            <LocationAgeWrapper>
              <LocationWrapper>
                <InputSectionDropDown
                  fieldName={'followersPrimaryLocation'}
                  required
                  title={t('influencer.addChannel.primaryLocation')}
                  placeholder={t('influencer.addChannel.country')}
                  options={countries}
                  setValue={formik.setFieldValue}
                  value={data?.followersPrimaryLocation}
                />
                {formik.errors.followersPrimaryLocation && (
                  <FieldErrorMessage
                    message={formik.errors.followersPrimaryLocation}
                    testId="followers-primary-location-error"
                  />
                )}
                <InputSectionDropDown
                  fieldName={'followersSecondaryLocation'}
                  required
                  title={t('influencer.addChannel.secondaryLocation')}
                  placeholder={t('influencer.addChannel.country')}
                  options={countries}
                  setValue={formik.setFieldValue}
                  value={data?.followersSecondaryLocation}
                />
                {formik.errors.followersSecondaryLocation && (
                  <FieldErrorMessage
                    message={formik.errors.followersSecondaryLocation}
                    testId="followers-secondary-location-error"
                  />
                )}
              </LocationWrapper>
              <AgeWrapper>
                <PlxSection
                  fieldName={'followersPrimaryAge'}
                  required
                  initialValue={data?.followersPrimaryAge}
                  options={ages}
                  title={t('influencer.addChannel.followersAge')}
                  setFieldValue={formik.setFieldValue}
                />
                {formik.errors.followersPrimaryAge && (
                  <FieldErrorMessage
                    message={formik.errors.followersPrimaryAge}
                    testId="followers-primary-age-error"
                  />
                )}
              </AgeWrapper>
            </LocationAgeWrapper>

            <TopicsSection
              fieldName="topics"
              setFieldValue={formik.setFieldValue}
              initialValue={data?.topics}
              finances={finances}
              stocks={stocks}
              investing={investing}
              themes={themes}
              required
              description={t('influencer.addChannel.description')}
              title={t('influencer.addChannel.topics')}
            />
            {formik.errors.topics && (
              <FieldErrorMessage
                message={formik.errors.topics}
                testId="topics-error"
              />
            )}
            <MultiSection
              fieldName={'designations'}
              required
              options={designations}
              initialValue={data?.designations}
              description={t('influencer.addChannel.description')}
              title={t('influencer.addChannel.designations')}
              setFieldValue={formik.setFieldValue}
            />
            {formik.errors.designations && (
              <FieldErrorMessage
                message={formik.errors.designations}
                testId="designations-error"
              />
            )}
            <BottomWrapper>
              <MultiSection
                fieldName={'background'}
                initialValue={data?.background}
                required
                options={background}
                title={t('influencer.addChannel.background')}
                setFieldValue={formik.setFieldValue}
              />
              <PlxSection
                fieldName={'gender'}
                initialValue={data?.gender}
                required
                options={gender}
                title={t('influencer.addChannel.gender')}
                setFieldValue={formik.setFieldValue}
              />
              <PlxSection
                fieldName={'age'}
                initialValue={data?.age}
                required
                options={ages}
                title={t('influencer.addChannel.age')}
                setFieldValue={formik.setFieldValue}
              />
              <PlxSection
                fieldName={'other'}
                initialValue={data?.other}
                required
                options={other}
                title={t('influencer.addChannel.other')}
                setFieldValue={formik.setFieldValue}
              />
            </BottomWrapper>
            {formik.errors.background ? (
              <FieldErrorMessage
                message={formik.errors.background}
                testId="background-error"
              />
            ) : formik.errors.gender ? (
              <FieldErrorMessage
                message={formik.errors.gender}
                testId="gender-error"
              />
            ) : formik.errors.age ? (
              <FieldErrorMessage
                message={formik.errors.age}
                testId="age-error"
              />
            ) : (
              <FieldErrorMessage
                message={formik.errors.other}
                testId="other-error"
              />
            )}
            <FooterButtons
              onSubmit
              onNextStep={handleNextStep}
              testId="submit-button"
            />
          </AddChannelFormWrapper>
        </div>
      )}
    </>
  );
};
