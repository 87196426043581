import styled from '@emotion/styled';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Trans } from 'react-i18next';
import * as Yup from 'yup';
import DoneIcon from '@material-ui/icons/Done';
import {
  getContractUUID,
  postSignerEvents,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import ky from 'ky';
import moment from 'moment';
import { Dialog } from '@material-ui/core';
import { AllPages } from './AllPages';

const BodyWrap = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: scroll;
  cursor: pointer;
`;
const Body = styled.div`
  max-width: 980px;
  width: 100%;
  margin: 2rem auto;
  padding: 2rem 1rem;
  background-color: #fff;
`;
const DialogWrap = styled.div`
  text-align: center;
  line-height: 2rem;
  svg.MuiSvgIcon-root {
    width: 2rem;
    height: 2rem;
  }
  a {
    text-decoration: none;
    color: #000;
  }
`;
const Title = styled.div`
  font-size: 2rem;
  margin: 4rem;
  line-height: 30px;
  text-decoration: none;
`;
const PlxButtonXWrap = styled.div`
  margin: 2rem;
  display: flex;
  justify-content: center;
`;
const initialValues = {
  signerIp: '',
  contractViewed: '',
  contractSigned: '',
  date: '',
  postalZip: '',
  email: '',
  name: '',
  country: '',
  provinceState: '',
  city: '',
  streetAddress: '',
  company: '',
  pdfLink: '',
  signUrl: '',
  popupOpen: false,
  acceptDialogOpen: false,
  identifier: '',
};

const validationSchema = Yup.object({
  company: Yup.string()
    .max(100, 'Company/Full-name name too long!')
    .required('Company/Full-name Required'),
  streetAddress: Yup.string()
    .max(225, 'Street address too long!')
    .required('Street Address Required'),
  city: Yup.string().max(50, 'City too long!').required('City Required'),
  provinceState: Yup.string()
    .max(50, 'Province/State too long!')
    .required('Province/State Required'),
  postalZip: Yup.string()
    .max(20, 'Postal/Zipcode too long!')
    .required('Postal/Zipcode Required'),
  country: Yup.string()
    .max(50, 'Country too long!')
    .required('Country Required'),
  name: Yup.string()
    .max(50, 'Attention: Name too long!')
    .required('Attention: Name Required'),
  email: Yup.string().email('Invalid email address').required('Email Required'),
  signUrl: Yup.string().required('Signature Required'),
});

interface IProps {
  isAdmin?: boolean;
  contractUrl?: string;
}

export const XemFinfluencerContract: FC<IProps> = ({
  isAdmin,
  contractUrl,
}) => {
  const [url, setUrl] = useState<string | ArrayBuffer | null>();
  const [popupSuccess, setPopupSuccess] = useState<boolean>(false);
  const calledOnce = React.useRef(false);
  const calledOnce2 = React.useRef(false);

  const formik = useFormik({
    initialValues,
    onSubmit: (form: any) => {
      formik.setFieldValue('acceptDialogOpen', true);
    },
    validationSchema,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });
  const newForm = {
    signerIp: formik.values.signerIp,
    contractViewed: formik.values.contractViewed,
    contractSigned: moment().format('DD-MM-YYYYTHH:mm:ss'),
    date: formik.values.date,
    postalZip: formik.values.postalZip,
    email: formik.values.email,
    name: formik.values.name,
    country: formik.values.country,
    provinceState: formik.values.provinceState,
    city: formik.values.city,
    streetAddress: formik.values.streetAddress,
    company: formik.values.company,
    signUrl: formik.values.signUrl,
    pdfLink: url,
  };
  useEffect(() => {
    if (isAdmin) return;

    if (calledOnce.current) {
      return;
    }

    ky('https://api.ipify.org?format=jsonp?callback=?')
      .then((res) => res.text())
      .then((res) => {
        formik.setFieldValue('signerIp', res);
        getContractUUID({ ip: res }).then(({ identifier }) => {
          formik.setFieldValue('identifier', identifier);
        });
      })
      .catch(() =>
        getContractUUID({ ip: formik.values.signerIp }).then((res) =>
          formik.setFieldValue('identifier', res.identifier)
        )
      );

    formik.setFieldValue(
      'contractViewed',
      moment().format('DD-MM-YYYYTHH:mm:ss')
    );
    formik.setFieldValue('date', moment().format('DD-MM-YYYYTHH:mm:ss'));
    calledOnce.current = true;
  }, [formik, isAdmin]);

  useEffect(() => {
    if (isAdmin) return;

    if (calledOnce2.current) {
      return;
    }
    if (url) {
      postSignerEvents(newForm)
        .then(() => {
          setPopupSuccess(true);
          calledOnce2.current = true;
        })
        .catch(() => {
          alert('Something went wrong. please try again. ');
          window.location.reload();
        });
    }
  }, [newForm, url, isAdmin]);
  return (
    <BodyWrap>
      <Body>
        <AllPages formik={formik} pdf={isAdmin} />
        <div
          style={{
            margin: '50px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <a
            href={
              isAdmin && contractUrl
                ? contractUrl
                : 'https://storage.googleapis.com/xemoto-prod/contracts/Influencer%20Marketing%20Agreement%20BPAI.pdf'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <GetAppIcon
              style={{
                width: '35px',
                height: 'auto',
                border: '1px solid #A9B3BB',
                padding: '.5rem',
                borderRadius: '3px',
              }}
            />
          </a>
          {!isAdmin && (
            <PlxButtonX onClick={formik.handleSubmit} testId="accept-button">
              <Trans i18nKey="payment.finfluencerEngagementLetter.accept" />
            </PlxButtonX>
          )}
        </div>

        {formik.values.acceptDialogOpen && !isAdmin && (
          <Dialog
            onClose={() => formik.setFieldValue('acceptDialogOpen', false)}
            open={true}
          >
            <DialogWrap>
              <PDFDownloadLink
                document={<AllPages formik={formik} pdf />}
                fileName="influencerContract.pdf"
              >
                {({ blob, loading }) => {
                  if (!url && blob) {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob as Blob);
                    reader.onloadend = () => {
                      const base64data = reader.result;
                      setUrl(
                        (base64data as string).replace(
                          'data:application/pdf;base64,',
                          ''
                        )
                      );
                    };
                  }
                  return (
                    <>
                      {popupSuccess && url && (
                        <>
                          <Title>
                            <DoneIcon
                              style={{
                                width: '75px',
                                height: '75px',
                                color: 'green',
                              }}
                            />
                          </Title>
                          <Title>
                            <Trans i18nKey="payment.finfluencerEngagementLetter.signSuccessMessage" />
                          </Title>
                          <GetAppIcon
                            style={{
                              width: '35px',
                              height: 'auto',
                              border: '1px solid #A9B3BB',
                              padding: '.5rem',
                              borderRadius: '3px',
                              marginBottom: 12,
                            }}
                          />
                        </>
                      )}
                    </>
                  );
                }}
              </PDFDownloadLink>
              {popupSuccess ? (
                <PlxButtonXWrap>
                  <PlxButtonX
                    onClick={() => window.location.reload()}
                    testId="close-button"
                  >
                    <Trans i18nKey="payment.finfluencerEngagementLetter.close" />
                  </PlxButtonX>
                </PlxButtonXWrap>
              ) : (
                <Title>
                  <Trans i18nKey="payment.finfluencerEngagementLetter.processing" />
                </Title>
              )}
            </DialogWrap>
          </Dialog>
        )}
      </Body>
    </BodyWrap>
  );
};
