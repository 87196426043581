import styled from '@emotion/styled';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import { FormSection } from '_Components/FormSection/FormSection';
import { PlxDatePicker } from '_Components/PlxDatePicker/PlxDatePicker';
import { InputSectionX } from '_Routes/Home/_Components/Sections/InputSectionX';
import { IOrderDTO, IOrderEditForm } from '_State/User/user.types';
import { ButtonsWrapper } from '../../../Spectrum/AdminSpectrumCampaign/Styles';

const Body = styled.div`
  width: 100%;
  padding: 2rem 4rem 4rem 4rem;
  background: #ffffff;
  border: 1px solid #d5dde3;
  border-radius: 5px;
  margin: 3.5rem;
  min-height: 80%;
  height: fit-content;
`;

const INITIAL_VALUES: IOrderEditForm = {
  remainingCampaigns: undefined,
  expiryDate: undefined,
};

let REQUIRED_FIELD: string[] = ['remainingCampaigns'];

interface IProps {
  userId?: string;
  agencyClientId?: string;
  data?: IOrderDTO;
  onBack: () => void;
  handleEditOrder: (form: IOrderEditForm, id: number) => Promise<any>;
}

export const EditOrder: FC<IProps> = ({
  data,
  onBack,
  handleEditOrder,
  userId,
  agencyClientId,
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>();
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState<
    Date | string | undefined | null
  >(data?.expiryDate);
  const { t } = useTranslation();

  useEffect(() => {
    if (data?.status === 'COMPLETED') {
      REQUIRED_FIELD.length = 0;
    } else {
      REQUIRED_FIELD.length === 0 && REQUIRED_FIELD.push('remainingCampaigns');
    }
  }, [data]);

  const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELD);
  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELD,
    t('errors.reqField')
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        setIsProcessing(true);
        data?.id &&
          handleEditOrder(form, data?.id).then(
            () => {
              setIsProcessing(false);
              onBack();
            },
            (error) => {
              error.response
                .json()
                .then((error: any) => setApiErrorMessage(error.message));
            }
          );
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      if (data?.status !== 'COMPLETED') {
        if (
          form.remainingCampaigns === undefined ||
          form.remainingCampaigns === 0
        ) {
          errors.remainingCampaigns = 'Required';
        }
      }
      if (form.invoiceId === undefined || form.invoiceId === '') {
        errors.invoiceId = 'Required';
      }
      if (
        data?.status !== 'COMPLETED' &&
        form.remainingCampaigns &&
        form.remainingCampaigns < 1
      ) {
        errors.remainingCampaigns = 'Value must be > 0';
      }
      if (form.remainingCampaigns && form.remainingCampaigns > 2147483647) {
        errors.remainingCampaigns = 'Value is too big';
      }
      if (!form.expiryDate) {
        errors.expiryDate = 'Required';
      } else if (new Date(form.expiryDate) < new Date()) {
        errors.expiryDate = 'Expiry date cannot be in the past';
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const fillForm = (
    id: string | undefined,
    agencyClientId: string | undefined,
    data: IOrderDTO | undefined
  ) => {
    if (agencyClientId) {
      formik.setValues({
        agencyClientId: agencyClientId,
        userId: id,
        remainingCampaigns: data?.remainingCampaigns,
        expiryDate: data?.expiryDate,
        invoiceId: data?.invoiceId,
      });
    } else {
      formik.setValues({
        remainingCampaigns: data?.remainingCampaigns,
        expiryDate: data?.expiryDate,
        invoiceId: data?.invoiceId,
      });
    }
  };

  useEffect(() => {
    fillForm(userId, agencyClientId, data);
    // eslint-disable-next-line
  }, [userId, agencyClientId, data]);

  const handlePostedDateChange = (date: Date | null) => {
    const datePosted = moment(date).format('YYYY-MM-DD[T]HH:MM:ss');
    formik.setFieldValue('expiryDate', datePosted);
    setSelectedDate(date);
  };

  const handleBack = () => {
    onBack();
  };

  return (
    <Body>
      <InputSectionX
        required
        typeNumber
        title={t('admin.users.orders.remaining')}
        fieldName={'remainingCampaigns'}
        handleChange={formik.handleChange}
        value={data?.remainingCampaigns}
        disabled={data?.status === 'COMPLETED'}
      />
      <FieldErrorMessage message={formik.errors.remainingCampaigns} />
      <div>
        <FormSection title={t('admin.users.orders.expire')} required>
          <PlxDatePicker
            disableToolbar
            inputVariant="outlined"
            variant="inline"
            format="MM/dd/yyyy"
            disabled={data?.status === 'COMPLETED'}
            margin="normal"
            placeholder={t(
              'opportunities.submitAnalytics.datePostedPlaceholder'
            )}
            value={selectedDate}
            fullwidth={true}
            onChange={handlePostedDateChange}
            minDate={new Date()}
          />
        </FormSection>
        <FieldErrorMessage message={formik.errors.expiryDate} />
      </div>
      <InputSectionX
        required
        value={data?.invoiceId}
        title={t('admin.users.orders.invoiceId')}
        fieldName={'invoiceId'}
        handleChange={formik.handleChange}
      />
      <FieldErrorMessage message={formik.errors.invoiceId} />
      <FieldErrorMessage message={apiErrorMessage} />
      <ButtonsWrapper>
        <PlxButton
          label={t('admin.users.orders.save')}
          onClick={formik.handleSubmit}
          isDisabled={isProcessing}
          isProcessing={isProcessing}
          testId="submit-button"
        />
        <PlxButton
          label={t('admin.users.orders.close')}
          onClick={handleBack}
          variant={ButtonVariant.Outlined}
          testId="back-button"
        />
      </ButtonsWrapper>
    </Body>
  );
};
