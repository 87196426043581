import React, { FC } from 'react';
import styled from '@emotion/styled';

const Item = styled.div<{ horizontal?: boolean; hashtags?: boolean }>`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  justify-content: ${({ horizontal }) =>
    horizontal ? 'space-between' : 'flex-start'};
  align-items: ${({ horizontal }) => (horizontal ? '' : 'flex-start')};
  padding: 1rem;
  line-height: 2rem;
  > div:last-of-type {
    display: ${({ hashtags }) => hashtags && 'grid'};
    grid-template-columns: ${({ hashtags }) =>
      hashtags && 'repeat(auto-fit, minmax(150px, 1fr))'};
    grid-column-gap: ${({ hashtags }) => hashtags && '3rem'};
  }
  // border-bottom: 1px solid rgba(213, 221, 227, 0.5);
`;
const Label = styled.div`
  color: #767676;
  font-size: 1.2rem;
`;
const Value = styled.div<{ horizontal?: boolean }>`
  color: #1b1b1b;
  font-size: 1.4rem;
  max-width: 90%;
  width: ${({ horizontal }) => (horizontal ? '' : '100%')};
`;

interface IProps {
  label: string;
  horizontal?: boolean;
  hashtags?: boolean;
}

export const ChannelDetailsItem: FC<IProps> = ({
  label,
  children,
  horizontal = true,
  hashtags,
}) => {
  return (
    <Item horizontal={horizontal} hashtags={hashtags}>
      <Label>{label}:</Label>
      <Value horizontal={horizontal}>{children}</Value>
    </Item>
  );
};
