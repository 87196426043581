import styled from '@emotion/styled';
import { InputBase, makeStyles } from '@material-ui/core';

export const Search = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  background: '#fff',
  border: '1px solid rgba(35, 31, 32, 0.16)',
  borderRadius: '6px',
  marginBottom: '1.5rem',
  paddingLeft: '2rem',
  width: '55%',
  height: '40px',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  minWidth: '250px',
  '& .MuiInputBase-input': {
    width: '100%',
    paddingLeft: '1rem',
  },
}));

export const IconContainer = styled.span`
  opacity: 0.43;
  margin-top: 8px;
  display: inline-flex;
  flex-direction: row;
`;

export const AddChannelFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const useStyles = makeStyles({
  input: {
    border: '1px solid #ffffff',
    fontFamily: 'Quicksand, sans-serif',
    backgroundColor: 'white',
    height: 34,
    width: '100%',
    padding: '0.625rem 1rem',
    alignItems: 'center',
    paddingRight: '40px',
  },
});

export const InputWrapper = styled.div`
  position: relative;
`;

export const StyledToggleIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
`;

export const NLWrapper = styled.div`
  display: flex;
  gap: 2rem;
  > div {
    flex: 1;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 2;
    > div {
      flex: none;
      width: 100%;
    }
  }
`;

export const LocationAgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  > div {
    flex: 1;
  }
  @media (max-width: 870px) {
    flex-direction: column;
  }
`;

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 75%;

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (min-width: 601px) and (max-width: 750px) {
    width: 60%;
  }
  @media (min-width: 751px) and (max-width: 900px) {
    width: 65%;
  }
`;

export const AgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 25%;

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (min-width: 601px) and (max-width: 750px) {
    width: 40%;
  }
  @media (min-width: 751px) and (max-width: 900px) {
    width: 35%;
  }
`;

export const AgeListWrapper = styled.div`
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  justify-content: space-between;
  height: 12.5rem;
  padding: 1rem;

  @media (max-width: 600px) {
    flex-direction: row;
    height: 4rem;
  }
  @media (max-width: 410px) {
    flex-direction: column;
    height: 12.5rem;
  }
`;

export const StyledCheckbox = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;

  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;

  border: 2px solid #949597;
  border-radius: 50%;
  background-color: ${(props) => (props.checked ? '#4a4a4a' : 'transparent')};

  &:checked {
    background-color: #4a4a4a;
  }

  transition: background-color 0.3s;
`;

export const Container = styled.div`
  background: #ffffff;
  height: 197px;
  @media (max-width: 870px) {
    height: auto;
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;
`;

export const TopicsWrapper = styled.div`
  padding: 1rem;
  background-color: #ffffff;
`;
export const PlxWrapper = styled.div`
  padding: 1rem;
  background-color: #ffffff;
`;

export const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  margin-bottom: 1.5rem;
`;

export const TopicsTitle = styled.h1`
  color: #5f5f61;
  font-family: Quicksand;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  margin-bottom: 1rem;
`;
