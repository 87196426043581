import { CellConfig } from '_Components/PlxCustomTable/PlxCustomTable';
import {
  IAgencyDTO,
  IClientsDTO,
  IOrderDTO,
  IUserDTO,
} from '_State/User/user.types';
import React, { ReactElement } from 'react';
import { PlxLinkTextButton } from '_Components/Buttons/PlxLinkTextButton/PlxLinkTextButton';
import {
  ButtonVariant,
  PlxButton,
} from '_Components/Buttons/PlxButton/PlxButton';
import { formatDate } from '_Components/DateFormat/DateFormat';
import { formatDateTime } from '_Components/DateFormat/DateTimeFormat';

export const createUsersTableConfig = (
  openClientEditDialog: (client: IUserDTO) => void,
  goToOrders: (client: IUserDTO) => void,
  goToAnalytics: (client: IUserDTO) => void
): CellConfig<IUserDTO>[] => {
  return [
    {
      name: 'Full Name',
      renderCell: (client: IUserDTO): ReactElement => {
        return <div>{client.fullName}</div>;
      },
    },
    {
      name: 'Email',
      propKey: 'email',
    },
    {
      name: 'Company Name',
      propKey: 'companyName',
    },
    {
      name: 'Registration Date',
      renderCell: (client: IUserDTO): ReactElement => {
        return <div>{formatDate(client.createdDate)}</div>;
      },
    },
    {
      name: 'Last Log In',
      renderCell: (client: IUserDTO): ReactElement => {
        return <div>{formatDate(client.lastLogin)}</div>;
      },
    },
    {
      name: '',
      renderCell: (client: IUserDTO): ReactElement => {
        return (
          <PlxLinkTextButton
            label={'Edit User'}
            onClick={() => {
              openClientEditDialog(client);
            }}
          />
        );
      },
    },
    {
      name: '',
      renderCell: (client: IUserDTO): ReactElement => {
        return (
          <PlxButton
            size={'small'}
            variant={ButtonVariant.Outlined}
            label={'Orders'}
            onClick={() => {
              goToOrders(client);
            }}
          />
        );
      },
    },
    // Enable it to see the analytics
    {
      name: '',
      renderCell: (client: IUserDTO): ReactElement => {
        return (
          <PlxButton
            size={'small'}
            variant={ButtonVariant.Outlined}
            label={'Analytics'}
            onClick={() => {
              goToAnalytics(client);
            }}
          />
        );
      },
    },
  ];
};

export const createAgenciesTableConfig = (
  openAgencyEditDialog: (agency: IAgencyDTO) => void,
  goToClients: (agency: IAgencyDTO) => void,
  goToAnalytics: (agency: IAgencyDTO) => void
): CellConfig<IAgencyDTO>[] => {
  return [
    {
      name: 'Full Name',
      renderCell: (agency: IAgencyDTO): ReactElement => {
        return <div>{agency.fullName}</div>;
      },
    },
    {
      name: 'Email',
      propKey: 'email',
    },
    {
      name: 'Company Name',
      propKey: 'companyName',
    },
    {
      name: 'Registration Date',
      renderCell: (agency: IAgencyDTO): ReactElement => {
        return <div>{formatDate(agency.createdDate)}</div>;
      },
    },
    {
      name: 'Last Log In',
      renderCell: (agency: IAgencyDTO): ReactElement => {
        return <div>{formatDate(agency.lastLogin)}</div>;
      },
    },
    {
      name: '',
      renderCell: (agency: IAgencyDTO): ReactElement => {
        return (
          <PlxLinkTextButton
            label={'Edit Agency'}
            onClick={() => {
              openAgencyEditDialog(agency);
            }}
          />
        );
      },
    },
    {
      name: '',
      renderCell: (agency: IAgencyDTO): ReactElement => {
        return (
          <PlxButton
            size={'small'}
            variant={ButtonVariant.Outlined}
            label={'Clients'}
            onClick={() => {
              goToClients(agency);
            }}
          />
        );
      },
    },
  ];
};

export const createAdminsAndEditorsTableConfig = (
  openAdminOrEditorEditDialog: (client: IUserDTO) => void
): CellConfig<IUserDTO>[] => {
  return [
    {
      name: 'Full Name',
      propKey: 'fullName',
    },
    {
      name: 'Email',
      propKey: 'email',
    },
    {
      name: 'Registration Date',
      renderCell: (client: IUserDTO): ReactElement => {
        return <div>{formatDate(client.createdDate)}</div>;
      },
    },
    {
      name: 'Last Log In',
      renderCell: (client: IUserDTO): ReactElement => {
        return <div>{formatDate(client.lastLogin)}</div>;
      },
    },
    {
      name: 'Privilege',
      propKey: 'privilege',
    },
    {
      name: '',
      renderCell: (client: IUserDTO): ReactElement => {
        return (
          <PlxButton
            size={'small'}
            variant={ButtonVariant.Outlined}
            label={'Edit User'}
            onClick={() => {
              openAdminOrEditorEditDialog(client);
            }}
          />
        );
      },
    },
  ];
};

export const createDistributionPartnersTableConfig = (
  openDistributionPartnerDetails: (client: IUserDTO) => void,
  openDistributionPartnerEdit: (client: IUserDTO) => void
): CellConfig<IUserDTO>[] => {
  return [
    {
      name: 'Full Name',
      propKey: 'fullName',
    },
    {
      name: 'Email',
      propKey: 'email',
    },
    {
      name: 'Business Name',
      propKey: 'businessName',
    },
    {
      name: 'Registration Date',
      renderCell: (client: IUserDTO): ReactElement => {
        return <div>{formatDate(client.createdDate)}</div>;
      },
    },
    {
      name: 'Last Log In',
      renderCell: (client: IUserDTO): ReactElement => {
        return <div>{formatDate(client.lastLogin)}</div>;
      },
    },
    {
      name: '',
      renderCell: (client: IUserDTO): ReactElement => {
        return (
          <PlxLinkTextButton
            label={'More details'}
            onClick={() => {
              openDistributionPartnerDetails(client);
            }}
          />
        );
      },
    },
    {
      name: '',
      renderCell: (client: IUserDTO): ReactElement => {
        return (
          <PlxButton
            variant={ButtonVariant.Outlined}
            size={'small'}
            label={'Edit User'}
            onClick={() => {
              openDistributionPartnerEdit(client);
            }}
          />
        );
      },
    },
  ];
};

export const createClientsTableConfig = (
  goToOrders: (client: IClientsDTO) => void
): CellConfig<IClientsDTO>[] => {
  return [
    {
      name: 'Company Name',
      renderCell: (client: IClientsDTO): ReactElement => {
        return <div>{client?.companyName}</div>;
      },
    },
    {
      name: 'Full Name',
      renderCell: (client: IClientsDTO): ReactElement => {
        return <div>{client?.fullName}</div>;
      },
    },
    {
      name: 'Email',
      renderCell: (client: IClientsDTO): ReactElement => {
        return <div>{client?.email}</div>;
      },
    },
    {
      name: '',
      renderCell: (client: IClientsDTO): ReactElement => {
        return (
          <PlxButton
            size={'small'}
            variant={ButtonVariant.Outlined}
            label={'Orders'}
            onClick={() => {
              goToOrders(client);
            }}
          />
        );
      },
    },
  ];
};

export const createOrdersTableConfig = (
  seeCampaign: (client: IOrderDTO) => void,
  openEditOrder: (client: IOrderDTO) => void,
  latestId: number
): CellConfig<IOrderDTO>[] => {
  return [
    {
      name: 'Program Status',
      propKey: 'status',
    },
    {
      name: 'Expiry Date',
      renderCell: (order: IOrderDTO): ReactElement => {
        return <div>{formatDateTime(order.expiryDate)}</div>;
      },
    },
    {
      name: 'Campaign Remaining',
      renderCell: (order: IOrderDTO): ReactElement => {
        return <div>{order.remainingCampaigns}</div>;
      },
    },
    {
      name: 'Total Campaigns',
      renderCell: (order: IOrderDTO): ReactElement => {
        return <div>{order.totalCampaigns}</div>;
      },
    },
    {
      name: 'InvoiceId',
      renderCell: (order: IOrderDTO): ReactElement => {
        return <div>{order.invoiceId}</div>;
      },
    },
    {
      name: 'Estimated Followers',
      renderCell: (order: IOrderDTO): ReactElement => {
        return <div>{order.estimatedReach ? order.estimatedReach : 0}</div>;
      },
    },
    // {
    //   name: '',
    //   renderCell: (order: IOrderDTO): ReactElement => {
    //     return (
    //       <PlxButton
    //         variant={ButtonVariant.Outlined}
    //         size={'small'}
    //         isDisabled
    //         label={'See campaign'}
    //         onClick={() => {
    //           seeCampaign(order);
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      name: '',
      renderCell: (order: IOrderDTO): ReactElement => {
        return (
          <div style={{ marginLeft: 'auto', width: '135px' }}>
            <PlxButton
              variant={ButtonVariant.Outlined}
              size={'small'}
              label={'Signed Contract'}
              onClick={() => {
                seeCampaign(order);
              }}
            />
          </div>
        );
      },
    },
    {
      name: '',
      renderCell: (order: IOrderDTO): ReactElement => {
        const isDisabled = latestId !== order.id ? true : false;
        return (
          <div style={{ marginLeft: 'auto', width: '110px' }}>
            <PlxButton
              variant={ButtonVariant.Outlined}
              size={'small'}
              label={'Edit'}
              isDisabled={isDisabled}
              onClick={() => {
                openEditOrder(order);
              }}
            />
          </div>
        );
      },
    },
  ];
};
