import React, { FC, useEffect, useState } from 'react';
import {
  IAnalyticsDTO,
  IAnalyticsForm,
} from '_State/DistributionPartner/distributionPartner.types';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import styled from '@emotion/styled';
import { InputSectionX } from '_Routes/Home/_Components/Sections/InputSectionX';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { FormSection } from '_Components/FormSection/FormSection';
import { PlxDatePicker } from '_Components/PlxDatePicker/PlxDatePicker';
import moment from 'moment';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import {
  submitAnalytics,
  updateAnalytics,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import { IThemed } from '_Styles/types';
import { PlxFilesInput } from '_Routes/Home/_Components/PostSamples/FilesInput';
import { IUploadFileDto } from '_State/Thrive/types';
import { ScreenshotsDialog } from './ScreenshotsDialog';
import { AddScreenshotsDialog } from './AddScreenshotsDialog';

interface IProps {
  submitAnalyticsDialogData: {
    data?: IAnalyticsDTO;
    id?: number;
    status?: string;
  };
  onAnalyticsSubmitted: () => void;
  onBack: () => void;
}

export type Attachment = {
  fileName: string;
  base64Content: string;
};

const INITIAL_VALUES: IAnalyticsForm = {
  comments: undefined,
  shares: undefined,
  datePosted: undefined,
  postSample: null,
  postUrl: '',
  reactions: undefined,
  impressions: undefined,
  videoSaves: undefined,
  numberOfFollowers: undefined,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  color: #5a3c5a;
`;

const Note = styled.div`
  background: #f0f2f8;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3.5rem;
  color: #1b1b1b;
  margin-bottom: 1.5rem;
`;

const Title = styled.div<IThemed>`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 1.8rem;
  font-family: Rubik, sans-serif;
  margin: ${({ theme }) => theme.spacing(2, 0.5, 1, 0.5)};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 5.5rem;
  // margin-top: 2rem;
  margin-bottom: 2rem;

  .MuiButton-root {
    padding: 0.6rem 3.2rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  gap: 1rem;
  > div {
    flex: 1;
  }
`;
const StyledButton = styled.button`
  width: -webkit-fill-available;
  height: 3.6rem;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin: 1.5px 0.5px 0.5px;
  &:hover {
    border: 1px solid #4f007d;
    cursor: pointer;
  }
`;
const StyledDissabledButton = styled.button`
  width: -webkit-fill-available;
  height: 3.6rem;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin: 1.5px 0.5px 0.5px;
`;
export const SubmitAnalyticsForm: FC<IProps> = ({
  submitAnalyticsDialogData,
  onBack,
  onAnalyticsSubmitted,
}) => {
  const { t } = useTranslation();
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState<boolean>();
  const [postedDate, setPostedDate] = useState<Date | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [updatedAttachments, setUpdatedAttachments] = useState<Attachment[]>(
    []
  );

  const REQUIRED_FIELDS = ['datePosted', 'postUrl', 'numberOfFollowers'];

  const isPostSampleRequired = !submitAnalyticsDialogData.data?.postSample;

  if (isPostSampleRequired) {
    REQUIRED_FIELDS.push('postSample');
  }

  const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );

  const isVideoSaves =
    submitAnalyticsDialogData.data?.channelType === 'INSTAGRAM' ||
    submitAnalyticsDialogData.data?.channelType === 'TIKTOK'
      ? true
      : false;

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (submittedForm) => {
      const form = !isVideoSaves
        ? { ...submittedForm, videoSaves: '0' }
        : submittedForm;
      if (requiredFormValidation(form)) {
        setIsProcessing(true);
        if (submitAnalyticsDialogData.data?.note) {
          updateAnalytics(
            form,
            submitAnalyticsDialogData.data.postSample.id,
            submitAnalyticsDialogData.id
          ).then(
            () => {
              onAnalyticsSubmitted();
              setIsProcessing(false);
            },
            (error) => {
              error.response.json().then((error: any) => {
                setApiErrorMessage(error.message);
                setIsProcessing(false);
              });
            }
          );
        } else {
          submitAnalytics(form, submitAnalyticsDialogData.id).then(
            () => {
              onAnalyticsSubmitted();
              setIsProcessing(false);
            },
            (error: any) => {
              error.response.json().then((error: any) => {
                setApiErrorMessage(error.message);
                setIsProcessing(false);
              });
            }
          );
        }
      }
    },
    validate: (submittedForm) => {
      const form = !isVideoSaves
        ? { ...submittedForm, videoSaves: '0' }
        : submittedForm;
      const errors = requiredFieldsValidation(form);
      if (form.comments && (isNaN(form.comments) || form.comments < 0)) {
        errors.comments = 'Please provide correct number value';
      }
      if (form.comments === undefined) {
        errors.comments = t('errors.reqField');
      }
      if (form.shares && (isNaN(form.shares) || form.shares < 0)) {
        errors.shares = 'Please provide correct number value';
      }
      if (form.shares === undefined) {
        errors.shares = t('errors.reqField');
      }
      if (form.reactions && (isNaN(form.reactions) || form.reactions < 0)) {
        errors.reactions = 'Please provide correct number value';
      }
      if (form.reactions === undefined) {
        errors.reactions = t('errors.reqField');
      }
      if (
        form.impressions &&
        (isNaN(form.impressions) || form.impressions < 0)
      ) {
        errors.impressions = 'Please provide correct number value';
      }
      if (form.impressions === undefined) {
        errors.impressions = t('errors.reqField');
      }
      if (
        form.videoSaves &&
        (isNaN(Number(form.videoSaves)) || Number(form.videoSaves) < 0)
      ) {
        errors.videoSaves = 'Please provide correct number value';
      }
      if (
        form.numberOfFollowers &&
        (isNaN(form.numberOfFollowers) || form.numberOfFollowers < 0)
      ) {
        errors.numberOfFollowers = 'Please provide correct number value';
      }
      if (
        form.postUrl &&
        !form.postUrl.startsWith('http://') &&
        !form.postUrl.startsWith('https://')
      ) {
        errors.postUrl = t('errors.companyWebsite');
      }
      if (form?.postSample && form.postSample.length < 1) {
        errors.postSample = 'Please upload screenshot!';
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });
  const fillForm = (data?: IAnalyticsDTO) => {
    formik.setValues({
      reactions: data?.reactions,
      comments: data?.comments,
      shares: data?.shares,
      datePosted: data?.datePosted,
      postUrl:
        data?.postUrl ||
        (data?.postSample?.originalPostUrl
          ? data.postSample.originalPostUrl
          : undefined),
      numberOfFollowers: data?.numberOfFollowers,
      impressions: data?.impressions,
      videoSaves: data?.videoSaves,
    });
    formik.setFieldValue(
      'postSample',
      data?.connected ? data.postSample : null
    );
    if (data?.datePosted) {
      setPostedDate(moment(data?.datePosted).toDate());
    }
  };

  useEffect(() => {
    fillForm(submitAnalyticsDialogData.data);
    // eslint-disable-next-line
  }, [submitAnalyticsDialogData]);

  const handleChange = (base64Urls: string[], fileNames: string[]) => {
    const cleanedBase64Urls = base64Urls.map((base64Content) =>
      base64Content.replace(/^data:image\/[a-z]+;base64,/, '')
    );
    const attachmentsData: IUploadFileDto[] = cleanedBase64Urls.map(
      (base64Content, index) => ({
        fileName: fileNames[index],
        base64Content: base64Content,
      })
    );

    let currentAttachments: IUploadFileDto[] = formik.values.postSample || [];

    if (!Array.isArray(currentAttachments)) {
      currentAttachments = [];
    }

    const updatedAttachments = [
      ...currentAttachments.slice(1),
      ...attachmentsData,
    ];

    formik.setFieldValue('postSample.attachments', updatedAttachments);
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handlePostedDateChange = (date: Date | null) => {
    const datePosted = moment(date).format('YYYY-MM-DD[T]HH:MM:ss.SSS[Z]');
    formik.setFieldValue('datePosted', datePosted);
    setPostedDate(date);
  };

  const isDisabled = submitAnalyticsDialogData.data?.connected
    ? submitAnalyticsDialogData.data?.postUrl
      ? true
      : false
    : false;

  const handleAddScreenshotClose = () => {
    setEditDialogOpen(true);
    setUpdateDialogOpen(false);
  };
  const handleEditScreenshotClose = () => {
    setEditDialogOpen(false);
    setUpdateDialogOpen(false);
    setUpdatedAttachments([]);
    formik.setFieldValue(
      'postSample',
      submitAnalyticsDialogData.data?.postSample
        ? submitAnalyticsDialogData.data.postSample
        : null
    );
  };
  const handleUpload = (value: number) => {
    setEditDialogOpen(false);
    setUpdateDialogOpen(true);
  };
  const handleAddScreenshotSave = () => {
    setUpdateDialogOpen(false);
    setEditDialogOpen(true);
  };
  const handleUpdateScreenshotSave = () => {
    setUpdateDialogOpen(false);
    setEditDialogOpen(false);
  };

  return (
    <Wrapper>
      {submitAnalyticsDialogData?.data?.note && (
        <>
          <Title>{t('opportunities.submitAnalytics.notes')}</Title>
          <Note>{submitAnalyticsDialogData?.data.note}</Note>
        </>
      )}
      <InputSectionX
        typeNumber
        disabled={isDisabled}
        title={t('opportunities.submitAnalytics.likes')}
        placeholder={t('opportunities.submitAnalytics.likesPlaceholder')}
        required
        value={submitAnalyticsDialogData.data?.reactions}
        fieldName={'reactions'}
        handleChange={formik.handleChange}
      />
      <FieldErrorMessage message={formik.errors.reactions} />
      <InputSectionX
        typeNumber
        title={t('opportunities.submitAnalytics.comments')}
        placeholder={t('opportunities.submitAnalytics.commentsPlaceholder')}
        disabled={isDisabled}
        required
        value={submitAnalyticsDialogData.data?.comments}
        fieldName={'comments'}
        handleChange={formik.handleChange}
      />
      <FieldErrorMessage message={formik.errors.comments} />
      <InputSectionX
        typeNumber
        title={t('opportunities.submitAnalytics.shares')}
        placeholder={t('opportunities.submitAnalytics.sharesPlaceholder')}
        disabled={isDisabled}
        required
        fieldName={'shares'}
        value={submitAnalyticsDialogData.data?.shares}
        handleChange={formik.handleChange}
      />
      <FieldErrorMessage message={formik.errors.shares} />

      <InputSectionX
        typeNumber
        title={t('opportunities.submitAnalytics.impressions')}
        placeholder={t('opportunities.submitAnalytics.impressionsPlaceholder')}
        required
        fieldName={'impressions'}
        disabled={isDisabled}
        value={submitAnalyticsDialogData.data?.impressions}
        handleChange={formik.handleChange}
      />
      <FieldErrorMessage message={formik.errors.impressions} />
      {submitAnalyticsDialogData.data?.channelType === 'TIKTOK' && (
        <>
          <InputSectionX
            typeNumber
            title={t('opportunities.submitAnalytics.videoSaves')}
            placeholder={t(
              'opportunities.submitAnalytics.videoSavesPlaceholder'
            )}
            required
            fieldName={'videoSaves'}
            value={submitAnalyticsDialogData.data?.videoSaves}
            handleChange={formik.handleChange}
          />
          <FieldErrorMessage message={formik.errors.videoSaves} />
        </>
      )}
      {submitAnalyticsDialogData.data?.channelType === 'INSTAGRAM' && (
        <>
          <InputSectionX
            typeNumber
            title={t('opportunities.submitAnalytics.videoSaves')}
            placeholder={t(
              'opportunities.submitAnalytics.videoSavesPlaceholder'
            )}
            required
            fieldName={'videoSaves'}
            value={submitAnalyticsDialogData.data?.videoSaves}
            handleChange={formik.handleChange}
          />
          <FieldErrorMessage message={formik.errors.videoSaves} />
        </>
      )}
      {!submitAnalyticsDialogData.data?.postUrl ? (
        <InputSectionX
          title={t('opportunities.submitAnalytics.postUrl')}
          placeholder={t('opportunities.submitAnalytics.postUrlPlaceholder')}
          required
          disabled={isDisabled}
          fieldName={'postUrl'}
          value={submitAnalyticsDialogData.data?.postSample?.originalPostUrl}
          handleChange={formik.handleChange}
        />
      ) : (
        <InputSectionX
          title={t('opportunities.submitAnalytics.postUrl')}
          required
          fieldName={'postUrl'}
          disabled={isDisabled}
          value={
            submitAnalyticsDialogData.data?.postSample?.originalPostUrl
              ? submitAnalyticsDialogData.data?.postSample?.originalPostUrl
              : submitAnalyticsDialogData.data?.postUrl
          }
          handleChange={formik.handleChange}
        />
      )}
      <FieldErrorMessage message={formik.errors.postUrl} />
      <InputContainer>
        <div>
          <FormSection
            title={t('opportunities.submitAnalytics.datePosted')}
            required
          >
            <PlxDatePicker
              disableFuture
              disabled={isDisabled}
              disableToolbar
              inputVariant="outlined"
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              placeholder={t(
                'opportunities.submitAnalytics.datePostedPlaceholder'
              )}
              value={postedDate}
              fullwidth={true}
              onChange={handlePostedDateChange}
            />
          </FormSection>
          <FieldErrorMessage message={formik.errors.datePosted} />
        </div>
        <div>
          {submitAnalyticsDialogData?.data?.postSample ? (
            <FormSection
              title={t('opportunities.submitAnalytics.screenshots')}
              tooltip={t('influencer.addChannel.screenshotTooltip')}
              required
            >
              {submitAnalyticsDialogData.data.connected ? (
                <StyledDissabledButton disabled>
                  Upload/Replace
                </StyledDissabledButton>
              ) : (
                <StyledButton onClick={() => setEditDialogOpen(true)}>
                  Upload/Replace
                </StyledButton>
              )}
            </FormSection>
          ) : (
            <FormSection
              title={t('opportunities.submitAnalytics.screenshot')}
              tooltip={t('influencer.addChannel.screenshotTooltip')}
              required
            >
              <PlxFilesInput fileNames={[]} onChange={handleChange} />
            </FormSection>
          )}
          <FieldErrorMessage message={formik.errors.postSample} />
          <FieldErrorMessage message={apiErrorMessage} />
        </div>
      </InputContainer>

      <InputSectionX
        typeNumber
        title={t('opportunities.submitAnalytics.updateFollowers')}
        placeholder={t('opportunities.submitAnalytics.updateFollowers')}
        required
        disabled={isDisabled}
        fieldName={'numberOfFollowers'}
        value={submitAnalyticsDialogData.data?.numberOfFollowers}
        handleChange={formik.handleChange}
      />
      <FieldErrorMessage message={formik.errors.numberOfFollowers} />
      <ButtonsWrapper>
        <PlxButtonX
          label={t('channels.approveChannel.submit')}
          onClick={handleSubmit}
          isDisabled={isProcessing}
          isProcessing={isProcessing}
        />
      </ButtonsWrapper>
      <ScreenshotsDialog
        formik={formik}
        dialogTitle={'Edit post sample'}
        initialValue={submitAnalyticsDialogData.data?.postSample}
        open={editDialogOpen}
        onClose={handleEditScreenshotClose}
        onUpload={handleUpload}
        onSave={handleUpdateScreenshotSave}
        updatedAttachments={updatedAttachments}
        setUpdatedAttachments={setUpdatedAttachments}
      />

      <AddScreenshotsDialog
        formik={formik}
        initialValue={submitAnalyticsDialogData.data?.postSample}
        dialogTitle={'Add more screenshots'}
        open={updateDialogOpen}
        onClose={handleAddScreenshotClose}
        onSave={handleAddScreenshotSave}
        updatedAttachments={updatedAttachments}
        setUpdatedAttachments={setUpdatedAttachments}
      />
    </Wrapper>
  );
};
