import ky from 'ky';
import { spectrumApi } from '_Api/api';
import { TokenLevelEnum } from '../FacebookManagementPopup';
import {
  IFacebookAccount,
  IFacebookGroup,
  IFacebookResponse,
  IRequestOptions,
} from '../interfaces';

export const getFacebookData = async (
  authToken: string,
  setFacebookData: any,
  setPopupReady?: (arg: boolean) => void
) => {
  const facebookGroupsQuery = `https://graph.facebook.com/me/groups?fields=name,member_count,picture,link&admin_only=true`;
  const facebookPagesQuery = `https://graph.facebook.com/me?fields=id,name,email,installed,friends,link,accounts{id,name,fan_count,link,picture{url},connected_instagram_account{id,username,followers_count,profile_picture_url}}`;

  await ky
    .get(facebookGroupsQuery, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((res) => res.json())
    .then((res) => {
      const data = res.data.map((el: any) => {
        return { ...el, link: `https://www.facebook.com/${el.id}` };
      });
      setFacebookData((prevState: IFacebookResponse) => ({
        ...prevState,
        groups: data,
      }));
    });

  ky.get(facebookPagesQuery, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res?.accounts?.data) {
        res.accounts = res.accounts.data;
      }
      setFacebookData((prevState: IFacebookResponse) => ({
        ...prevState,
        ...res,
      }));
      if (setPopupReady) {
        setPopupReady(true);
      }
    });
};

export const handleAccountConnect = (
  id: string,
  tokenLevel: TokenLevelEnum,
  facebookData: IFacebookResponse,
  setFacebookData: any,
  setIsFormDisabled: (arg: boolean) => void,
  handleClose: () => void,
  userNm?: string,
  followersCount?: number
) => {
  const requestOptions: IRequestOptions = {
    channelType: '',
    name: '',
    socialMediaMasterId: facebookData.userID,
    socialMediaNodeId: facebookData.userID,
    accessToken: facebookData.accessToken,
  };

  const channelConnectRequest = (requestOptions: IRequestOptions): any => {
    setIsFormDisabled(true);
    return spectrumApi
      .post(`connectors/meta/connect?channelId=${facebookData.channelId}`, {
        json: requestOptions,
      })
      .json();
  };

  if (tokenLevel === TokenLevelEnum.instagram) {
    requestOptions.channelType = 'INSTAGRAM';
    requestOptions.name = facebookData.name || '';
    requestOptions.socialMediaMasterId = facebookData.id;
    requestOptions.socialMediaNodeId = id;
    requestOptions.followers = followersCount;
    requestOptions.username = userNm;
    requestOptions.permaLink = facebookData.link;

    channelConnectRequest(requestOptions)
      .then(() => {
        setFacebookData({ ...facebookData, connected: true });
        setIsFormDisabled(false);
      })
      .then(() => {
        handleClose();
      })
      .catch((err: Error) => {
        setIsFormDisabled(false);
        return err;
      });
  }
  if (tokenLevel === TokenLevelEnum.profile) {
    requestOptions.channelType = 'FACEBOOK_USER';
    requestOptions.followers = facebookData?.friends?.summary.total_count;
    requestOptions.permaLink = facebookData.link;
    requestOptions.name = facebookData.name || '';
    channelConnectRequest(requestOptions)
      .then(() => {
        setFacebookData({ ...facebookData, connected: true });
        setIsFormDisabled(false);
      })
      .then(() => {
        handleClose();
      })
      .catch((err: Error) => {
        setIsFormDisabled(false);
        return err;
      });
  }

  if (tokenLevel === TokenLevelEnum.account) {
    if (!facebookData?.accounts) return;
    const updatedData = facebookData.accounts.map(
      (x: IFacebookAccount): IFacebookAccount => {
        if (x.id === id) {
          requestOptions.channelType = 'FACEBOOK_PAGE';
          requestOptions.socialMediaNodeId = x.id;
          requestOptions.followers = x.fan_count;
          requestOptions.name = x.name;
          requestOptions.permaLink = x.link;
          return { ...x, connected: true };
        } else {
          return x;
        }
      }
    );
    channelConnectRequest(requestOptions)
      .then(() => {
        setFacebookData({
          ...facebookData,
          accounts: updatedData,
        });
        setIsFormDisabled(false);
      })
      .then(() => handleClose())
      .catch((err: Error) => {
        setIsFormDisabled(false);
        return err;
      });
  }
  if (tokenLevel === TokenLevelEnum.groups) {
    if (!facebookData.groups) return;
    const updatedData = facebookData.groups.map(
      (x: IFacebookGroup): IFacebookGroup => {
        if (x.id === id) {
          requestOptions.channelType = 'FACEBOOK_GROUP';
          requestOptions.socialMediaNodeId = x.id;
          requestOptions.name = x.name;
          requestOptions.followers = x.member_count;
          requestOptions.permaLink = x.link
            ? x.link
            : `https://www.facebook.com/groups/${x.id}`;
          return { ...x, connected: true };
        } else {
          return x;
        }
      }
    );
    channelConnectRequest(requestOptions)
      .then(() => {
        setFacebookData({
          ...facebookData,
          groups: updatedData,
        });
        setIsFormDisabled(false);
      })
      .then(() => handleClose())
      .catch((err: Error) => {
        setIsFormDisabled(false);
        return err;
      });
  }
};
