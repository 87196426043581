import styled from '@emotion/styled';

export const StyleOverwrite = styled.div`
  overflow: hidden;
  padding: 20px 30px;
  width: auto;
  background-color: #f6f6f6;
`;
export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 20px 30px;
`;
export const ProfileContainer = styled.div`
  display: flex;
  & img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
`;

export const NameDiv = styled.div`
  margin-left: 16px;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  align-self: flex-end;
`;
export const FriendsDiv = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 3rem;
`;
export const ManagedItemsList = styled.div`
  margin: 10px 0;
  padding: 10px;
  background-color: #e5e5e5;
`;
export const ListElement = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
`;
export const ManagedListTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
`;
export const ListInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`;
export const ListElementTitle = styled.div`
  align-self: center;
  font-weight: 700;
  font-size: 12px;
  width: 100%;
`;
export const ListElementDescription = styled.div`
  margin-top: 3px;
  align-self: center;
  font-weight: 400;
  font-size: 12px;
  width: 100%;
`;

export const ListElementProfileContainer = styled.div`
  display: flex;
  & img {
    align-self: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`;
