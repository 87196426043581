import React, { FC } from 'react';
import {
  DataGrid,
  GridRowsProp,
  GridSelectionModelChangeParams,
} from '@material-ui/data-grid';
import { GridOverlay, GridColumns } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ReactElement } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

function CustomLoadingOverlay(): ReactElement {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      '&.MuiDataGrid-root .MuiDataGrid-footer': {
        display: 'none',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '&.MuiDataGrid-root .MuiDataGrid-colCell': {
        padding: '0',
      },
      '&.MuiDataGrid-root .MuiDataGrid-cell': {
        padding: '0',
      },
      '&.MuiDataGrid-root .MuiDataGrid-colCellWrapper': {
        backgroundColor: '#f8f8f8',
      },
      '&.MuiDataGrid-root .MuiDataGrid-row.Mui-odd': {
        backgroundColor: '#f8f8f8',
      },
      '&.MuiDataGrid-root .MuiDataGrid-row': {
        borderBottom: 'none',
      },
      '& div div div div >.MuiDataGrid-cell': {
        borderBottom: 'none',
      },
    },
  })
);

interface IProps {
  header: GridColumns;
  data: GridRowsProp;
  checkbox?: boolean;
  handleRowSelection?: (param: GridSelectionModelChangeParams) => void;
  density?: 'compact' | 'standard' | 'comfortable';
  loading?: boolean;
}
export const XemTable: FC<IProps> = ({
  header,
  data,
  checkbox,
  handleRowSelection,
  density,
  loading,
}) => {
  const classes = useStyles();
  return (
    <div
      style={{
        height: '145px',
        width: '100%',
        background: '#fff',
        overflow: 'hidden',
        marginBottom: '2rem',
        borderRadius: '5px',
      }}
    >
      <DataGrid
        rows={loading ? [] : data}
        columns={header}
        checkboxSelection={checkbox}
        density={density}
        disableSelectionOnClick
        onSelectionModelChange={handleRowSelection}
        loading={loading}
        // filterMode={filterMode}
        // onFilterModelChange={onFilterModelChange}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
        }}
        className={classes.table}
        rowHeight={36}
        headerHeight={36}
        disableColumnMenu
      />
    </div>
  );
};
