import React, { FC, useMemo, useState } from 'react';
import { CellConfigX } from '_Components/PlxCustomTable/PlxCustomTable';
import { PlxCustomTableX } from '_Components/PlxCustomTable/PlxCustomTableX';
import { createDistributionPartnerCampaignsTableConfig } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerDashboard/utils';
import {
  IUrlDTO,
  IAnalyticsDTO,
  IDistributionPartnerOpportunityDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import { PlxTableToolbarX } from '_Components/PlxTableToolbar/PlxTableToolbarX';
import { useTranslation } from 'react-i18next';
import { CampaignDetails } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerOpportunities/CampaignDetails';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { SubmitAnalyticsForm } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerDashboard/SubmitAnalyticsForm';
import { SubmitUrlForm } from '_Routes/Home/_routes/DistributionPartner/_Routes/FinfluencerDashboard/SubmitUrlForm';
import { orderBy } from 'lodash';
import { PlxInputX } from '_Components/PlxInput/PlxInputX';

export const DistributionPartnerCampaignsTableX: FC<{
  campaigns: IDistributionPartnerOpportunityDTO[];
  urlSubmitted: () => void;
  analyticsSubmitted: () => void;
}> = ({ campaigns, urlSubmitted, analyticsSubmitted }) => {
  const { t } = useTranslation();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [submitUrlDialogOpen, setSubmitUrlDialogOpen] = useState(false);
  const [submitUrlDialogData, setSubmitUrlDialogData] = useState<{
    url?: IUrlDTO;
  }>({ url: undefined });
  const [submitAnalyticsDialogOpen, setSubmitAnalyticsDialogOpen] = useState(
    false
  );
  const [submitAnalyticsDialogData, setSubmitAnalyticsDialogData] = useState<{
    data?: IAnalyticsDTO;
    id?: number;
    status?: string;
  }>({ id: undefined, status: undefined, data: undefined });

  const [campaignDetailsIds, setCampaignDetailsIds] = useState<{
    campaignId?: number;
    opportunityId?: number;
    channelType?: string;
  }>({
    campaignId: undefined,
    opportunityId: undefined,
    channelType: undefined,
  });
  const [columnToSort, setColumnToSort] = useState<string>('');
  const [sortDataDirection, setSortDataDirection] = useState<any>('desc');
  const [q, setQ] = useState<any>('');

  const openDetails = (
    campaignId: number,
    opportunityId: number,
    channelType: string
  ) => {
    setCampaignDetailsIds({
      campaignId: campaignId,
      opportunityId: opportunityId,
      channelType: channelType,
    });
    setDetailsOpen(true);
  };

  const closeDetails = () => {
    setDetailsOpen(false);
  };
  const handleSubmitUrl = (url: IUrlDTO) => {
    setSubmitUrlDialogData({ url: url });
    setSubmitUrlDialogOpen(true);
  };

  const closeSubmitUrl = () => {
    setSubmitUrlDialogOpen(false);
  };

  const handleUrlSubmitted = () => {
    setSubmitUrlDialogOpen(false);
    urlSubmitted();
  };

  const handleSubmitAnalitics = (
    id: number,
    status: string,
    data: IAnalyticsDTO
  ) => {
    setSubmitAnalyticsDialogData({ id: id, status: status, data: data });
    setSubmitAnalyticsDialogOpen(true);
  };

  const closeSubmitAnalytics = () => {
    setSubmitAnalyticsDialogOpen(false);
  };

  const handleAnalyticsSubmitted = () => {
    setSubmitAnalyticsDialogOpen(false);
    analyticsSubmitted();
  };

  const config = useMemo(() => {
    return createDistributionPartnerCampaignsTableConfig(
      openDetails,
      handleSubmitUrl,
      handleSubmitAnalitics
    ) as CellConfigX<{}>[];
    // eslint-disable-next-line
  }, []);

  const handleSort = (name: string) => {
    setColumnToSort(name);
    setSortDataDirection(
      columnToSort === name
        ? sortDataDirection === 'desc'
          ? 'asc'
          : 'desc'
        : 'asc'
    );
  };

  const search = (rows: any) => {
    return rows.filter(
      (row: any) =>
        row.channelName.toLowerCase().indexOf(q) > -1 ||
        row.channelType.toLowerCase().indexOf(q) > -1 ||
        row.postDue.toLowerCase().indexOf(q) > -1 ||
        row.analyticsDue.toLowerCase().indexOf(q) > -1 ||
        row.status.toLowerCase().indexOf(q) > -1
    );
  };

  return (
    <>
      <PlxCustomTableX
        config={config}
        columnToSort={columnToSort}
        sortDataDirection={sortDataDirection}
        data={orderBy(search(campaigns), columnToSort, sortDataDirection)}
        fullHeight={true}
        onClick={handleSort}
      >
        <PlxTableToolbarX label={t('influencer.dashboard.tableLabel')}>
          <div>
            <PlxInputX
              placeholder="Search"
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
          </div>
        </PlxTableToolbarX>
      </PlxCustomTableX>
      <PlxDialog
        title={t('channels.campaignDetails.title')}
        open={detailsOpen}
        onClose={closeDetails}
      >
        <CampaignDetails campaignDetailsIds={campaignDetailsIds} />
      </PlxDialog>
      <PlxDialog
        title={t('channels.campaignDetails.submitUrl')}
        open={submitUrlDialogOpen}
        onClose={closeSubmitUrl}
      >
        <SubmitUrlForm
          onBack={closeSubmitUrl}
          onUrlSubmitted={handleUrlSubmitted}
          submitUrlDialogData={submitUrlDialogData}
        />
      </PlxDialog>
      <PlxDialog
        title={t('channels.campaignDetails.submitAnalytics')}
        open={submitAnalyticsDialogOpen}
        onClose={closeSubmitAnalytics}
      >
        <SubmitAnalyticsForm
          onBack={closeSubmitAnalytics}
          onAnalyticsSubmitted={handleAnalyticsSubmitted}
          submitAnalyticsDialogData={submitAnalyticsDialogData}
        />
      </PlxDialog>
    </>
  );
};
