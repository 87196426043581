import {
  IDistributionPartnerAddChannelForm,
  IDistributionPartnerChannelDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import {
  prepareRequiredFieldsValidation,
  prepareRequiredFormValidation,
} from '_Components/Forms/utils';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { mapTranslatedLabel } from '_App/utils';
import { geoConfig } from '_Routes/Home/_routes/Spectrum/config';
import { PlxLoader } from '_Components/PlxLoader';
import { ButtonGroupSection } from '_Routes/Home/_Components/Sections/ButtonGroupSection';
import { channelTypeConfig } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerChannel/_Routes/distributionPartnerForm.config';
import { FieldErrorMessage } from '_Components/Forms/FieldErrorMessage';
import { InputSectionX } from '_Routes/Home/_Components/Sections/InputSectionX';
import { FooterButtons } from '_Components/FooterButtons/FooterButtons';
import { getChannels } from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import _ from 'lodash';
import { PlxCheckbox } from './../../../../src/_Components/PlxCheckbox/PlxCheckbox';
import { InputSectionDropDown } from './Sections/NewChannelSection/InputSectionDropDown';
import {
  ages,
  background,
  countries,
  designations,
  finances,
  gender,
  investing,
  other,
  stocks,
  themes,
} from './Sections/NewChannelSection/options';
import { TopicsSection } from './Sections/NewChannelSection/TopicsSection';
import { PlxSection } from './Sections/NewChannelSection/PlxSection';
import {
  AddChannelFormWrapper,
  AgeWrapper,
  BottomWrapper,
  LocationAgeWrapper,
  LocationWrapper,
  NLWrapper,
} from './Sections/styles';
import { MultiSection } from './Sections/NewChannelSection/MultiSection';

const INITIAL_VALUES: IDistributionPartnerAddChannelForm = {
  channelType: '',
  geolocations: [],
  channelName: '',
  channelUrl: '',
  numberOfFollowers: undefined,
  followersPrimaryLocation: '',
  followersSecondaryLocation: '',
  followersPrimaryAge: '',
  designations: [],
  background: [],
  gender: '',
  age: '',
  other: '',
  topics: [],
  dontWantOffer: false,
};

const REQUIRED_FIELDS = [
  'channelType',
  'geolocations',
  'channelName',
  'channelUrl',
  'numberOfFollowers',
  'followersPrimaryLocation',
  'followersSecondaryLocation',
  'followersPrimaryAge',
  'designations',
  'background',
  'gender',
  'age',
  'other',
  'topics',
];

const requiredFormValidation = prepareRequiredFormValidation(REQUIRED_FIELDS);

interface IProps {
  onSubmit: (form: IDistributionPartnerAddChannelForm) => void;
  apiError?: string;
  footerButtons?: boolean;
  id?: string;
  performSubmit?: boolean;
}

export const ChannelForm: FC<IProps> = ({
  onSubmit,
  footerButtons,
  id,
  performSubmit,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDistributionPartnerChannelDTO>();

  const requiredFieldsValidation = prepareRequiredFieldsValidation(
    REQUIRED_FIELDS,
    t('errors.reqField')
  );
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: (form) => {
      if (requiredFormValidation(form)) {
        onSubmit(form);
      }
    },
    validate: (form) => {
      const errors = requiredFieldsValidation(form);
      const Url = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;
      if (
        form.channelUrl &&
        !form.channelUrl.startsWith('http://') &&
        !form.channelUrl.startsWith('https://')
      ) {
        errors.channelUrl = t('errors.companyWebsite');
      }

      if (form.channelUrl && !Url.test(form.channelUrl)) {
        errors.channelUrl = 'Please enter a valid URL';
      }
      if (
        form.numberOfFollowers &&
        (isNaN(form.numberOfFollowers) || form.numberOfFollowers < 0)
      ) {
        errors.numberOfFollowers = 'Please provide correct number value';
      }
      if (form.numberOfFollowers && form.numberOfFollowers > 2147483647) {
        errors.numberOfFollowers = 'Value is too big';
      }
      if (form.geolocations && form.geolocations.length < 1) {
        errors.geolocations = 'This field is required';
      }
      if (form.categoriesOfInterest && form.categoriesOfInterest.length < 1) {
        errors.categoriesOfInterest = 'This field is required';
      }
      if (
        form.followersPrimaryLocation &&
        form.followersPrimaryLocation.length < 1
      ) {
        errors.followersPrimaryLocation = 'This field is required';
      }
      if (
        form.followersSecondaryLocation &&
        form.followersSecondaryLocation.length < 1
      ) {
        errors.followersSecondaryLocation = 'This field is required';
      }
      if (form.followersPrimaryAge && form.followersPrimaryAge.length < 1) {
        errors.followersPrimaryAge = 'This field is required';
      }
      if (form.topics && form.topics.length < 1) {
        errors.topics = 'This field is required';
      }
      if (form.designations && form.designations.length < 1) {
        errors.designations = 'This field is required';
      }
      if (form.background && form.background.length < 1) {
        errors.background = 'Background field is required';
      }
      if (form.gender && form.gender.length < 1) {
        errors.gender = 'Gender field is required';
      }
      if (form.age && form.age.length < 1) {
        errors.age = 'Age field is required';
      }
      if (form.other && form.other.length < 1) {
        errors.other = 'Other field is required';
      }
      return errors;
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const geolocationOptions = geoConfig.map((item) =>
    mapTranslatedLabel(item, t(item.label))
  );

  const channelTypeOptions = _.cloneDeep(channelTypeConfig);

  const handleNextStep = () => {
    formik.handleSubmit();
  };

  const fillForm = (data?: IDistributionPartnerChannelDTO) => {
    formik.setValues({
      channelType: data?.channelType,
      geolocations: data?.geolocations,
      channelName: data?.channelName,
      channelUrl: data?.channelUrl,
      channelVisibility: data?.channelVisibility,
      numberOfFollowers: data?.numberOfFollowers,
      groupDescription: data?.groupDescription,
      dontWantOffer: data?.dontWantOffer,
      followersPrimaryLocation: data?.followersPrimaryLocation,
      followersSecondaryLocation: data?.followersSecondaryLocation,
      followersPrimaryAge: data?.followersPrimaryAge,
      topics: data?.topics,
      designations: data?.designations,
      background: data?.background,
      gender: data?.gender,
      age: data?.age,
      other: data?.other,
    });
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getChannels({ channelId: id }).then(
        ({ content }) => {
          setData(content[0]);
          fillForm(content[0]);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (performSubmit !== undefined) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line
  }, [performSubmit]);

  const channelTypeInitialValue = useMemo(
    () => (data?.channelType ? [data?.channelType] : undefined),
    [data]
  );

  return (
    <>
      {loading ? (
        <PlxLoader />
      ) : (
        <AddChannelFormWrapper>
          <ButtonGroupSection
            buttonsConfig={channelTypeOptions}
            title={t('influencer.addChannel.channelType')}
            otherOption
            inputTitle={t('influencer.addChannel.channelTypeInput')}
            required
            placeholder={t('influencer.addChannel.channelTypeInputPlaceholder')}
            setValue={formik.setFieldValue}
            fieldName={'channelType'}
            value={channelTypeInitialValue}
            testId="channel-type-form"
          />
          {formik.errors.channelType && (
            <FieldErrorMessage
              message={formik.errors.channelType}
              testId="channel-type-error"
            />
          )}
          <NLWrapper>
            <div>
              <InputSectionX
                title={t('influencer.addChannel.channelName')}
                placeholder={t('influencer.addChannel.channelNamePlaceholder')}
                required
                fieldName={'channelName'}
                handleChange={formik.handleChange}
                value={data?.channelName}
                testId="channel-name-field"
              />
              {formik.errors.channelName && (
                <FieldErrorMessage
                  message={formik.errors.channelName}
                  testId="channel-name-error"
                />
              )}
            </div>
            <div>
              <InputSectionX
                title={t('influencer.addChannel.channelUrl')}
                placeholder={t('influencer.addChannel.channelUrlPlaceholder')}
                required
                fieldName={'channelUrl'}
                handleChange={formik.handleChange}
                value={data?.channelUrl}
                testId="cahnnel-url-field"
              />
              {formik.errors.channelUrl && (
                <FieldErrorMessage
                  message={formik.errors.channelUrl}
                  testId="channel-url-error"
                />
              )}
            </div>
          </NLWrapper>
          <NLWrapper>
            <div>
              <InputSectionX
                typeNumber
                title={t('influencer.addChannel.numberOfFollowers')}
                placeholder={t(
                  'influencer.addChannel.numberOfFollowersPlaceholder'
                )}
                required
                fieldName={'numberOfFollowers'}
                handleChange={formik.handleChange}
                value={data?.numberOfFollowers}
                testId="followers-field"
              />
              {formik.errors.numberOfFollowers && (
                <FieldErrorMessage
                  message={formik.errors.numberOfFollowers}
                  testId="followers-error"
                />
              )}
            </div>
            <div />
          </NLWrapper>
          <ButtonGroupSection
            title={t('spectrum.program.geoLocation')}
            tooltip={t('influencer.addChannel.geoLocationTooltip')}
            multiple={true}
            required
            fieldName={'geolocations'}
            setValue={formik.setFieldValue}
            buttonsConfig={geolocationOptions}
            value={data?.geolocations}
            testId="geo-location-form"
          />
          {formik.errors.geolocations && (
            <FieldErrorMessage
              message={formik.errors.geolocations}
              testId="geo-location-error"
            />
          )}
          <LocationAgeWrapper>
            <LocationWrapper>
              <InputSectionDropDown
                fieldName={'followersPrimaryLocation'}
                required
                title={t('influencer.addChannel.primaryLocation')}
                placeholder={t('influencer.addChannel.country')}
                options={countries}
                setValue={formik.setFieldValue}
                value={data?.followersPrimaryLocation}
              />
              {formik.errors.followersPrimaryLocation && (
                <FieldErrorMessage
                  message={formik.errors.followersPrimaryLocation}
                  testId="followers-primary-location-error"
                />
              )}
              <InputSectionDropDown
                fieldName={'followersSecondaryLocation'}
                required
                title={t('influencer.addChannel.secondaryLocation')}
                placeholder={t('influencer.addChannel.country')}
                options={countries}
                setValue={formik.setFieldValue}
                value={data?.followersSecondaryLocation}
              />
              {formik.errors.followersSecondaryLocation && (
                <FieldErrorMessage
                  message={formik.errors.followersSecondaryLocation}
                  testId="followers-secondary-location-error"
                />
              )}
            </LocationWrapper>
            <AgeWrapper>
              <PlxSection
                fieldName={'followersPrimaryAge'}
                required
                initialValue={data?.followersPrimaryAge}
                options={ages}
                title={t('influencer.addChannel.followersAge')}
                setFieldValue={formik.setFieldValue}
              />
              {formik.errors.followersPrimaryAge && (
                <FieldErrorMessage
                  message={formik.errors.followersPrimaryAge}
                  testId="followers-primary-age-error"
                />
              )}
            </AgeWrapper>
          </LocationAgeWrapper>

          <TopicsSection
            fieldName="topics"
            setFieldValue={formik.setFieldValue}
            initialValue={data?.topics}
            finances={finances}
            stocks={stocks}
            investing={investing}
            themes={themes}
            required
            description={t('influencer.addChannel.description')}
            title={t('influencer.addChannel.topics')}
          />
          {formik.errors.topics && (
            <FieldErrorMessage
              message={formik.errors.topics}
              testId="topics-error"
            />
          )}
          <MultiSection
            fieldName={'designations'}
            required
            options={designations}
            initialValue={data?.designations}
            description={t('influencer.addChannel.description')}
            title={t('influencer.addChannel.designations')}
            setFieldValue={formik.setFieldValue}
          />
          {formik.errors.designations && (
            <FieldErrorMessage
              message={formik.errors.designations}
              testId="designations-error"
            />
          )}
          <BottomWrapper>
            <MultiSection
              fieldName={'background'}
              initialValue={data?.background}
              required
              options={background}
              title={t('influencer.addChannel.background')}
              setFieldValue={formik.setFieldValue}
            />
            <PlxSection
              fieldName={'gender'}
              initialValue={data?.gender}
              required
              options={gender}
              title={t('influencer.addChannel.gender')}
              setFieldValue={formik.setFieldValue}
            />
            <PlxSection
              fieldName={'age'}
              initialValue={data?.age}
              required
              options={ages}
              title={t('influencer.addChannel.age')}
              setFieldValue={formik.setFieldValue}
            />
            <PlxSection
              fieldName={'other'}
              initialValue={data?.other}
              required
              options={other}
              title={t('influencer.addChannel.other')}
              setFieldValue={formik.setFieldValue}
            />
          </BottomWrapper>
          {formik.errors.background ? (
            <FieldErrorMessage
              message={formik.errors.background}
              testId="background-error"
            />
          ) : formik.errors.gender ? (
            <FieldErrorMessage
              message={formik.errors.gender}
              testId="gender-error"
            />
          ) : formik.errors.age ? (
            <FieldErrorMessage message={formik.errors.age} testId="age-error" />
          ) : (
            <FieldErrorMessage
              message={formik.errors.other}
              testId="other-error"
            />
          )}
          <PlxCheckbox
            label={t('influencer.addChannel.dontWantOffer')}
            name={'dontWantOffer'}
            onChange={formik.handleChange}
            value={formik.values.dontWantOffer}
            testId="offer-checkbox"
          />
          {footerButtons && (
            <FooterButtons
              onSubmit
              onNextStep={handleNextStep}
              testId="submit-button"
            />
          )}
        </AddChannelFormWrapper>
      )}
    </>
  );
};
