import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { IUpdateableAnalyticsDTO } from '_State/DistributionPartner/distributionPartner.types';
import { TextField } from '@material-ui/core';

interface IProps {
  data?: number;
  reactions?: boolean;
  shares?: boolean;
  comments?: boolean;
  impressions?: boolean;
  videoSaves?: boolean;
  numberOfFollowers?: boolean;
  handleUpdate: (data: IUpdateableAnalyticsDTO) => void;
}

export const PlxUpdateField: FC<IProps> = ({
  data,
  handleUpdate,
  reactions,
  shares,
  comments,
  impressions,
  videoSaves,
  numberOfFollowers,
}) => {
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    data && setValue(data);
  }, [data]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (/^\d*$/.test(inputValue) || inputValue === '') {
      const numericValue = inputValue === '' ? null : parseInt(inputValue, 10);

      setValue(numericValue as number);

      switch (true) {
        case reactions:
          handleUpdate({
            reactions: numericValue === null ? 0 : numericValue,
          });
          break;
        case shares:
          handleUpdate({
            shares: numericValue === null ? 0 : numericValue,
          });
          break;
        case comments:
          handleUpdate({
            comments: numericValue === null ? 0 : numericValue,
          });
          break;
        case impressions:
          handleUpdate({
            impressions: numericValue === null ? 0 : numericValue,
          });
          break;
        case videoSaves:
          handleUpdate({
            videoSaves: numericValue === null ? 0 : numericValue,
          });
          break;
        case numberOfFollowers:
          handleUpdate({
            numberOfFollowers: numericValue === null ? 0 : numericValue,
          });
          break;
        default:
          break;
      }
    }
  };

  return (
    <TextField
      style={{ width: '10rem' }}
      hiddenLabel
      value={value === undefined || value === null ? '0' : value.toString()}
      size="small"
      onChange={handleInputChange}
      InputProps={{
        style: { textAlignLast: 'end' },
      }}
    />
  );
};
