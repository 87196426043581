import React, { FC } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TrimString } from '_Routes/Home/_routes/DistributionPartner/utils';
import { useTranslation } from 'react-i18next';
import { getComparator, PostData, stableSort, useStyles } from './utils';
import { Link } from 'react-router-dom';
import { XemTooltip } from '_Components/XemTooltip/XemTooltip';

const createData = (
  campaignId: number,
  channelName: string,
  channelType: string,
  datePosted: string,
  postDetails: string,
  engagement: number,
  reactions: number,
  comments: number,
  shares: number,
  postScreenShot: string,
  tag?: string
): PostData => {
  return {
    campaignId,
    channelName,
    channelType,
    tag,
    postScreenShot,
    datePosted,
    postDetails,
    engagement,
    reactions,
    comments,
    shares,
  };
};

export const TopPosts: FC<{
  postData: PostData[];
  isAdmin?: number;
  isAgencyType: boolean;
}> = ({ postData, isAdmin, isAgencyType }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);

  const rows = postData.map(
    ({
      campaignId,
      channelName,
      channelType,
      tag,
      datePosted,
      postScreenShot,
      postDetails,
      engagement,
      reactions,
      comments,
      shares,
    }) =>
      createData(
        campaignId,
        channelName,
        channelType,
        datePosted,
        postDetails,
        engagement,
        reactions,
        comments,
        shares,
        postScreenShot,
        tag ? tag : '-'
      )
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const emptyRows = 3 - Math.min(3, rows.length - page * 3);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableBody className={classes.tableBody}>
              {stableSort(rows, getComparator('desc', 'engagement'))
                .slice(page * 3, page * 3 + 3)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className={classes.tableRowTopPosts}
                    >
                      <ul className={classes.ul}>
                        <li className={classes.li}>
                          <div className={classes.postHead}>
                            {t('spectrum.topSpectrumTable.channel')}
                          </div>
                          <div className={classes.postValue}>
                            {row.channelName}
                          </div>
                        </li>
                        <li className={classes.li}>
                          <div className={classes.postHead}>
                            {t('spectrum.topSpectrumTable.date')}
                          </div>
                          <div className={classes.postValue}>
                            {row.datePosted}
                          </div>
                        </li>
                        <li className={classes.li}>
                          <div className={classes.postHead}>
                            {t('spectrum.topSpectrumTable.channelType')}
                          </div>
                          <div className={classes.postValue}>
                            {row.channelType}
                          </div>
                        </li>
                        <li className={classes.li}>
                          <div className={classes.postHead}>
                            {t('spectrum.topSpectrumTable.campaignTag')}
                          </div>
                          <div className={classes.postValue}>
                            {row.tag ? row.tag : '-'}
                          </div>
                        </li>
                      </ul>
                      <div>
                        <img
                          style={{
                            maxWidth: '100%',
                            maxHeight: '200px',
                            height: '200px',
                            objectFit: 'cover',
                            margin: 'auto',
                            display: 'flex',
                          }}
                          src={row.postScreenShot}
                          alt="influencer-post"
                        />
                      </div>
                      <div className={classes.postDetails}>
                        <p>
                          <TrimString
                            paragraph={row.postDetails ? row.postDetails : ''}
                            maxLetters={200}
                          />
                        </p>
                      </div>
                      <div>
                        <ul className={classes.ul}>
                          <li
                            className={classes.reach}
                            style={{ background: '#FFEDFF' }}
                          >
                            <div className={classes.postHead}>
                              {t('spectrum.topSpectrumTable.engagement')}
                            </div>
                            <div>
                              {row.engagement
                                .toFixed(3)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              %
                            </div>
                          </li>
                          <li className={classes.reach}>
                            <div className={classes.postHead}>
                              {t('spectrum.topSpectrumTable.reactions')}
                            </div>
                            <div>{row.reactions}</div>
                          </li>
                          <li className={classes.reach}>
                            <div className={classes.postHead}>
                              {t('spectrum.topSpectrumTable.comments')}
                            </div>
                            <div>{row.comments}</div>
                          </li>
                          <li className={classes.reach}>
                            <div className={classes.postHead}>
                              {t('spectrum.topSpectrumTable.shares')}
                            </div>
                            <div>{row.shares}</div>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.goToSection}>
                        <Link
                          className={classes.a}
                          to={
                            !!isAdmin
                              ? `/home/admin/users/analyt/campaign/${isAdmin}/${row.campaignId}`
                              : isAgencyType
                              ? `/home/agency/spectrum-dashboard/campaign/${row.campaignId}`
                              : `/home/client/spectrum-dashboard/campaign/${row.campaignId}`
                          }
                        >
                          {t('spectrum.topSpectrumTable.goToCampaign')}
                        </Link>
                        {/* <Link className={classes.a} to="./thePostLink">
                          {t('spectrum.topSpectrumTable.seeDetails')}
                        </Link> */}
                      </div>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div className={classes.paginationWrap}>
        <div>
          <XemTooltip
            primaryText={t('spectrum.topSpectrumTable.topPostsTooltip')}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={3}
            page={page}
            onPageChange={handleChangePage}
          />
          {/* <Link className={classes.seeAll} to="./engagement">
          {t('spectrum.topSpectrumTable.seeAllPosts')}
        </Link> */}
        </div>
      </div>
    </div>
  );
};
