import { ISpectrumCampaign } from '_State/Spectrum/types';
import { ITile } from '_Types/ITile';
import { CampaignStatusIcon, ISelectOption } from '_Components/PlxSelect/types';
import React, { ReactElement } from 'react';
import { arrayToSplittedString } from '_App/utils';
import { PlxSelect } from '_Components/PlxSelect/PlxSelect';
import { CampaignStatus, PaymentStatus } from '_Types/campaigns';
import {
  CAMPAIGN_STATUS_MAP_STRATEGY,
  PAYMENT_METHOD_MAP_STRATEGY,
  PAYMENT_STATUS_MAP_STRATEGY,
} from '_Routes/Home/_routes/Admin/types';
import moment from 'moment';
import styled from '@emotion/styled';
import { getPaymentMethod } from '../Dashboard/utils';
import {
  ELIGIBLE_CHANNEL_STATUS_MAP_STRATEGY,
  ELIGIBLE_TRANSACTION_STATUS_MAP_STRATEGY,
  IAnalyticsDTO,
  IDistributionPartnerChannelSelectableRow,
  IUrlDTO,
} from '_State/DistributionPartner/distributionPartner.types';
import { ChannelStatusCell } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelStatusCell';
import {
  getEligibleChannelStatus,
  getEligibleTransactionStatus,
} from '_Routes/Home/_routes/Admin/_Routes/Channels/utils';
import { EligibleChannelsActions } from '_Routes/Home/_routes/Admin/_Routes/Spectrum/AdminSpectrumCampaign/EligibleChannelActions';
import { StandardizeAmount } from '_Components/StandardizeAmount/StandardizeAmount';
import { EligibleChannelStatus } from '_State/DistributionPartner/distributionPartner.enums';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { PlxIconButton } from '_Components/Buttons/PlxButton/PlxButtonX';
import { EditIcon } from '_Styles/images/_Icons/EditIcon';
import { GreenCircle } from './Styles';
import { PlxText } from '_Components/Text/PlxText';

const StyledText = styled(PlxText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const configureStatistics = (
  t: any,
  campaign?: ISpectrumCampaign,
  noOfFollowers?: any
): ITile[] => [
  {
    label: t('spectrum.dashboard.estimatedReach'),
    amount: campaign?.estimatedReach,
    formControlName: 'estimatedReach',
    editable: true,
  },
  {
    label: t('spectrum.dashboard.reactions'),
    amount: campaign?.reactions,
    formControlName: 'reactions',
    editable: true,
  },
  {
    label: t('spectrum.dashboard.comments'),
    amount: campaign?.comments,
    formControlName: 'comments',
    editable: true,
  },
  {
    label: t('spectrum.dashboard.shares'),
    amount: campaign?.shares,
    formControlName: 'shares',
    editable: true,
  },
  {
    label: t('spectrum.dashboard.clicks'),
    amount: campaign?.clicks,
    formControlName: 'clicks',
    editable: false,
  },
  {
    label: t('spectrum.dashboard.impressions'),
    amount: campaign?.impressions,
    formControlName: 'impressions',
    editable: false,
  },
  {
    label: t('spectrum.dashboard.videoSaves'),
    amount: campaign?.videoSaves,
    formControlName: 'videoSaves',
    editable: false,
  },
  {
    label: t('spectrum.dashboard.followers'),
    amount: noOfFollowers?.noOfFollowers,
    formControlName: 'followers',
    editable: true,
  },
  {
    label: t('spectrum.dashboard.contributors'),
    amount: noOfFollowers?.participatingFinfluencers,
    formControlName: 'numberOfContributors',
    editable: true,
  },
];

export const configureStatusSelect = (t: any): ISelectOption[] => [
  {
    label: t(CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.NEW].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.NEW].color}
      />
    ),
    value: CampaignStatus[CampaignStatus.NEW],
  },
  {
    label: t(CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.LAUNCHED].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.LAUNCHED].color}
      />
    ),
    value: CampaignStatus[CampaignStatus.LAUNCHED],
  },
  {
    label: t(CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.REVIEW_NEEDED].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.REVIEW_NEEDED].color}
      />
    ),
    value: CampaignStatus[CampaignStatus.REVIEW_NEEDED],
  },
  {
    label: t(CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.COMPLETED].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.COMPLETED].color}
      />
    ),
    value: CampaignStatus[CampaignStatus.COMPLETED],
  },
  {
    label: t(CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.PAUSED].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.PAUSED].color}
      />
    ),
    value: CampaignStatus[CampaignStatus.PAUSED],
  },
  {
    label: t(CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.PAID].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.PAID].color}
      />
    ),
    value: CampaignStatus[CampaignStatus.PAID],
  },
  {
    label: t(CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.DRAFT].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={CAMPAIGN_STATUS_MAP_STRATEGY[CampaignStatus.DRAFT].color}
      />
    ),
    value: CampaignStatus[CampaignStatus.DRAFT],
  },
];

export const configurePaymentSelect = (t: any): ISelectOption[] => [
  {
    label: t(PAYMENT_STATUS_MAP_STRATEGY[PaymentStatus.SUCCESS].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={PAYMENT_STATUS_MAP_STRATEGY[PaymentStatus.SUCCESS].color}
      />
    ),
    value: PaymentStatus[PaymentStatus.SUCCESS],
  },
  {
    label: t(PAYMENT_STATUS_MAP_STRATEGY[PaymentStatus.IN_PROGRESS].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={PAYMENT_STATUS_MAP_STRATEGY[PaymentStatus.IN_PROGRESS].color}
      />
    ),
    value: PaymentStatus[PaymentStatus.IN_PROGRESS],
  },
  {
    label: t(PAYMENT_STATUS_MAP_STRATEGY[PaymentStatus.FAILED].label),
    optionIcon: () => (
      <CampaignStatusIcon
        color={PAYMENT_STATUS_MAP_STRATEGY[PaymentStatus.FAILED].color}
      />
    ),
    value: PaymentStatus[PaymentStatus.FAILED],
  },
];

export const configureAdminSpectrumCampaignInfo = (
  t: any,
  onPaymentStatusChange: (value: unknown) => void,
  campaign?: ISpectrumCampaign
) => [
  {
    label: t('admin.campaignInfo.companyName'),
    value: campaign?.companyName,
    key: 'companyName',
    renderContent: () => <div>{campaign?.companyName}</div>,
  },
  {
    label: t('admin.campaignInfo.agencyName'),
    value: campaign?.agencyClient?.companyName,
    key: 'companyName',
    renderContent: () => <div>{campaign?.agencyClient?.companyName}</div>,
  },
  {
    label: t('admin.campaignInfo.agencyEmail'),
    value: campaign?.agencyClient?.email,
    key: 'companyName',
    renderContent: () => <div>{campaign?.agencyClient?.email}</div>,
  },
  {
    label: t('admin.campaignInfo.creatorEmail'),
    value: campaign?.creatorEmail,
    key: 'creatorEmail',
    renderContent: () => <div>{campaign?.creatorEmail}</div>,
  },
  {
    label: t('admin.campaignInfo.creatorName'),
    value: campaign?.creatorName,
    key: 'creatorName',
    renderContent: () => <div>{campaign?.creatorName}</div>,
  },
  {
    label: t('admin.campaignInfo.campaignId'),
    value: campaign?.id,
    key: 'id',
    renderContent: () => <div>Campaign #{campaign?.id}</div>,
  },
  {
    label: t('admin.campaignInfo.invoiceId'),
    value: campaign?.program?.invoiceId || '',
    key: 'InvoiceId',
    renderContent: () => <div>{campaign?.program?.invoiceId}</div>,
  },
  {
    label: t('admin.campaignInfo.dateOfCampaign'),
    value: campaign && moment(campaign.createdDate).format('yyyy-MM-DD'),
    key: 'createdDate',
    renderContent: () => (
      <div>{campaign && moment(campaign.createdDate).format('yyyy-MM-DD')}</div>
    ),
  },
  {
    label: t('admin.campaignInfo.campaignLaunchDate'),
    value: campaign && moment(campaign.startDate).format('yyyy-MM-DD'),
    key: 'startDate',
    renderContent: () => (
      <div>{campaign && moment(campaign.startDate).format('yyyy-MM-DD')}</div>
    ),
  },
  {
    label: t('admin.campaignInfo.completionDate'),
    value:
      campaign?.completionDate &&
      moment(campaign.completionDate).format('yyyy-MM-DD'),
    key: 'completionDate',
    renderContent: () => (
      <div>
        {campaign?.completionDate &&
          moment(campaign.completionDate).format('yyyy-MM-DD')}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.link'),
    value: campaign?.url,
    key: 'url',
    renderContent: () => <div>{campaign?.url}</div>,
  },
  {
    label: t('admin.campaignInfo.furtherInstructions'),
    value: campaign?.furtherInstructions,
    key: 'furtherInstructions',
    renderContent: () => <div>{campaign?.furtherInstructions}</div>,
  },
  {
    label: t('admin.campaignInfo.package'),
    value: campaign?.payment ? `$${campaign?.payment.amount}` : '',
    key: 'payment.amount',
    renderContent: () => (
      <div>{campaign?.payment ? `$${campaign?.payment.amount}` : ''}</div>
    ),
  },
  {
    label: t('admin.campaignInfo.paymentMethod'),
    key: 'payment.paymentMethod',
    renderContent: () => (
      <>
        {campaign?.payment ? (
          <PlxSelect
            onChange={onPaymentStatusChange}
            title={t(
              (PAYMENT_METHOD_MAP_STRATEGY as any)[
                getPaymentMethod(campaign?.payment.paymentMethod)
              ].label
            )}
            initialValue={campaign?.payment.paymentStatus}
            options={configurePaymentSelect(t)}
          />
        ) : (
          <div>-</div>
        )}
      </>
    ),
  },
  {
    label: t('admin.campaignInfo.industry'),
    value:
      campaign?.industry && campaign?.industry.length > 0
        ? arrayToSplittedString(campaign.industry)
        : '',
    key: 'industry',
    renderContent: () => (
      <div>
        {campaign?.industry && campaign?.industry.length > 0
          ? arrayToSplittedString(campaign.industry)
          : ''}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.targetGeoLocation'),
    value:
      campaign?.geolocation && campaign?.geolocation.length > 0
        ? arrayToSplittedString(campaign.geolocation)
        : '',
    key: 'geolocation',
    renderContent: () => (
      <div>
        {campaign?.geolocation && campaign?.geolocation.length > 0
          ? arrayToSplittedString(campaign.geolocation)
          : ''}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.contentType'),
    value: campaign?.contentType,
    key: 'contentType',
    renderContent: () => <div>{campaign?.contentType}</div>,
  },
  {
    label: t('admin.campaignInfo.contentCategories'),
    value:
      campaign?.contentCategories && campaign?.contentCategories.length > 0
        ? arrayToSplittedString(campaign.contentCategories)
        : '',
    key: 'contentCategories',
    renderContent: () => (
      <div>
        {campaign?.contentCategories && campaign?.contentCategories.length > 0
          ? arrayToSplittedString(campaign.contentCategories)
          : ''}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.hashtags'),
    value:
      campaign?.hashtags && campaign?.hashtags.length > 0
        ? arrayToSplittedString(campaign.hashtags)
        : '',
    key: 'hashtags',
    renderContent: () => (
      <div>
        {campaign?.hashtags && campaign?.hashtags.length > 0
          ? arrayToSplittedString(campaign.hashtags)
          : ''}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.stockSymbol'),
    value:
      campaign?.stockTickerList && campaign?.stockTickerList.length > 0
        ? campaign?.stockTickerList
        : [],
    key: 'stockTickerList',
    renderContent: () => (
      <div>
        {campaign?.stockTickerList && campaign?.stockTickerList.length > 0
          ? arrayToSplittedString(
              campaign.stockTickerList.map(
                (stock) => `${stock.exchangeSymbol}: ${stock.companySymbol}`
              ),
              false
            )
          : ''}
      </div>
    ),
  },
];
export const configureEditorSpectrumCampaignInfo = (
  t: any,
  campaign?: ISpectrumCampaign
) => [
  {
    label: t('admin.campaignInfo.companyName'),
    renderContent: () => <div>{campaign?.companyName}</div>,
  },
  {
    label: t('admin.campaignInfo.creatorEmail'),
    renderContent: () => <div>{campaign?.creatorEmail}</div>,
  },
  {
    label: t('admin.campaignInfo.creatorName'),
    renderContent: () => <div>{campaign?.creatorName}</div>,
  },
  {
    label: t('admin.campaignInfo.campaignId'),
    renderContent: () => <div>Campaign #{campaign?.id}</div>,
  },
  {
    label: t('admin.campaignInfo.dateOfCampaign'),
    renderContent: () => (
      <div>{campaign && moment(campaign.createdDate).format('MM/DD/yyyy')}</div>
    ),
  },
  {
    label: t('admin.campaignInfo.industry'),
    renderContent: () => (
      <div>
        {campaign?.industry && arrayToSplittedString(campaign.industry)}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.package'),
    renderContent: () => <div>-</div>,
  },
  {
    label: t('admin.campaignInfo.targetGeoLocation'),
    renderContent: () => (
      <div>
        {campaign?.geolocation && arrayToSplittedString(campaign.geolocation)}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.contentType'),
    renderContent: () => <div>{campaign?.contentType}</div>,
  },
  {
    label: t('admin.campaignInfo.contentCategories'),
    renderContent: () => (
      <div>
        {campaign?.contentCategories &&
          arrayToSplittedString(campaign.contentCategories)}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.hashtags'),
    renderContent: () => (
      <div>
        {campaign?.hashtags && arrayToSplittedString(campaign.hashtags)}
      </div>
    ),
  },
  {
    label: t('admin.campaignInfo.stockSymbol'),
    renderContent: () => (
      <div>
        {campaign?.stockTickerList &&
          arrayToSplittedString(
            campaign.stockTickerList.map(
              (stock) => `${stock.exchangeSymbol}: ${stock.companySymbol}`
            ),
            false
          )}
      </div>
    ),
  },
];

export const xemCreateSpectrumChannelsTableConfig = (
  onInvite: (id?: string) => void,
  onReview: (opportunityId?: string, dialogData?: IAnalyticsDTO) => void,
  onManualPay: (id?: string) => void,
  submitUrl: (url: IUrlDTO) => void,
  onCancel: (id?: string) => void,
  campaignStatus: string,
  maxSpend: number | undefined
): any[] => {
  return [
    {
      field: 'channelId',
      headerName: 'ID',
      width: 75,
    },
    {
      field: 'channelName',
      headerName: 'Name',
      width: 175,
      renderCell({
        row,
      }: {
        row: IDistributionPartnerChannelSelectableRow;
      }): ReactElement {
        return (
          <a href={row.channelUrl} target="_blank" rel="noreferrer noopener">
            {row.channelName}
          </a>
        );
      },
    },
    {
      field: 'channelType',
      headerName: 'Type',
      width: 180,
      renderCell({
        row,
      }: {
        row: IDistributionPartnerChannelSelectableRow;
      }): ReactElement {
        return (
          <>
            {row.channelType}
            <GreenCircle
              style={{ display: !row.connected ? 'none' : undefined }}
            />
          </>
        );
      },
    },

    {
      field: 'numberOfFollowers',
      headerName: 'Followers',
      width: 100,
      renderCell({
        row,
      }: {
        row: IDistributionPartnerChannelSelectableRow;
      }): ReactElement {
        return <StandardizeAmount amount={row.numberOfFollowers} decimal={0} />;
      },
    },
    {
      headerName: 'Recent ER',
      field: 'recentEngagementRate',
      width: 120,
      sortable: false,
      filterable: false,
      renderCell({
        row,
      }: {
        row: IDistributionPartnerChannelSelectableRow;
      }): ReactElement {
        return (
          <StyledText>
            {row.recentEngagementRate && row.recentEngagementRate + '%'}
          </StyledText>
        );
      },
    },
    {
      field: 'clicks',
      headerName: 'Clicks',
      width: 100,
    },

    {
      field: 'fullName',
      headerName: 'Influencer',
      width: 150,
    },
    {
      field: 'geolocations',
      headerName: 'Geolocation',
      width: 100,
      renderCell({
        row,
      }: {
        row: IDistributionPartnerChannelSelectableRow;
      }): ReactElement {
        return <div>{row.geolocations.join(', ')}</div>;
      },
    },
    {
      field: 'defaultWalletEmail',
      headerName: 'Wallet Id',
      width: 200,
    },
    {
      field: 'offer',
      headerName: 'Offer ($)',
      width: 100,
      renderCell({
        row,
      }: {
        row: IDistributionPartnerChannelSelectableRow;
      }): ReactElement {
        return <div>{row.offer ? `$ ${row.offer}.00` : '-'}</div>;
      },
    },
    {
      field: 'shortUrl',
      headerName: 'Short Url',
      width: 120,
      renderCell({
        row,
      }: {
        row: IDistributionPartnerChannelSelectableRow;
      }): ReactElement {
        return (
          <a
            href={`https://${row.shortenUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.shortenUrl}
          </a>
        );
      },
    },
    {
      field: 'postUrl',
      headerName: 'Post Url',
      width: 200,
      renderCell({
        row,
      }: {
        row: IDistributionPartnerChannelSelectableRow;
      }): ReactElement {
        const link = row.postSample
          ? `${row.postSample.originalPostUrl}`
          : row.postUrl
          ? `${row.postUrl}`
          : '';
        return (
          <>
            {!row.postSample && link !== '' && (
              <PlxIconButton
                onClick={() =>
                  submitUrl({
                    id: row.id,
                    postUrl: row.postUrl,
                  })
                }
              >
                <EditIcon />
              </PlxIconButton>
            )}
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          </>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 175,
      renderCell({
        row,
      }: {
        row: IDistributionPartnerChannelSelectableRow;
      }): ReactElement {
        return (
          <ChannelStatusCell
            status={
              !!row.transactionStatus
                ? (ELIGIBLE_TRANSACTION_STATUS_MAP_STRATEGY as any)[
                    getEligibleTransactionStatus(row.transactionStatus)
                  ]
                : row.status === 'COMPLETED' && !row.defaultWalletEmail
                ? {
                    color: 'red',
                    label: 'channelStatus.completedNoWallet',
                  }
                : (ELIGIBLE_CHANNEL_STATUS_MAP_STRATEGY as any)[
                    getEligibleChannelStatus(row.status)
                  ]
            }
            rejectionReason={row.reason}
          />
        );
      },
    },
    {
      headerName: 'Analytics Date',
      field: 'hourlyDataCollectionIsActiveAndEndsAt',
      width: 160,
      sortable: false,
      filterable: false,
      renderCell({
        row,
      }: {
        row: IDistributionPartnerChannelSelectableRow;
      }): ReactElement {
        return (
          <StyledText>
            {row.hourlyDataCollectionIsActiveAndEndsAt &&
              moment(
                row.hourlyDataCollectionIsActiveAndEndsAt?.toString()
              ).format('MMM DD, YYYY HH:mm:ss')}
          </StyledText>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 250,
      renderCell({
        row,
      }: {
        row: IDistributionPartnerChannelSelectableRow;
      }): ReactElement {
        return (
          <>
            {row.transactionStatus ===
              EligibleChannelStatus[EligibleChannelStatus.COMPLETED] ||
            row.status ===
              EligibleChannelStatus[
                EligibleChannelStatus.COMPLETED_MANUAL_PAYOUT
              ] ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <CheckCircleIcon
                  style={{
                    color: 'green',
                  }}
                />
              </div>
            ) : (
              <EligibleChannelsActions
                onInvite={onInvite}
                onReview={onReview}
                onManualPay={onManualPay}
                onCancel={onCancel}
                maxSpend={maxSpend}
                dialogData={{
                  shares: row.shares,
                  reactions: row.reactions,
                  postSample: row.postSample,
                  datePosted: row.datePosted,
                  comments: row.comments,
                  impressions: row.impressions,
                  videoSaves: row.videoSaves,
                  numberOfFollowers: row.numberOfFollowers,
                  analyticsDate: row.hourlyDataCollectionIsActiveAndEndsAt,
                }}
                opportunityId={row.id.toString()}
                channelId={row.channelId.toString()}
                status={row.status}
                defaultWallet={row.defaultWalletEmail}
                transactionStatus={row.transactionStatus}
                campaignStatus={campaignStatus.toString()}
              />
            )}
          </>
        );
      },
    },
  ];
};

export const xemCreatePayoutList: any[] = [
  {
    field: 'channelName',
    headerName: 'Name',
    width: 175,
  },
  {
    field: 'channelType',
    headerName: 'Type',
    width: 150,
  },
  {
    field: 'offer',
    headerName: 'Offer',
    width: 100,
    renderCell({
      row,
    }: {
      row: IDistributionPartnerChannelSelectableRow;
    }): ReactElement {
      return (
        <>
          $<StandardizeAmount amount={row.offer} />
        </>
      );
    },
  },
];

export const sortingOptions = [
  {
    id: 'ASC',
    name: 'Oldest to newest',
  },
  {
    id: 'DESC',
    name: 'Newest to oldest',
  },
];

export const channelTypeOptions = [
  {
    id: 'DISCORD',
    name: 'Discord',
  },
  {
    id: 'FACEBOOK',
    name: 'Facebook',
  },
  {
    id: 'FORUM',
    name: 'Forum',
  },
  {
    id: 'INSTAGRAM',
    name: 'Instagram',
  },
  {
    id: 'LINKEDIN',
    name: 'Linkedin',
  },
  {
    id: 'PODCAST',
    name: 'Podcast',
  },
  {
    id: 'PINTEREST',
    name: 'Pinterest',
  },
  {
    id: 'REDDIT',
    name: 'Reddit',
  },
  {
    id: 'SLACK',
    name: 'Slack',
  },
  {
    id: 'SNAPCHAT',
    name: 'Snapchat',
  },
  {
    id: 'X',
    name: 'X',
  },
  {
    id: 'TIKTOK',
    name: 'Tik Tok',
  },
  {
    id: 'TWITCH',
    name: 'Twitch',
  },
  {
    id: 'YOUTUBE',
    name: 'YouTube',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];
