import React, { FC, useEffect, useState } from 'react';
import { FormSection } from '_Components/FormSection/FormSection';
import { ICommonProps } from '_Types/props';
import { Option } from '../types';
import {
  CheckboxContainer,
  CheckboxLabel,
  StyledCheckbox,
  TopicsTitle,
  TopicsWrapper,
} from '../styles';
import { useTranslation } from 'react-i18next';

interface IProps extends ICommonProps {
  title?: string;
  tooltip?: string;
  description?: string;
  finances: Option[];
  stocks: Option[];
  investing: Option[];
  themes: Option[];
  initialValue?: any;
  fieldName: string;
  required?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  testId?: string;
}
export const TopicsSection: FC<IProps> = ({
  initialValue,
  tooltip,
  title,
  description,
  finances,
  stocks,
  investing,
  themes,
  required,
  fieldName,
  className,
  setFieldValue,
  testId,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string[]>([]);

  useEffect(() => {
    initialValue && setInputValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (setFieldValue) {
      setFieldValue(fieldName, inputValue);
    }
    // eslint-disable-next-line
  }, [inputValue]);

  const handleCheckboxChange = (optionValue: string) => {
    const isSelected = inputValue.includes(optionValue);
    if (isSelected) {
      setInputValue((prev) => prev.filter((item) => item !== optionValue));
    } else {
      setInputValue((prev) => [...prev, optionValue]);
    }
  };

  return (
    <FormSection
      className={className}
      title={title}
      description={description}
      tooltip={tooltip}
      required={required}
      testId={testId}
    >
      <TopicsWrapper>
        <TopicsTitle>{t('influencer.addChannel.financial')}</TopicsTitle>
        <CheckboxContainer>
          {finances.map((option) => (
            <CheckboxLabel key={option.label}>
              <StyledCheckbox
                type="checkbox"
                name={option.label}
                checked={inputValue.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
              {option.label}
            </CheckboxLabel>
          ))}
        </CheckboxContainer>
        <TopicsTitle>{t('influencer.addChannel.stocks')}</TopicsTitle>
        <CheckboxContainer>
          {stocks.map((option) => {
            return (
              <CheckboxLabel key={option.label}>
                <StyledCheckbox
                  type="checkbox"
                  name={option.label}
                  checked={inputValue.includes(option.value)}
                  onChange={() => handleCheckboxChange(option.value)}
                />
                {option.label}
              </CheckboxLabel>
            );
          })}
        </CheckboxContainer>
        <TopicsTitle>{t('influencer.addChannel.investing')}</TopicsTitle>
        <CheckboxContainer>
          {investing.map((option) => {
            return (
              <CheckboxLabel key={option.label}>
                <StyledCheckbox
                  type="checkbox"
                  name={option.label}
                  checked={inputValue.includes(option.value)}
                  onChange={() => handleCheckboxChange(option.value)}
                />
                {option.label}
              </CheckboxLabel>
            );
          })}
        </CheckboxContainer>
        <TopicsTitle>{t('influencer.addChannel.themes')}</TopicsTitle>
        <CheckboxContainer>
          {themes.map((option) => {
            return (
              <CheckboxLabel key={option.label}>
                <StyledCheckbox
                  type="checkbox"
                  name={option.label}
                  checked={inputValue.includes(option.value)}
                  onChange={() => handleCheckboxChange(option.value)}
                />
                {option.label}
              </CheckboxLabel>
            );
          })}
        </CheckboxContainer>
      </TopicsWrapper>
    </FormSection>
  );
};
