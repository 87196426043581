import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ChannelDetailsItem } from '_Routes/Home/_routes/Admin/_Routes/Channels/ChannelDetails/ChannelDetailsItem';
import { useTranslation } from 'react-i18next';
import { getChannels } from '_State/DistributionPartner/Api/distributionPartner-admin.api';
import { IDistributionPartnerChannelDTO } from '_State/DistributionPartner/distributionPartner.types';
import { PlxLoader } from '_Components/PlxLoader';
import { formatString } from '_Routes/Home/_routes/Admin/_Routes/Channels/utils';
import { formatDate } from '_Components/DateFormat/DateFormat';

const Body = styled.div`
  width: 100%;
  padding: 2rem;
  background: #ffffff;
  border: 1px solid #808285;
  margin: 1rem 3.5rem;
  min-height: 80%;
  height: fit-content;
`;

const RateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  a {
    margin-left: 0.5rem;
  }
`;

const StyledMultipleContainer = styled.div`
  text-align: right;
`;

interface IProps {
  channelId?: number;
}

export const ChannelDetails: FC<IProps> = ({ channelId }) => {
  const { t } = useTranslation();
  const [channelDetails, setChannelDetails] = useState<
    IDistributionPartnerChannelDTO
  >();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (channelId) {
      getChannels({ channelId: channelId }).then(({ content }) => {
        setChannelDetails(content[0]);
        setLoading(false);
      });
    }
  }, [channelId]);

  const capitalizeFirstLetter = (str?: string) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <>
      <Body>
        {loading ? (
          <PlxLoader />
        ) : (
          <>
            <ChannelDetailsItem
              label={t('channels.channelDetails.channelType')}
            >
              {formatString(channelDetails?.channelType)}
            </ChannelDetailsItem>
            <ChannelDetailsItem
              label={t('channels.channelDetails.channelName')}
            >
              {channelDetails?.channelName}
            </ChannelDetailsItem>
            <ChannelDetailsItem label={t('channels.channelDetails.channelURL')}>
              <a
                href={channelDetails?.channelUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {channelDetails?.channelUrl}
              </a>
            </ChannelDetailsItem>
            <ChannelDetailsItem
              label={t('channels.channelDetails.numberOfFollowers')}
            >
              {channelDetails?.numberOfFollowers}
            </ChannelDetailsItem>
            <ChannelDetailsItem
              label={t('channels.channelDetails.channelCreationDate')}
            >
              {formatDate(channelDetails?.channelCreationDate)}
            </ChannelDetailsItem>
            {channelDetails?.geolocations && (
              <ChannelDetailsItem
                label={t('channels.channelDetails.geolocation')}
              >
                {channelDetails?.geolocations
                  .map((key) => formatString(key))
                  .join(', ')}
              </ChannelDetailsItem>
            )}
            <ChannelDetailsItem label={t('channels.channelDetails.interest')}>
              <StyledMultipleContainer>
                {channelDetails?.topics.map((item: string) => {
                  return <div>{formatString(item)}</div>;
                })}
              </StyledMultipleContainer>
            </ChannelDetailsItem>
            <ChannelDetailsItem
              label={t('channels.channelDetails.designation')}
            >
              <StyledMultipleContainer>
                {channelDetails?.designations.map((item: string) => {
                  return <div>{formatString(item)}</div>;
                })}
              </StyledMultipleContainer>
            </ChannelDetailsItem>
            <ChannelDetailsItem
              label={t('channels.channelDetails.demographics')}
            >
              <StyledMultipleContainer>
                {channelDetails?.background.map((item: string) => {
                  return formatString(item) + ' ';
                })}
                <div>{capitalizeFirstLetter(channelDetails?.gender)}</div>
                <div>{channelDetails?.age}</div>
              </StyledMultipleContainer>
            </ChannelDetailsItem>
            <ChannelDetailsItem label={t('channels.channelDetails.growthRate')}>
              <RateWrapper>
                {formatDate(channelDetails?.growthRateDateFrom)}
                {' - '}
                {formatDate(channelDetails?.growthRateDateTo)}
                {': '}
                <a
                  href={channelDetails?.growthRateFile?.fileSourceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  {channelDetails?.growthRateFile?.fileName}
                </a>
              </RateWrapper>
            </ChannelDetailsItem>
            <ChannelDetailsItem
              label={t('channels.channelDetails.engagementRate')}
            >
              <RateWrapper>
                {formatDate(channelDetails?.engagementRateDateFrom)}
                {' - '}
                {formatDate(channelDetails?.engagementRateDateTo)}
                {': '}
                <a
                  href={channelDetails?.engagementRateFile?.fileSourceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  {channelDetails?.growthRateFile?.fileName}
                </a>
              </RateWrapper>
            </ChannelDetailsItem>
          </>
        )}
      </Body>
    </>
  );
};
