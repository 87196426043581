import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NavigationContext } from '_Routes/Home/NavigationContext';
import { PlxList } from '_Components/PlxList/PlxList';
import { IDistributionPartnerOpportunityDTO } from '_State/DistributionPartner/distributionPartner.types';
import {
  createListConfig,
  xemCreateListConfig,
} from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerOpportunities/utils';
import { CellConfig } from '_Components/PlxCustomTable/PlxCustomTable';
import {
  acceptOpportunity,
  getNewOpportunities,
  getOpportunities,
  rejectOpportunity,
  getRejectedOpportunity,
} from '_State/DistributionPartner/Api/distributionPartner.api';
import { PlxLoader } from '_Components/PlxLoader';
import { distributionPartnerActions } from '_State/DistributionPartner/distributionPartner.state';
import { useDispatch } from 'react-redux';
import { PlxDialog } from '_Components/Dialogs/PlxDialog/PlxDialog';
import { useTranslation } from 'react-i18next';
import { CampaignDetails } from '_Routes/Home/_routes/DistributionPartner/_Routes/DistributionPartnerOpportunities/CampaignDetails';
import { SectionHeader, TabPageHeader } from '../../utils';
// import { EmptyDataPlaceHolder } from '_Components/EmptyDataPlaceHolder';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { EmptyContainer } from '../../utils';
import { PlxButtonX } from '_Components/Buttons/PlxButton/PlxButtonX';
import { TextField } from '@material-ui/core';
import {
  Wrapper,
  FormWrapper,
  ButtonWrapper,
  DialogBody,
} from './DistributionPartnerOpportunities.styles';
import { Body } from '../FinfluencerDashboard/DistributionPartnerDashboard';

const orderArr = [
  'NEEDS_WORK',
  'ANALYTICS_NEEDED',
  'REVIEW_ANALYTICS',
  'ACTIVE',
  'COMPLETED',
];

export const DistributionPartnerOpportunities: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { navBarExpanded } = useContext(NavigationContext);
  const history = useHistory();

  const [notSeenOpportunities, setNotSeenOpportunities] = useState<
    IDistributionPartnerOpportunityDTO[]
  >([]);
  const [pendingOpportunities, setPendingOpportunities] = useState<
    IDistributionPartnerOpportunityDTO[]
  >([]);
  const [acceptedOpportunities, setAcceptedOpportunities] = useState<
    IDistributionPartnerOpportunityDTO[]
  >([]);
  const [rejectedOpportunities, setRejectedOpportunities] = useState<
    IDistributionPartnerOpportunityDTO[]
  >([]);
  const [closedOpportunities, setClosedOpportunities] = useState<
    IDistributionPartnerOpportunityDTO[]
  >([]);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [opportunityId, setOpportunityId] = useState<number>(0);
  const [otherValue, setOtherValue] = useState('');
  const [value, setValue] = useState('');

  const [campaignDetailsIds, setCampaignDetailsIds] = useState<{
    campaignId?: number;
    opportunityId?: number;
  }>({
    campaignId: undefined,
    opportunityId: undefined,
  });
  const [openAgreementDialog, setOpenAgreementDialog] = useState(false);
  const dispatch = useDispatch();

  const fetchOpportunities = () => {
    try {
      getNewOpportunities().then((opportunities) => {
        setNotSeenOpportunities(
          opportunities.filter((opportunity) => !opportunity.seen)
        );
        setPendingOpportunities(
          opportunities.filter((opportunity) => opportunity.seen)
        );
        // setLoading(false);
        return opportunities.length;
      });
      getRejectedOpportunity().then((opportunities) => {
        setRejectedOpportunities(
          opportunities.filter(
            (opportunity) => opportunity.status === 'REJECTED'
          )
        );
      });
      getOpportunities().then((opportunities) => {
        setAcceptedOpportunities(
          opportunities
            .filter(
              // ACTIVE, ANALYTICS_NEEDED, REVIEW_ANALYTICS, NEEDS_WORK
              (opportunity) =>
                opportunity.status === 'ACTIVE' ||
                opportunity.status === 'ANALYTICS_NEEDED' ||
                opportunity.status === 'REVIEW_ANALYTICS' ||
                opportunity.status === 'NEEDS_WORK'
            )
            // sorting by status listed in the orderArr
            .slice()
            .sort(
              ({ status: q }, { status: w }) =>
                orderArr.indexOf(q) - orderArr.indexOf(w)
            )
        );
        setClosedOpportunities(
          opportunities.filter(
            (opportunity) =>
              opportunity.status === 'COMPLETED' ||
              opportunity.status === 'COMPLETED_MANUAL_PAYOUT'
          )
        );
        setLoading(false);
        return opportunities.length;
      });
    } catch (httpError) {
      console.log(httpError);
      return 0;
    }
  };

  const openDetails = (
    campaignId: number,
    opportunityId: number,
    channelType: string
  ): void => {
    setCampaignDetailsIds({
      campaignId: campaignId,
      opportunityId: opportunityId,
    });
    setDetailsOpen(true);
  };

  const closeDetails = (): void => {
    setDetailsOpen(false);
    setOpen(false);
    setValue('');
    setOpenAgreementDialog(false);
  };
  const closeAgreementDialog = (): void => {
    setOpportunityId(0);
    setOpenAgreementDialog(false);
  };
  const handleAccept = (opportunityId: number): void => {
    if (openAgreementDialog) {
      acceptOpportunity(opportunityId).then(() => {
        setOpportunityId(0);
        fetchOpportunities();
        setOpenAgreementDialog(false);
      });
    } else {
      setOpportunityId(opportunityId);
      setOpenAgreementDialog(true);
    }
  };

  const rejectAccept = (opportunityId: number): void => {
    //setLoading(true);
    // rejectOpportunity(opportunityId).then(() => fetchOpportunities());
    setOpen(true);
    setOpportunityId(opportunityId);
  };

  const handleReject = () => {
    rejectOpportunity(
      opportunityId,
      value !== 'other' ? value : otherValue
    ).then(() => {
      setOpen(false);
      setValue('');
      setOtherValue('');
      fetchOpportunities();
    });
  };
  const pendingConfig = useMemo(() => {
    return createListConfig(
      handleAccept,
      rejectAccept,
      openDetails
    ) as CellConfig<{}>[];
    // eslint-disable-next-line
  }, []);

  const config = useMemo(() => {
    return xemCreateListConfig(openDetails) as CellConfig<{}>[];
    // eslint-disable-next-line
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    fetchOpportunities();
    dispatch(distributionPartnerActions.setNumberOfNewOpportunities(undefined));
    // eslint-disable-next-line
  }, [dispatch, history]);

  return (
    <Body isExpanded={navBarExpanded}>
      <TabPageHeader name={t('influencer.dashboard.opportunities')} />
      {loading ? (
        <PlxLoader />
      ) : (
        <Wrapper>
          {notSeenOpportunities.length > 0 && (
            <>
              <SectionHeader>{t('opportunities.new')}</SectionHeader>
              <PlxList
                visibility={'New'}
                highlighted
                data={notSeenOpportunities}
                config={pendingConfig}
              />
            </>
          )}
          <SectionHeader>{t('opportunities.pending')}</SectionHeader>
          {pendingOpportunities.length > 0 ? (
            <PlxList
              visibility={'seen'}
              data={pendingOpportunities}
              config={pendingConfig}
            />
          ) : (
            <EmptyContainer>
              {t('opportunities.pendingEmptyPlaceHolder')}
            </EmptyContainer>
          )}
          <SectionHeader>{t('opportunities.accepted')}</SectionHeader>
          {acceptedOpportunities.length > 0 ? (
            <PlxList
              visibility={'seen'}
              data={acceptedOpportunities}
              config={config}
            />
          ) : (
            <EmptyContainer>
              {t('opportunities.acceptedEmptyPlaceHolder')}
            </EmptyContainer>
          )}
          <SectionHeader>{t('opportunities.rejected')}</SectionHeader>
          {rejectedOpportunities.length > 0 ? (
            <PlxList
              visibility={'seen'}
              data={rejectedOpportunities}
              config={config}
              disableItem
            />
          ) : (
            <EmptyContainer>
              {t('opportunities.rejectedEmptyPlaceHolder')}
            </EmptyContainer>
          )}
          <SectionHeader>{t('opportunities.closed')}</SectionHeader>
          {closedOpportunities.length > 0 ? (
            <PlxList
              visibility={'seen'}
              data={closedOpportunities}
              config={config}
              disableItem
            />
          ) : (
            <EmptyContainer>
              {t('opportunities.closedEmptyPlaceHolder')}
            </EmptyContainer>
          )}
          <PlxDialog
            title={t('channels.campaignDetails.title')}
            open={detailsOpen}
            onClose={closeDetails}
          >
            <CampaignDetails campaignDetailsIds={campaignDetailsIds} />
          </PlxDialog>

          <PlxDialog
            title=""
            open={openAgreementDialog}
            onClose={closeAgreementDialog}
          >
            <DialogBody>
              <span>{t('channels.campaignDetails.acceptConfirmation')}</span>
              <ol>
                <li>- {t('channels.campaignDetails.acceptConfirmation1')}</li>
                <li>- {t('channels.campaignDetails.acceptConfirmation2')}</li>
                <li>- {t('channels.campaignDetails.acceptConfirmation3')}</li>
              </ol>
              <h1>
                {t('channels.campaignDetails.acceptConfirmationConclusion')}
              </h1>
              <div style={{ marginTop: '2rem' }}>
                <PlxButtonX onClick={closeAgreementDialog}>
                  {t('channels.campaignDetails.cancel')}
                </PlxButtonX>
                <PlxButtonX onClick={() => handleAccept(opportunityId)}>
                  {t('channels.campaignDetails.agreeAndAccept')}
                </PlxButtonX>
              </div>
            </DialogBody>
          </PlxDialog>

          <PlxDialog title="" open={open} onClose={closeDetails}>
            <FormWrapper>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {t('channels.rejectedOpportunity.confirmation')}
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={t('channels.rejectedOpportunity.option1')}
                    control={<Radio />}
                    label={t('channels.rejectedOpportunity.option1')}
                  />
                  <FormControlLabel
                    value={t('channels.rejectedOpportunity.option2')}
                    control={<Radio />}
                    label={t('channels.rejectedOpportunity.option2')}
                  />
                  <FormControlLabel
                    value={t('channels.rejectedOpportunity.option3')}
                    control={<Radio />}
                    label={t('channels.rejectedOpportunity.option3')}
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label={t('channels.rejectedOpportunity.option4')}
                    // onClick={() => setHandleOther(true)}
                  />
                  {value === 'other' && (
                    <TextField
                      value={otherValue}
                      onChange={(e) => setOtherValue(e.target.value)}
                      placeholder={t(
                        'channels.rejectedOpportunity.placeHolder'
                      )}
                      multiline
                      rows={4}
                      required
                      variant="outlined"
                    />
                  )}
                </RadioGroup>
                <ButtonWrapper>
                  <PlxButtonX onClick={closeDetails} label="Cancel" />
                  <PlxButtonX
                    isDisabled={
                      value
                        ? value === 'other'
                          ? otherValue
                            ? false
                            : true
                          : false
                        : true
                    }
                    onClick={handleReject}
                    label="Submit"
                  />
                </ButtonWrapper>
              </FormControl>
            </FormWrapper>
          </PlxDialog>
        </Wrapper>
      )}
    </Body>
  );
};
