import React, { useState, FC } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { getFacebookData } from './functions/facebookConnectionFunctions';
import FacebookManagementPopup from './FacebookManagementPopup';
import { IFacebookLoginComponent, IFacebookResponse } from './interfaces';

const FacebookLoginComponent: FC<IFacebookLoginComponent> = ({
  id,
  fetchChannels,
  connectedChannelsID,
  channelType,
  children,
}) => {
  const [facebookData, setFacebookData] = useState<IFacebookResponse>({
    accessToken: '',
    id: '',
  });
  const [popupReady, setPopupReady] = useState(false);
  const responseFacebook = async (response: IFacebookResponse) => {
    if (response.status === 'unknown') {
      return;
    }
    setFacebookData({ accessToken: '', id: '' });

    if (response.accessToken) {
      response.accounts = (response?.accounts as any)?.data || [];
      setFacebookData(
        (prevState: IFacebookResponse): IFacebookResponse => ({
          ...prevState,
          ...response,
          channelId: id,
        })
      );
      getFacebookData(response.accessToken, setFacebookData, setPopupReady);
    }
  };

  return (
    <div className="container">
      {facebookData.accessToken && popupReady ? (
        <FacebookManagementPopup
          facebookData={facebookData}
          fetchChannels={fetchChannels}
          setFacebookData={setFacebookData}
          connectedChannelsID={connectedChannelsID}
          channelType={channelType}
        />
      ) : (
        <FacebookLogin
          appId="569720507786195"
          autoLoad={false}
          fields="name,email,picture,accounts"
          scope={'public_profile, email, user_friends, user_posts'}
          callback={responseFacebook}
          icon=""
          render={(renderProps: any) => (
            <div onClick={renderProps.onClick}>{children}</div>
          )}
        />
      )}
    </div>
  );
};

export default FacebookLoginComponent;
