import React, { FC, useState, useEffect } from 'react';
import { ICommonProps } from '_Types/props';
import { Autocomplete } from '@material-ui/lab';
import { InputWrapper, StyledToggleIcon, useStyles } from '../styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';
import { Option } from '../types';

interface IProps extends ICommonProps {
  placeholder?: string;
  options: Option[];
  onChange?: (value: string) => void;
  initialValue: string;
  agencyId?: string;
  loading?: boolean;
  onStateChange?: (value: boolean) => void;
}

export const DropDownMenu: FC<IProps> = ({
  initialValue,
  agencyId,
  placeholder,
  options,
  onChange,
  loading,
  onStateChange,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState<string | null>(initialValue);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnChange = (
    event: React.ChangeEvent<{}>,
    newValue: string | null
  ): void => {
    if (newValue == null) return;

    setValue(newValue);
    onChange && onChange(newValue);
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ): void => {
    setInputValue(newInputValue);
  };

  const handleIconClick = React.useCallback((): void => {
    if (value) {
      setValue(null);
      onChange && onChange('');
    }

    setIsOpen(true);
  }, [value, onChange]);

  useEffect(() => {
    onStateChange && onStateChange(isOpen);
  }, [isOpen, onStateChange]);

  const filteredOptions = options.map((option: Option) => option.value);

  const getLabel = (value: string): string | null => {
    const option = options.find((option) => option.value === value);

    if (!option) return null;

    return option.label;
  };

  return (
    <Autocomplete
      value={value}
      onChange={handleOnChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      blurOnSelect
      open={isOpen}
      loading={loading}
      onClose={(): void => setIsOpen(false)}
      onOpen={(): void => setIsOpen(true)}
      id="controllable-select-filter"
      options={filteredOptions}
      getOptionLabel={(option: string): string => `${getLabel(option)}`}
      renderInput={(params): JSX.Element => (
        <InputWrapper ref={params.InputProps.ref}>
          <input
            type="text"
            {...params.inputProps}
            placeholder={placeholder}
            className={classes.input}
          />
          <StyledToggleIcon onClick={handleIconClick}>
            {value ? (
              <CloseIcon style={{ color: '#5f5f61' }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: '#5f5f61' }} />
            )}
          </StyledToggleIcon>
        </InputWrapper>
      )}
    />
  );
};
